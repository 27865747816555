import RocketIcon from "./RocketIcon";
import ReflectXUpgradeButton from "../ReflectXUpgradeButton";
import { isProductSiteManagerOrSiteDeploy } from "../../../../utils/license/productIsSiteManager";

const ReflectXUpgrade = ({ description }) => {
  const isSMorSDorSB = isProductSiteManagerOrSiteDeploy(description);
  return (
    <div className="pt2 flex flex-column">
      <div className="flex flex-center">
        <RocketIcon />
        <div className="ml2">
          <div className="bold">The new {isSMorSDorSB ? "SiteManager Platform" : "Reflect X"} is now available</div>
          <div>
            Upgrade now to the all new powerful version of {isSMorSDorSB ? "SiteManager Platform" : "Macrium Reflect"}.
          </div>
        </div>
      </div>
      <div className="mt2">
        <ReflectXUpgradeButton
          asButton={true}
          isPrimary={false}
          text={`Upgrade to ${isSMorSDorSB ? "SiteManager Platform" : "X"}`}
        />
      </div>
    </div>
  );
};

export default ReflectXUpgrade;
