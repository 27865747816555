import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { LICENSES_DESCRIPTIVE_STRING, SITE_MANAGER } from "../../../constants/licenses";
import { MOST_RECENT_REFLECT_RELEASE_NOTES, SITE_MANAGER_RELEASE_NOTES } from "../../../constants/urls";
import { getReleaseNotesURLForOfflineActivation } from "../../../selectors/offlineActivation";

const ReleaseNotes = ({ isSMSD, notLoggedIn, productDescription, isSB }) => {
  const releaseNotes = useSelector(getReleaseNotesURLForOfflineActivation);
  const shouldShowReflectNotes = notLoggedIn || (productDescription && !isSMSD);

  const shouldShowSMNotes = notLoggedIn || (productDescription && isSMSD);

  return (
    <>
      {shouldShowReflectNotes && (
        <>
          <a href={releaseNotes || MOST_RECENT_REFLECT_RELEASE_NOTES} target="_blank" rel="noopener noreferrer">
            Macrium Reflect release notes
          </a>{" "}
        </>
      )}
      {notLoggedIn && <>and the </>}
      {shouldShowSMNotes && (
        <a href={releaseNotes || SITE_MANAGER_RELEASE_NOTES} target="_blank" rel="noopener noreferrer">
          Macrium {productDescription ? LICENSES_DESCRIPTIVE_STRING[productDescription] : SITE_MANAGER} release notes{" "}
        </a>
      )}
    </>
  );
};

export default ReleaseNotes;

ReleaseNotes.propTypes = {
  isSMSD: PropTypes.bool.isRequired,
  notLoggedIn: PropTypes.bool.isRequired,
  productDescription: PropTypes.string,
  isSB: PropTypes.bool.isRequired,
};
ReleaseNotes.defaultProps = {
  productDescription: null,
};
