import { combineReducers } from "@reduxjs/toolkit";

import products from "./products";
import productsRange from "./productsRange";
import friendlyNames from "./friendlyNames";
import licenseReset from "./licenseReset";

export default combineReducers({
  products,
  productsRange,
  friendlyNames,
  licenseReset,
});
