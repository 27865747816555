import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Divider, Segment } from "semantic-ui-react";
import { Preloader } from "@macrium/shared-components";
import { isEmpty, get, isNil } from "lodash";

import "./AccountDetails.styles.scss";
import Details from "../Details/Details";
import CommPrefs from "../CommPrefs/CommPrefs";
import TwoFA from "../TwoFA/TwoFA";
import Billing from "../BillingChange/Billing";
import SupportNumber from "../SupportNumber/SupportNumber";
import RegistrationCode from "../RegistrationCode/RegistrationCode";
import { getAccountDetails, isLoadingDetails, isUpdatingEmail } from "../../../selectors/basics";
import { getAccountDetailsAsync, updateAccountDetailsAsync } from "../../../slices/account/details";
import Page from "../../generic/Page";
import { REJECTED } from "../../../constants/redux-action-statuses";
import { hasActiveSupport } from "../../../selectors/licenses";
import AddressChange from "../AddressChange/AddressChange";
import PasswordChange from "../PasswordChange/PasswordChange";
import { setIsBlocking } from "../../../slices/prompt/prompt";
import DeleteAccount from "../DeleteAccount/DeleteAccount";

const AccountDetails = () => {
  const dispatch = useDispatch();
  const accountDetails = useSelector(getAccountDetails);
  const [updatedDetails, setUpdatedDetails] = useState(accountDetails);
  const [editMode, setEditMode] = useState(false);
  const isLoading = useSelector(isLoadingDetails);
  const updatingEmail = useSelector(isUpdatingEmail);
  const hasSupport = useSelector(hasActiveSupport);

  useEffect(() => {
    dispatch(getAccountDetailsAsync());
  }, [dispatch]);

  useEffect(() => {
    if (accountDetails) {
      setUpdatedDetails(accountDetails);
    }
  }, [accountDetails]);

  const handleChange = (e, field) => {
    dispatch(setIsBlocking(true));
    setUpdatedDetails((prevDetails) => ({
      ...prevDetails,
      [field]: e.target.value,
    }));
  };

  const handleSave = async (data = {}) => {
    if (isEmpty(data)) return;

    const updatedData = { ...updatedDetails, ...data };

    const res = await dispatch(updateAccountDetailsAsync(updatedData));

    // if the request failed for any reason, return early so we don't
    // show override the current fields with the "expected" new fields
    if (get(res, "meta.requestStatus") === REJECTED) return res;

    dispatch(setIsBlocking(false));
    setUpdatedDetails(updatedData);
    setEditMode(false);
    return res;
  };

  const subscribeFlag = accountDetails ? accountDetails.subscribe : false;
  const twoFAFlag = accountDetails ? accountDetails.twoFA : false;
  const showSupportKey = accountDetails?.showSupportPhoneNumber;

  return (
    <Page title="Account Settings">
      <Preloader loading={isLoading} size="massive" content={updatingEmail ? "Updating Email Address" : ""} />

      <Segment raised className="account-settings-segment">
        <Details
          editMode={editMode}
          setEditMode={setEditMode}
          accountDetails={updatedDetails}
          handleSave={handleSave}
        />
        <Divider className="mb3" />
        <AddressChange accountDetails={updatedDetails} />
        <Divider className="mb3" />
        <PasswordChange label="Password" loginMethod={updatedDetails.loginMethod} />
        <Divider className="mb3" />
        <Billing value={accountDetails.billingEmail} handleSave={handleSave} />

        <Divider className="mb3" />
        {showSupportKey && hasSupport && !isNil(accountDetails.supportKey) && !isEmpty(accountDetails.supportKey) && (
          <>
            <SupportNumber supportKey={accountDetails.supportKey} />
            <Divider className="mb3" />
          </>
        )}

        <RegistrationCode urc={accountDetails.urc} />

        <Divider className="mb3" />

        <CommPrefs
          accountDetails={accountDetails}
          subscribeFlag={subscribeFlag}
          handleChange={handleChange}
          handleSave={handleSave}
        />

        <Divider className="mb3" />

        <TwoFA twoFAFlag={twoFAFlag} handleChange={handleChange} handleSave={handleSave} />
        <Divider className="mb3" />
        <DeleteAccount />
      </Segment>
    </Page>
  );
};

export default AccountDetails;
