import OfflineStepProgress from "../OflineStepProgress";
import PropTypes from "prop-types";
import { MIcon } from "@macrium/shared-components";

const OfflineHeader = ({ stepNumber, closeModal, isOem }) => {
  return (
    <div className="flex justify-between flex-center">
      <div className="flex flex-center largeHeader">
        <MIcon name="offline" size="large" className="mr2" color="cLogoLightBlue" />
        {isOem ? "OEM" : "Offline"} License Activation
      </div>
      {stepNumber && (
        <div className="flex flex-center">
          <div className="offline-step-display">
            <OfflineStepProgress currentStep={stepNumber} numberOfSteps={3} />
          </div>
          <div
            data-testid="close-modal-button"
            aria-label="close-modal-button"
            className="clickable pt1"
            onClick={closeModal}
          >
            <MIcon name="close" size="small" color="grey" className="ml2 " />
          </div>
        </div>
      )}
    </div>
  );
};

export default OfflineHeader;

OfflineHeader.propTypes = {
  stepNumber: PropTypes.number,
  closeModal: PropTypes.func.isRequired,
  isOem: PropTypes.bool,
};
OfflineHeader.defaultProps = {
  stepNumber: null,
  isOem: false,
};
