import PropTypes from "prop-types";

const BillingCell = ({ title, children }) => {
  return (
    <div className="order__details">
      <p className="bold m0">{title}</p>
      {children}
    </div>
  );
};

export default BillingCell;

BillingCell.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};
BillingCell.defaultProps = {
  title: null,
};
