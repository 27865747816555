const RocketIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
      <path
        d="M16.2496 13.7493L4.37455 25.6243M17.5226 4.42236C19.0451 5.43262 20.5085 6.62365 21.876 7.99114C23.2553 9.37042 24.455 10.8473 25.4709 12.384M11.5683 9.86945L7.97467 8.67159C7.56081 8.53364 7.10498 8.62142 6.77195 8.90321L3.20051 11.9252C2.46935 12.5439 2.67707 13.7213 3.5758 14.0524L6.9598 15.2991M14.6009 22.9399L15.8476 26.3239C16.1787 27.2227 17.3561 27.4304 17.9748 26.6992L20.9967 23.1278C21.2785 22.7948 21.3663 22.3389 21.2284 21.9251L20.0305 18.3315M24.1853 2.83768L18.0522 3.85986C17.3899 3.97024 16.7825 4.29575 16.3238 4.78604L8.0575 13.6225C5.91481 15.9129 5.97441 19.4899 8.19222 21.7077C10.41 23.9255 13.987 23.9851 16.2775 21.8424L25.1139 13.5761C25.6042 13.1174 25.9297 12.51 26.0401 11.8478L27.0623 5.71466C27.3443 4.02261 25.8773 2.55567 24.1853 2.83768Z"
        stroke="#2B7FFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RocketIcon;
