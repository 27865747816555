import { doGetRequest, doPostRequest, doDeleteRequest } from "./requester";

const apiLicenceUrl = "api/licence";
const apiFriendlyNameUrl = "api/friendlynames";
export const getLicences = () => doGetRequest(`${apiLicenceUrl}/licences`);

export const getLicenceInfo = () => doGetRequest(`${apiLicenceUrl}/getlicenseinfo?extraDetail=true`);
export const getUnassignedLicenses = () => doGetRequest(`${apiLicenceUrl}/getunassignedlicenses`);
export const updateFriendlyName = (data) =>
  doPostRequest(`${apiFriendlyNameUrl}/setfriendlyname`, { ...data, friendlyNameType: "L" });
export const removeFriendlyName = (data) =>
  doDeleteRequest(`${apiFriendlyNameUrl}/friendlyname`, { ...data, friendlyNameType: "L" });
export const postResetLicense = (data) => doPostRequest(`${apiLicenceUrl}/resetlicense`, data);
