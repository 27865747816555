import PropTypes from "prop-types";
import "./LicenseContextMenu.styles.scss";
import ContextMenu from "../../ContextMenu/ContextMenu";
import KebabMenutrigger from "../../ContextMenu/KebabMenuTrigger";

const LicenseContextMenu = ({
  openOfflineActivationModal,
  setOfflineKey,
  offlineActivationKey,
  isOfflineActivated,
  openResetLicenseModal,
  showOfflineOptionsMenu,
  showResetLicenseOption,
  setFriendlyNameLicense,
  friendlyWording,
}) => {
  const optionStyling = "flex justify-center regular flex-center context-menu-option";
  const menuContents = [];
  if (showOfflineOptionsMenu) {
    menuContents.push(
      isOfflineActivated
        ? {
            id: 2,
            content: "View Offline Key",
            onClick: () => setOfflineKey(offlineActivationKey),
            className: optionStyling,
          }
        : {
            id: 1,
            content: "Activate License Offline",
            onClick: openOfflineActivationModal,
            className: optionStyling,
          }
    );
  }
  if (showResetLicenseOption) {
    menuContents.push({
      id: 3,
      content: "Reset License",
      onClick: openResetLicenseModal,
      className: optionStyling,
    });
  }
  menuContents.push({
    id: 4,
    content: friendlyWording,
    onClick: () => setFriendlyNameLicense(),
    className: optionStyling,
  });
  return <ContextMenu trigger={<KebabMenutrigger />} alignRight menuContents={menuContents} />;
};

export default LicenseContextMenu;

LicenseContextMenu.propTypes = {
  openOfflineActivationModal: PropTypes.func.isRequired,
  setOfflineKey: PropTypes.func.isRequired,
  isOfflineActivated: PropTypes.bool.isRequired,
  offlineKey: PropTypes.string,
  setFriendlyNameLicense: PropTypes.func.isRequired,
  friendlyWording: PropTypes.string.isRequired,
};
LicenseContextMenu.defaultProps = {
  offlineKey: null,
};
