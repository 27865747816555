import React from "react";
import { MIcon } from "@macrium/shared-components";
import TrialExpirationLabel from "../TrialExpirationLabel";
import { MACHINE_DEFAULT_NAME, PRODUCTS_EDITIONS } from "../../../../constants/licenses-products";
import "./TrialMachine.styles.scss";
import { formatDate } from "../../../../utils/generic";
import ReflectXSmallImage from "../../ReflectXImage/RelectXSmallImage";

const TrialMachine = ({ install, index, edition }) => {
  const { description, expireTimes, trialInstalledOn, expiresInHours, version } = install;
  const productEdition = edition || PRODUCTS_EDITIONS[description];
  return (
    <div className="trial-machine-row">
      <div className="flex flex-wrap trial-machine-block">
        <div className="flex flex-center bold">
          <MIcon name="desktop" className="mr2" />
          <span className="mr3">
            {MACHINE_DEFAULT_NAME[description] || "Computer"} {index + 1}
          </span>
        </div>
        <div style={{ width: "15em" }}>
          {expiresInHours <= 0 ? (
            <>Expired On: {formatDate(expireTimes)}</>
          ) : (
            <>Installed On: {formatDate(trialInstalledOn)}</>
          )}
        </div>
        {version && (
          <div>
            {version.startsWith("10") && productEdition ? (
              <ReflectXSmallImage edition={productEdition} description={description} />
            ) : (
              `v${version}`
            )}
          </div>
        )}
      </div>
      <div className="flex flex-column trial-machine-row__label">
        {expireTimes && (
          <TrialExpirationLabel expiresInHours={expiresInHours} isExtendedTrial={install.isExtendedTrial} />
        )}
      </div>
    </div>
  );
};

export default TrialMachine;
