import { MIcon } from "@macrium/shared-components";
import PropTypes from "prop-types";

const StepCircle = ({ isActive, isComplete, step }) => {
  if (isComplete)
    return (
      <div data-testid="completed-step">
        <MIcon name="check circle invert" color="blue" width="35" height="35" />
      </div>
    );
  if (isActive)
    return (
      <div data-testid="active-step" className={"activeStep basicStep"}>
        {step}
      </div>
    );
  return (
    <div data-testid="inactive-step" className="inactiveStep basicStep">
      {step}
    </div>
  );
};
StepCircle.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isComplete: PropTypes.bool.isRequired,
  step: PropTypes.number.isRequired,
};
const OfflineStepProgress = ({ currentStep, numberOfSteps }) => {
  const steps = Array(numberOfSteps)
    .fill()
    .map((_, i) => i + 1);
  return (
    <div className="flex flex-center">
      {steps.map((step) => (
        <div key={step} className="flex flex-center">
          <StepCircle isActive={currentStep === step} isComplete={currentStep > step} step={step} />
          {step !== numberOfSteps && (
            <div className={`basic-line ${currentStep > step ? "completed-line" : "incomplete-line"}`} />
          )}
        </div>
      ))}
    </div>
  );
};
OfflineStepProgress.propTypes = {
  currentStep: PropTypes.number.isRequired,
  numberOfSteps: PropTypes.number.isRequired,
};
export default OfflineStepProgress;
