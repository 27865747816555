import { createSelector } from "reselect";
import { reduce, find } from "lodash";

import { getCurrentProducts } from "./licenses";
import { isLegacy } from "../utils/products";
import moment from "moment";
import { PERPETUAL } from "../constants/licenses-products";
import { getPlatformXReleaseDate, getReflectXReleaseDate, productsRangeBasicLookup } from "./products";
import {
  isProductSiteBackup,
  isProductSiteDeployByEdition,
  isProductSiteManager,
  isProductSiteManagerByEdition,
} from "../utils/license/productIsSiteManager";
import { SITE_BACKUP, SITE_MANAGER } from "../constants/licenses";

/**
 * Order and split products into Products and Legacy products
 */
export const getProductsToDownloadsPage = createSelector(
  [getCurrentProducts, productsRangeBasicLookup, getReflectXReleaseDate, getPlatformXReleaseDate],
  (allProducts, productsRangeLookup, reflectXReleaseDate, platformXReleaseDate) => {
    const iteration1 = reduce(
      allProducts,
      (res, product) => {
        // (1) Iterate all products and add them to appropriate section
        if (product.ltscVersion) {
          res.products.push(product);
          return res;
        } else if (product.isTrial === "1" || !isLegacy(product.version)) {
          const removeSMandReplace = () => {
            const newResult =
              res?.products?.filter(
                (prod) => !isProductSiteManager(prod.description) && !isProductSiteBackup(prod.description)
              ) || [];
            res.products = newResult;
            // need to ensure version X shows as site backup
            res.products.push({ ...product, description: SITE_BACKUP });
          };
          if (isProductSiteManagerByEdition(product.edition)) {
            removeSMandReplace();
          } else if (!res.products.find((prod) => prod.edition === product.edition && !prod.ltscVersion)) {
            res.products.push(product);
          }
        } else {
          res.legacyProducts.push(product);
        }

        // (2) Now that the products are on their own section, check if v8 product card is needed depending on:
        // -> If v10 product card / license & it was purchased before the X release
        //    -> offer v8 download product card if there isn't any perpetual licenses already for the same product
        if (product.isTrial !== "1" && !isLegacy(product.version)) {
          const isSD = isProductSiteDeployByEdition(product.edition);
          const isSMorSB = isProductSiteManagerByEdition(product.edition);
          if (
            isSMorSB ||
            (isSD && moment.unix(product.purchasedOn).isBefore(platformXReleaseDate)) ||
            (!isSD && !isSMorSB && moment.unix(product.purchasedOn).isBefore(reflectXReleaseDate))
          ) {
            const noPerpetualProduct = !find(
              allProducts,
              (prod) => prod.edition === product.edition && prod.version === "8" && !prod.ltscVersion
            );

            // If there isn't a perpetual product, build one and add it to legacy
            if (noPerpetualProduct) {
              const urls = find(
                productsRangeLookup[product.edition]?.versionInfo,
                (info) => info.majorVersion === 8 && !info.ltscVersion // major version is a number. TODO: As part of the backend refactor
              );
              if (
                //checks if any duplicates v8 already added
                !res.legacyProducts.find(
                  (legacyProduct) => legacyProduct.edition === product.edition && Number(legacyProduct.version) === 8
                )
              ) {
                res.legacyProducts.push({
                  downloadURL32: urls?.downloadURL32,
                  downloadURL64: urls?.downloadURL64,
                  downloadURLARM: urls?.downloadURLARM,
                  releaseNotes: urls?.releaseNotes,
                  edition: product.edition,
                  description: isProductSiteBackup(product.description) ? SITE_MANAGER : product.description,
                  subscription: PERPETUAL,
                  version: "8",
                  hideUpgradeButton: true,
                  isTrial: product.isTrial,
                });
              }
            }
          }
        }

        return res;
      },
      {
        products: [],
        legacyProducts: [],
      }
    );

    return iteration1;
  }
);
