import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LOADING, IDLE, FAILED } from "../actionStatuses.js";
import { toast } from "react-toastify";
import { getBillingInfo, getManageSubURL, getOrderInvoice } from "../../app/api/billing.js";
import { get, isEmpty } from "lodash";
import { ERROR } from "../../constants/toast.js";

const REDUCER_NAME = "billing";

const initialState = {
  status: LOADING,
  // subscriptionStatus: LOADING,
  orders: [],
  // subscriptions: [],
  // subscriptionError: "",
  error: "",
  loaded: false,
};

export const fetchBillingAsync = createAsyncThunk("billing, fetchBilling", async (thunkAPI) => {
  const res = await getBillingInfo();
  if (!res.success) return thunkAPI.rejectWithValue(res);

  return res;
});
// export const fetchSubscriptionsAsync = createAsyncThunk("billing, fetchSubs", async (thunkAPI) => {
//   const res = await getSubscriptionInfo();
//   if (!res.success) return thunkAPI.rejectWithValue(res);
//   return res;
// });
export const fetchInvoiceAsync = createAsyncThunk("billing, fetchInvoice", async (orderNumber, thunkAPI) => {
  const res = await getOrderInvoice(orderNumber);
  if (!res.success) {
    toast(`Could not download your invoice for order number ${orderNumber}`, { type: ERROR, className: "toast-large" });
    return thunkAPI.rejectWithValue(res);
  }
  return res;
});

export const fetchmanagSubscriptionUrl = createAsyncThunk(
  "billing, fetchManagePaymentURL",
  async (orderNumber, thunkAPI) => {
    const res = await getManageSubURL(orderNumber);
    if (!res.success) {
      toast(`Could not access your subscription for order number ${orderNumber}`, {
        type: ERROR,
        className: "toast-large",
      });
      return thunkAPI.rejectWithValue(res);
    }

    return res;
  }
);

export const billingSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBillingAsync.pending, (state) => {
        if (!state.loaded || !isEmpty(state.error)) state.status = LOADING;
      })
      .addCase(fetchBillingAsync.fulfilled, (state, { payload }) => {
        state.status = IDLE;
        state.orders = get(payload, "data.summary", []);
        state.error = "";
      })
      .addCase(fetchBillingAsync.rejected, (state, { payload }) => {
        state.status = FAILED;
        state.error = payload.message;
      });
    // .addCase(fetchSubscriptionsAsync.pending, (state) => {
    //   state.subscriptionStatus = LOADING;
    // })
    // .addCase(fetchSubscriptionsAsync.fulfilled, (state, { payload }) => {
    //   state.subscriptionStatus = IDLE;
    //   state.subscriptions = get(payload, "data.subscriptions", []);
    //   state.subscriptionError = "";
    // })
    // .addCase(fetchSubscriptionsAsync.rejected, (state, { payload }) => {
    //   state.subscriptionStatus = FAILED;
    //   state.subscriptionError = payload.message;
    // });
  },
});

export default billingSlice.reducer;
