export const PARTNER_PORTAL = "https://portal.macrium.com";
export const ARTICLES_ALL = "https://www.macrium.com/knowledge-base";
export const OFF_ACTIVATION_KB = "https://go.macrium.com/offlineactivationreflectkb";
export const OFF_ACTIVATION_SM_KB = "https://go.macrium.com/offlineactivationsmkb";
export const OFF_ACTIVATION_SD_KB = "https://go.macrium.com/offlineactivationsdkb";
export const USER_FORUM = "https://forum.macrium.com/";
export const GET_SUPPORT = "https://portal.macrium.com/renewalwizard1";
export const SUBMIT_TICKET = "https://help.macrium.com/support/tickets/new";
export const PRODUCT_SUPPORT_POLICY = "https://www.macrium.com/product-support-policy";
export const PRIVACY_POLICY = "https://www.macrium.com/terms-and-privacy-policy-full";
export const EULA_POLICY = "https://www.macrium.com/eula";
export const COOKIE_POLICY = "https://www.macrium.com/terms-and-privacy-policy-full";
export const YOUTUBE_LINK = "//www.youtube.com/macrium";
export const OFF_ACTIVATION_VIDEO_URL = "https://www.youtube.com/embed/Uhx0M5-Ello";
export const OFF_ACT_SM_SD_VIDEO_URL = "https://www.youtube.com/embed/9Om5FzcATK0";
export const MOST_RECENT_REFLECT_RELEASE_NOTES = "https://updates.macrium.com/reflect/v10/latest_release_notes.asp";
export const SITE_MANAGER_RELEASE_NOTES = "https://knowledgebase.macrium.com/display/MSM8/Release+Notes";
export const LTSC_WEBSITE_PAGE = "https://go.macrium.com/ltscwebsitepageredirect";
export const X_WEBSITE_PAGE = "https://www.macrium.com/reflect-x";
export const SB_WEBSITE_PAGE = "https://go.macrium.com/website-sitebackup";
