import { isMobile, isTablet, isIOS } from "react-device-detect";
import PropTypes from "prop-types";
import CreateAnAccount from "./CreateAnAccount/CreateAnAccount";
import { EDITION_TO_PRODUCT_DESCRIPTION } from "../../../constants/licenses-products";

const MainHeader = ({ isLoginPage, isTrialPage }) => {
  const isMobileDevice = isMobile || isTablet || isIOS;
  const header = () => {
    if (isTrialPage) {
      const productName = EDITION_TO_PRODUCT_DESCRIPTION[isTrialPage.edition] || "";
      return `Download ${productName} Trial`;
    }
    if (isLoginPage) {
      return "Login to your Macrium Account";
    }
    return "Create a Macrium Account";
  };

  const subHeader = () => {
    if (isTrialPage) {
      return "Create an account or login to start your free trial now";
    }
    if (!isLoginPage) {
      return "Access Macrium products and licenses, and manage your account details";
    }
    return "";
  };
  return (
    <CreateAnAccount
      header={header()}
      subHeader={subHeader()}
      hideForMobile={isMobileDevice}
      showIcons={!isLoginPage || isTrialPage}
    />
  );
};
export default MainHeader;

MainHeader.propTypes = {
  isLoginPage: PropTypes.bool.isRequired,
  isTrialPage: PropTypes.shape({
    architecture: PropTypes.string,
    edition: PropTypes.number,
    trialSource: PropTypes.string,
  }),
};
MainHeader.defaultProps = {
  isTrialPage: null,
};
