import { createSelector } from "reselect";
import { getOrders, getProductsData } from "./basics";
import { isNil } from "lodash";
// import subsriptionEndsWithin2Weeks from "../utils/billing/subscriptionEndsWithin2Weeks";
import { COMPLETE_STATUS } from "../constants/billing";
// import isRenewalDateInFuture from "../utils/billing/isRenewalDateInFuture";
import moment from "moment";
import { getLicensesForLicensesPage } from "./licenses";

// export const hasResellerOrdersOrSubsSelector = createSelector(
//   [getOrders, getSubscriptions],
//   (orders, subscriptions) => {
//     if (orders && subscriptions) {
//       return !!orders.find((order) => order.reseller) || !!subscriptions.find((sub) => sub.reseller);
//     }
//     return false;
//   }
// );
export const hasResellerOrdersOrSubsSelector = createSelector([getOrders], (orders) => {
  if (orders) {
    return !!orders.find((order) => order.reseller);
  }
  return false;
});
export const getOrdersWithDisplayableContent = createSelector(
  [getOrders, getProductsData, getLicensesForLicensesPage],
  (orders, rawLicenseData, productsWithLicenses) => {
    if (orders) {
      return orders.map((order) => {
        //initial matching licenses
        const licensesOnOrder = rawLicenseData.filter(
          (license) => license.subsTransId === order.transId || license.transId === order.transId
        );
        //split down into 1 long array of associated licenses per order
        const reducedLicenses = licensesOnOrder.reduce((acc, license) => {
          const licenseKeys = license.licenseKeys.split(",");
          licenseKeys.forEach((key) => {
            acc.push(key);
          });
          return acc;
        }, []);
        //filter out the licenses that are not in the productsWithLicenses array
        const productsOnOrder = Object.values(productsWithLicenses).filter((product) =>
          product.licenses.find((lic) => reducedLicenses.includes(lic.licenseId))
        );
        const mappedProductsWithAssoctiatedLicenses = productsOnOrder.map((product) => {
          const associatedLicenses = product.licenses.filter((lic) => reducedLicenses.includes(lic.licenseId));
          return {
            description: product.description,
            licenses: associatedLicenses,
          };
        });
        const orderIsNotFromReseller =
          (isNil(order.reseller) || order.reseller === "") && !order.transId.startsWith("MR");
        const getSellerName = () => {
          if (orderIsNotFromReseller) {
            return "Macrium - Cleverbridge";
          }
          return order.reseller || "-";
        };
        return {
          ...order,
          resellerEmail: order.resellerEmail || "-",
          orderIsReseller: !orderIsNotFromReseller,
          isRefunded: order.status !== COMPLETE_STATUS,
          purchaseDate: moment.unix(order.transTime).local().format("L"),
          associatedProductsAndLicenses: mappedProductsWithAssoctiatedLicenses,
          seller: getSellerName(),
        };
      });
    }
    return [];
  }
);
// const positiveStatuses = [ACT, NEW];
// const getStatus = (sub) => {
//   if (isRenewalDateInFuture(sub.nextRenewalDate)) return ACT;
//   return DEA;
// };
// const getAutoRenew = (sub) => {
//   if (!positiveStatuses.includes(sub.status)) {
//     return false;
//   }
//   return sub.autoRenew;
// };
// export const getSubscriptionsWithResellersSelector = createSelector([getSubscriptions], (subscriptions) => {
//   if (subscriptions) {
//     return subscriptions.map((sub) => {
//       return {
//         ...sub,
//         status: getStatus(sub),
//         autoRenew: getAutoRenew(sub),
//         reseller: sub.reseller || null,
//         expiresWithin14Days:
//           subsriptionEndsWithin2Weeks(sub.nextRenewalDate) &&
//           (!sub.autoRenew || !positiveStatuses.includes(sub.status)),
//         hasExpired: !isRenewalDateInFuture(sub.nextRenewalDate),
//       };
//     });
//   }
//   return [];
// });
// export const hasSubscriptionEndingSoonSelector = createSelector([getSubscriptions], (subscriptions) => {
//   if (subscriptions) {
//     return !!subscriptions.find((order) => subsriptionEndsWithin2Weeks(order.nextRenewalDate) && !order.autoRenew);
//   }
//   return false;
// });
// export const hasExpiredSubscriptionsSelector = createSelector([getSubscriptions], (subscriptions) => {
//   if (subscriptions) {
//     return !!subscriptions.find((order) => !isRenewalDateInFuture(order.nextRenewalDate));
//   }
//   return false;
// });
