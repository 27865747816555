import PropTypes from "prop-types";

const TableCell = ({ children, mobileHeader, isBillingPage }) => (
  <div className={`table_cell ${isBillingPage ? "mobile_table_cell" : ""}`}>
    {mobileHeader && <div className={isBillingPage ? "billing_mobile-header" : "mobile-header"}>{mobileHeader}</div>}
    {children}
  </div>
);
export default TableCell;

TableCell.propTypes = {
  children: PropTypes.node,
  mobileHeader: PropTypes.string,
  isBillingPage: PropTypes.bool,
};
TableCell.defaultProps = {
  isBillingPage: false,
  mobileHeader: "",
};
