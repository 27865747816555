import ReflectXLogo from "./Reflect-X-Logo";
import PropTypes from "prop-types";
import "./ReflectXImage.styles.scss";
import SiteDeployLogo from "./SiteDeployLogo";
import { PRODUCTS_EDITIONS } from "../../../constants/licenses-products";
import { SERVER_PLUS, SITE_BACKUP, SITE_MANAGER, SITEDEPLOY } from "../../../constants/licenses";
import SiteBackupLogo from "./SiteBackupLogo";
import { isProductSiteBackup } from "../../../utils/license/productIsSiteManager";

const ReflectXSmallImage = ({ edition, description }) => {
  const isReflect =
    edition !== PRODUCTS_EDITIONS[SITE_BACKUP] &&
    edition !== PRODUCTS_EDITIONS[SITEDEPLOY] &&
    edition !== PRODUCTS_EDITIONS[SITE_MANAGER];

  return (
    <div className="flex justify-center flex-center">
      {edition === PRODUCTS_EDITIONS[SITE_BACKUP] && isProductSiteBackup(description) && <SiteBackupLogo />}
      {edition === PRODUCTS_EDITIONS[SITEDEPLOY] && <SiteDeployLogo />}
      {isReflect && edition !== PRODUCTS_EDITIONS[SERVER_PLUS] && <ReflectXLogo />}
    </div>
  );
};

export default ReflectXSmallImage;

ReflectXSmallImage.propTypes = {
  edition: PropTypes.number,
  description: PropTypes.string,
};
