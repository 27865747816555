import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Label } from "semantic-ui-react";
import {
  RED_LABEL,
  GREEN_LABEL,
  REFLECT_HOME,
  HOME_LICENSES,
  EXPIRED,
  NO_SUPPORT,
  ORANGE_LABEL,
} from "../../constants/licenses.js";
import "./LicensePage.styles.scss";
import { SUBSCRIPTION } from "../../constants/licenses-products.js";

const getSupportLabelContent = (description, allExpired, allNoSupport) => {
  if (allExpired) {
    return EXPIRED;
  } else if (allNoSupport) {
    return NO_SUPPORT;
  } else if (HOME_LICENSES[description]) {
    return "Home";
  } else {
    return "Business";
  }
};

const SupportExpiry = ({
  description,
  allRenewalSame,
  allExpired,
  nextExpiry,
  lastExpiry,
  subscription,
  ltscVersion,
  allNoSupport,
}) => {
  return (
    <div className="license-card__support-container licence-card__header-item">
      {subscription === SUBSCRIPTION &&
        (allExpired ? (
          <p className="bold m0 pb1">Subscription expired on {moment(lastExpiry).format("L")}</p>
        ) : (
          <p className="bold m0 pb1">
            {allRenewalSame ? "" : "Next "}Subscription renews on: {moment(nextExpiry).format("L")}
          </p>
        ))}
      <div className="flex flex-center">
        <p className="pr1 m0 bold">Support:</p>
        <Label
          className={allExpired ? RED_LABEL : allNoSupport ? ORANGE_LABEL : GREEN_LABEL}
          content={getSupportLabelContent(description, allExpired, allNoSupport)}
        />
      </div>
      {subscription !== SUBSCRIPTION && (
        <p className="m0 pt1 license-card__small-text">
          {allNoSupport ? (
            "Support was not purchased for these licenses"
          ) : (
            <>
              {allExpired
                ? `Support expired on ${moment(lastExpiry).format("L")}`
                : `${allRenewalSame && description === REFLECT_HOME ? "Next " : ""}Support ${
                    description === REFLECT_HOME || ltscVersion ? "expires " : "renews "
                  }on: ${moment(nextExpiry).format("L")}`}
            </>
          )}
        </p>
      )}
    </div>
  );
};

SupportExpiry.propTypes = {
  description: PropTypes.string,
  allRenewalSame: PropTypes.bool,
  allExpired: PropTypes.bool,
  nextExpiry: PropTypes.object,
  lastExpiry: PropTypes.object,
  subscription: PropTypes.string.isRequired,
  ltscVersion: PropTypes.string,
  allNoSupport: PropTypes.bool,
};

SupportExpiry.defaultProps = {
  description: "",
  allRenewalSame: false,
  allExpired: false,
  allNoSupport: false,
  nextExpiry: {},
  lastExpiry: {},
};

export default SupportExpiry;
