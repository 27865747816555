import React from "react";
import PropTypes from "prop-types";
import { Dropdown, Button } from "semantic-ui-react";
import * as DeviceDetect from "react-device-detect";

import { ARCH64, ARCH86, ARM } from "../../../../constants/licenses-products";
import "./DownloadButton.styles.scss";

const DownloadButton = ({ onDownload, className, showARMOption, only64Bit, data, text, isLoading, isTrial }) => {
  const isMobileOrTablet = DeviceDetect.isMobile || DeviceDetect.isTablet || DeviceDetect.isIOS;

  const options = React.useMemo(() => {
    const optns = [
      {
        key: ARCH64,
        text: "64-bit",
        value: ARCH64,
        onClick: () => onDownload(ARCH64),
        as: isTrial ? undefined : "a",
        href: isTrial ? undefined : data.downloadURL64,
        name: ARCH64,
      },
    ];

    if (!only64Bit)
      optns.push({
        key: ARCH86,
        text: "32-bit",
        value: ARCH86,
        onClick: () => onDownload(ARCH86),
        as: isTrial ? undefined : "a",
        href: isTrial ? undefined : data.downloadURL32,
        name: ARCH86,
      });
    if (showARMOption) {
      optns.push({
        key: ARM,
        text: "ARM 64-bit",
        value: ARM,
        onClick: () => onDownload(ARM),
        as: isTrial ? undefined : "a",
        href: isTrial ? undefined : data.downloadURLARM,
        name: ARM,
      });
    }
    return optns;
  }, [onDownload, only64Bit, data, isTrial, showARMOption]);
  return isMobileOrTablet ? null : (
    <div className={className}>
      <Button.Group>
        <Dropdown
          className="flex button basic primary dropdown-arch"
          options={options}
          trigger={<div className="dropdown-arch__content">{text}</div>}
          disabled={isLoading}
        />
      </Button.Group>
    </div>
  );
};

DownloadButton.propTypes = {
  text: PropTypes.any,
  onDownload: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  showARMOption: PropTypes.bool,
  className: PropTypes.string,
  only64Bit: PropTypes.bool,
  data: PropTypes.object.isRequired,
  /* Decides if shows href or not - Disables for trials */
  isTrial: PropTypes.bool,
};

DownloadButton.defaultProps = {
  text: "",
  disabled: false,
  className: "",
  only64Bit: true,
  showARMOption: false,
  isTrial: false,
};

export default DownloadButton;
