import { MIcon } from "@macrium/shared-components";
import PropTypes from "prop-types";
import "./CreateAnAccount.styles.scss";

const InfoComponent = ({ iconName, text }) => (
  <>
    <MIcon name={iconName} size="large" color="cLogoLightBlue" />
    <p className="cGrey3">{text}</p>
  </>
);
const CreateAnAccount = ({ header, subHeader, hideForMobile, showIcons }) => {
  return (
    <div className="trial-message mb2 flex flex-column flex-center">
      <div className="segment-styling">
        <h2 className="mb1">{header}</h2>
        {subHeader && <div>{subHeader}</div>}
      </div>
      {showIcons && hideForMobile && <div className="mb2">Head to a desktop device to download</div>}
      {showIcons && !hideForMobile && (
        <div className="trial-message__summary ">
          <div className="trial-message__summary__item">
            <InfoComponent iconName="download" text="Access and download product trials" />
          </div>

          <div className="trial-message__summary__item">
            <InfoComponent iconName="key" text="View and manage software license keys" />
          </div>

          <div className="trial-message__summary__item">
            <InfoComponent iconName="circle question" text="Access set up guides and help" />
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateAnAccount;
CreateAnAccount.propNames = {
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
  hideForMobile: PropTypes.bool.isRequired,
  showIcons: PropTypes.bool,
};
CreateAnAccount.defaultProps = {
  showIcons: true,
};
