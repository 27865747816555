import React from "react";
import { Form, Input, Icon, Button } from "semantic-ui-react";
import { isEmpty } from "lodash";
import VALIDATOR from "validator";
import { FormFieldError } from "@macrium/shared-components";

const PasswordResetForm = ({ email, setEmail, handleRequestPasswordReset, errorMessage }) => {
  const [errors, setErrors] = React.useState({});

  const handleSubmit = (email) => {
    const errors = {};

    const validEmail = VALIDATOR.isEmail(email);
    if (!validEmail) errors["email"] = "Invalid email";

    setErrors(errors);

    if (isEmpty(errors)) handleRequestPasswordReset();
  };

  return (
    <Form
      onSubmit={() => {
        handleSubmit(email);
      }}
      data-testid="password-reset-form"
    >
      <Form.Field className="forgot-password__form__field" error={!!errors["email"]}>
        <Input
          icon
          iconPosition="left"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autoFocus
          type="email"
        >
          <input />
          <Icon name="envelope" data-testid="email-icon" />
        </Input>
        <FormFieldError error={errors["email"]} />
      </Form.Field>
      <Button
        disabled={!email}
        className="mt2"
        content="Email Me A Recovery Link"
        size="large"
        primary
        fluid
        type="submit"
      />
    </Form>
  );
};

export default PasswordResetForm;
