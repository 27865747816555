import { Segment } from "semantic-ui-react";
import PropTypes from "prop-types";
import TableHeader from "../../TableComponents/TableHeader";
import BillingRow from "./BillingRow";

const OrderTable = ({ orders, setOpenOrders, openOrders }) => {
  return (
    <Segment>
      <div>
        <TableHeader headers={["Order ID", "Purchase Date", "Licensee Email", "Seller"]} billingTable />
        {orders.map((order) => (
          <BillingRow order={order} key={order.transId} openOrders={openOrders} setOpenOrders={setOpenOrders} />
        ))}
      </div>
    </Segment>
  );
};

export default OrderTable;

OrderTable.propTypes = {
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      transId: PropTypes.string,
      licenseeEmail: PropTypes.string,
      resellerEmail: PropTypes.string,
      status: PropTypes.string,
      seller: PropTypes.string,
      isRefunded: PropTypes.bool,
      purchaseDate: PropTypes.string,
      orderIsReseller: PropTypes.bool,
      associatedProductsAndLicenses: PropTypes.array,
    })
  ).isRequired,
  setOpenOrders: PropTypes.func,
  openOrders: PropTypes.arrayOf(PropTypes.string).isRequired,
};
