import { Button, Modal } from "semantic-ui-react";
import { MIcon } from "@macrium/shared-components";
import ReflectXImage from "../ReflectXImage/ReflectXImage";
import { useEffect, useState } from "react";
import ReflectXUpgradeButton from "../ProductCard/ReflectXUpgradeButton";
import { useSelector } from "react-redux";
import { getAccountEmail } from "../../../selectors/basics";
import { SBX_MODAL_DISMISSED, X_MODAL_DISMISSED } from "../../../constants/localStorage";
import { SB_WEBSITE_PAGE, X_WEBSITE_PAGE } from "../../../constants/urls";
import {
  areAllProductsSubs,
  shouldShowBackupXFromNotifications,
  shouldShowUpgradeButtonFromNotifications,
} from "../../../selectors/xNotifications";

const ReflectXModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [productIsX, setProductIsX] = useState(true);
  const userEmail = useSelector(getAccountEmail);
  const showUpgradeButton = useSelector(shouldShowUpgradeButtonFromNotifications);
  const hasBackupProducts = useSelector(shouldShowBackupXFromNotifications);
  const onlyNeedsAppUpdate = useSelector(areAllProductsSubs);

  useEffect(() => {
    const hasSeenReflectXModal = localStorage.getItem(X_MODAL_DISMISSED) === "true";
    const hasSeenBackupXModal = localStorage.getItem(SBX_MODAL_DISMISSED) === "true";
    const shouldShowBackupXModal = !hasSeenBackupXModal && hasBackupProducts;
    if ((!hasSeenReflectXModal || shouldShowBackupXModal) && userEmail) {
      setShowModal(true);
      if (!hasSeenReflectXModal) {
        return setProductIsX(true);
      }
      setProductIsX(false);
    }
  }, [userEmail, productIsX, hasBackupProducts]);

  const handleClose = () => {
    localStorage.setItem(productIsX ? X_MODAL_DISMISSED : SBX_MODAL_DISMISSED, "true");
    setShowModal(false);
  };
  return (
    <Modal size="tiny" open={showModal} onClose={handleClose} closeOnDimmerClick={true}>
      <Modal.Header className="flex flex-start justify-between mb1">
        {productIsX ? (
          <div style={{ height: "62px" }}>
            Reflect X is here!
            {onlyNeedsAppUpdate && (
              <>
                <br /> Upgrade for FREE in the Reflect app
              </>
            )}
          </div>
        ) : (
          <div style={{ height: "62px" }}>
            SiteBackup X is here!{" "}
            {onlyNeedsAppUpdate && (
              <>
                <br /> Start your upgrade today
              </>
            )}
          </div>
        )}
        <span data-testid="close-modal-button" className="flex flex-center cursor-pointer " onClick={handleClose}>
          <MIcon name="close" size="tiny" color="grey" />
        </span>
      </Modal.Header>
      <Modal.Content>
        {productIsX ? (
          <div style={{ height: "40px" }}>
            Explore the all new powerful version of Macrium Reflect{" "}
            {onlyNeedsAppUpdate ? "by upgrading for FREE directly in the app now." : "or Upgrade Now."}
          </div>
        ) : (
          <div>Explore the all new powerful version of SiteManager Platform by upgrading today.</div>
        )}
        <div className="my2">
          <ReflectXImage />
        </div>
        <div className="flex pt1">
          <Button
            content="Learn More"
            secondary
            className="mr2"
            as="a"
            href={productIsX ? X_WEBSITE_PAGE : SB_WEBSITE_PAGE}
            target="_blank"
            rel="noopener noreferrer"
          />
          {showUpgradeButton && <ReflectXUpgradeButton asButton text="Upgrade Now" isPrimary />}
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default ReflectXModal;
