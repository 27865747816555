import {
  SITEDEPLOY,
  SITE_BACKUP,
  SITE_BACKUP_SUBSCRIPTION,
  SITE_MANAGER,
  SITE_MANAGER_SUBSCRIPTION,
} from "../../constants/licenses";
import { PRODUCTS_EDITIONS } from "../../constants/licenses-products";

export const isProductSiteManager = (productDescription) =>
  productDescription === SITE_MANAGER || productDescription === SITE_MANAGER_SUBSCRIPTION;

export const isProductSiteBackup = (productDescription) =>
  productDescription === SITE_BACKUP || productDescription === SITE_BACKUP_SUBSCRIPTION;
export const isProductSiteDeploy = (productDescription) => productDescription === SITEDEPLOY;
export const isProductSiteManagerOrSiteDeploy = (description) =>
  isProductSiteManager(description) || isProductSiteDeploy(description) || isProductSiteBackup(description);

export const isProductSiteManagerByEdition = (edition) => edition === PRODUCTS_EDITIONS[SITE_MANAGER];
export const isProductSiteDeployByEdition = (edition) => edition === PRODUCTS_EDITIONS[SITEDEPLOY];
export const isProductSiteManagerOrSiteDeployByEdition = (edition) =>
  isProductSiteManagerByEdition(edition) || isProductSiteDeployByEdition(edition);
