import { EMAIL_CHECKLIST } from "../../../constants/forms/validation";

const canEmailBeUsedForCompanyDomain = (email) => {
  const domain = email?.split("@")[1] || "";
  const splitDomain = domain?.split(".")[0];
  const validDomainName = EMAIL_CHECKLIST.includes(splitDomain.toLowerCase()) ? "" : domain;
  return validDomainName;
};

export default canEmailBeUsedForCompanyDomain;
