import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updateFriendlyName, removeFriendlyName } from "../../app/api/licences";
import { toast } from "react-toastify";
import { ERROR, SUCCESS } from "../../constants/toast";
import { fetchLicenceInfoAsync } from "./products.js";

const REDUCER_NAME = "friendlyNames";
const initialState = {
  selectedFriendlyName: {},
  savingFriendlyName: false,
};
export const updatedLicenseFriendlyName = createAsyncThunk(
  `${REDUCER_NAME}/setfriendlyName`,
  async (data, thunkAPI) => {
    // needs to be updated to new api call once created
    const response = await updateFriendlyName(data);

    if (!response.success) {
      if (response.message) {
        toast(response.message, { type: ERROR });
      }
      return thunkAPI.rejectWithValue(response);
    }
    toast("Friendly Name has been saved", { type: SUCCESS });
    thunkAPI.dispatch(fetchLicenceInfoAsync());
    return response;
  }
);
export const deleteLicenseFriendlyName = createAsyncThunk(
  `${REDUCER_NAME}/deletefriendlyName`,
  async (data, thunkAPI) => {
    // needs to be updated to new api call once created
    const response = await removeFriendlyName(data);

    if (!response.success) {
      if (response.message) {
        toast(response.message, { type: ERROR });
      }
      return thunkAPI.rejectWithValue(response);
    }
    toast("Friendly Name has been deleted", { type: SUCCESS });
    thunkAPI.dispatch(fetchLicenceInfoAsync());
    return response;
  }
);
export const friendlyNamesSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    clearState: () => initialState,
    setSelectedFriendlyName: (state, { payload: selectedFriendlyName }) => {
      state.selectedFriendlyName = selectedFriendlyName;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updatedLicenseFriendlyName.pending, (state) => {
        state.savingFriendlyName = true;
      })
      .addCase(updatedLicenseFriendlyName.rejected, (state) => {
        state.savingFriendlyName = false;
      })
      .addCase(updatedLicenseFriendlyName.fulfilled, (state) => {
        state.savingFriendlyName = false;
        state.selectedFriendlyName = {};
      })
      .addCase(deleteLicenseFriendlyName.pending, (state) => {
        state.savingFriendlyName = true;
      })
      .addCase(deleteLicenseFriendlyName.rejected, (state) => {
        state.savingFriendlyName = false;
      })
      .addCase(deleteLicenseFriendlyName.fulfilled, (state) => {
        state.savingFriendlyName = false;
        state.selectedFriendlyName = {};
      });
  },
});

export const { clearState, setSelectedFriendlyName } = friendlyNamesSlice.actions;

export default friendlyNamesSlice.reducer;
