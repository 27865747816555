import React from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";

import FormField from "../FormField/FormField";
import EmailChange from "../EmailChange/EmailChange";
import {
  JOB_MAX_LENGTH,
  COMPANY_MAX_LENGTH,
  PHONE_MAX_LENGTH,
  COMPANY_DOMAIN_TOOLTIP,
  COMPANY_WEBSITE_PATTERN,
} from "../../../constants/forms/validation";
import CancelButton from "../../generic/CancelButton";
import EditButton from "../../generic/EditButton";
import FieldWrapper from "../FieldWrapper/FieldWrapper";

const Details = ({ accountDetails, handleSave, editMode, setEditMode }) => {
  const { firstName, lastName, email, company, companyDomain, job, phone, loginMethod } = accountDetails || {};
  const {
    setValue,
    handleSubmit,
    reset,
    control,
    formState: { errors, isDirty, isSubmitting, dirtyFields },
  } = useForm();

  const handleEditClick = async () => {
    reset();
    setValue("firstName", firstName);
    setValue("lastName", lastName);
    setValue("company", company);
    setValue("companyDomain", companyDomain);
    setValue("job", job);
    setValue("phone", phone);
    setEditMode(true);
  };
  const handleCancelClick = () => {
    setEditMode(false);
    reset();
  };
  const areCompanyRoleFieldsRequired = !!(
    accountDetails.job ||
    accountDetails.company ||
    accountDetails.companyDomain ||
    dirtyFields?.companyDomain ||
    dirtyFields?.company ||
    dirtyFields?.job
  );
  return (
    <FieldWrapper header="Your Details">
      <Form onSubmit={handleSubmit(handleSave)}>
        <div className={`account-details__section__right__item ${editMode && "edit-mode"}`}>
          <div className="account-details__section__right__item__wrapper account-details__address__wrapper">
            <div className="account-details__name flex flex-column account-details__address__wrapper__section">
              <FormField
                label="First Name"
                value={firstName || ""}
                name="firstName"
                rules={{ required: "First Name is required" }}
                editMode={editMode}
                errors={errors}
                control={control}
              />
              <FormField
                label="Last Name"
                value={lastName || ""}
                name="lastName"
                rules={{ required: "Last Name is required" }}
                editMode={editMode}
                errors={errors}
                control={control}
              />
              <EmailChange label="Email" value={email || ""} loginMethod={loginMethod} />
              <FormField
                label="Job Role"
                value={job || ""}
                name="job"
                rules={{
                  required: areCompanyRoleFieldsRequired ? "Job Role is required" : false,
                  ...JOB_MAX_LENGTH,
                }}
                editMode={editMode}
                errors={errors}
                control={control}
              />
              <FormField
                label="Company Name"
                value={company || ""}
                name="company"
                rules={{
                  required: areCompanyRoleFieldsRequired ? "Company Name is required" : false,
                  ...COMPANY_MAX_LENGTH,
                }}
                editMode={editMode}
                errors={errors}
                control={control}
              />
              <FormField
                label="Company Website"
                value={companyDomain || ""}
                name="companyDomain"
                popUpMessage={editMode ? COMPANY_DOMAIN_TOOLTIP : ""}
                rules={{
                  required: areCompanyRoleFieldsRequired ? "Company Website is required" : false,
                  ...COMPANY_WEBSITE_PATTERN,
                  ...COMPANY_MAX_LENGTH,
                }}
                editMode={editMode}
                errors={errors}
                control={control}
              />
              <FormField
                label="Phone Number"
                value={phone || ""}
                name="phone"
                rules={{ ...PHONE_MAX_LENGTH }}
                isPhone={true}
                onSave={handleSubmit(handleSave)}
                editMode={editMode}
                errors={errors}
                control={control}
              />
            </div>
          </div>
          <div className={`account-details__btn-wrap ${editMode && "account-details__btn-wrap--edit-mode"}`}>
            {editMode && <CancelButton onCancelClick={handleCancelClick} disabled={isSubmitting} />}
            <EditButton
              editMode={editMode}
              onEditClick={handleEditClick}
              onSaveClick={handleSubmit(handleSave)}
              disabled={(editMode && !isDirty) || isSubmitting}
              isLoading={isSubmitting}
            />
          </div>
        </div>
      </Form>
    </FieldWrapper>
  );
};

Details.propTypes = {
  accountDetails: PropTypes.object.isRequired,
  handleSave: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  setEditMode: PropTypes.func.isRequired,
};

export default Details;
