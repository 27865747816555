import React from "react";
import PropTypes from "prop-types";
import { Form, Popup } from "semantic-ui-react";
import { FormFieldError, MIcon } from "@macrium/shared-components";
import { noop } from "lodash";

import EditInput from "../../generic/Inputs/EditInput";
import FormFieldController from "../../generic/FormFieldController";
import PhoneInput from "../../generic/Inputs/PhoneInput";

const FormField = ({ label, value, name, rules, onSave, isPhone, editMode, errors, control, popUpMessage }) => {
  return (
    <Form.Field error={rules ? !!errors[name] : false}>
      <div className="mb1 bold flex flex-center">
        {label}
        {popUpMessage && (
          <Popup
            position="top center"
            trigger={
              <div data-testid="popup-trigger">
                <MIcon size="largeSmall" name="info circle" className="mlhalf mt1 mbhalf" color="blue" />
              </div>
            }
            wide
            hideOnScroll
            content={<div>{popUpMessage}</div>}
            hoverable
          />
        )}
      </div>

      {editMode ? (
        <div>
          <FormFieldController
            name={name}
            control={control}
            rules={rules}
            render={({ field: { ref, ...field } }) =>
              isPhone ? (
                <PhoneInput name="phone" showIcon={false} placeholder="" onSave={onSave} {...field} />
              ) : (
                <EditInput {...field} name={name} autoFocus={name === "firstName"} />
              )
            }
          />
          <FormFieldError className="py1" error={errors[name]?.message} />
        </div>
      ) : (
        <div>{value || "-"}</div>
      )}
    </Form.Field>
  );
};
FormField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  editMode: PropTypes.bool.isRequired,
  isPhone: PropTypes.bool,
  popUpMessag: PropTypes.string,
  errors: PropTypes.object,
  control: PropTypes.object.isRequired,
  onSave: PropTypes.func,
};

FormField.defaultProps = {
  label: "",
  value: "",
  popUpMessage: "",
  rules: undefined,
  isPhone: false,
  errors: {},
  onSave: noop,
};

export default FormField;
