import React from "react";
import PropTypes from "prop-types";
import { MIcon, Link } from "@macrium/shared-components";
import { isMobile, isTablet, isIOS } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "semantic-ui-react";
import { downloadTrialAsync } from "../../../../slices/downloads/downloads";
import { addOnboardingCardAsync } from "../../../../slices/onboarding/onboarding";
import DownloadButton from "../DownloadButton/DownloadButton";
import { DEPLOYMENT_KIT, REFLECT_HOME, REFLECT_SERVER, TECHNICIAN } from "../../../../constants/licenses";
import { EDITION_TO_HOTJAR_EVENT, SUBSCRIPTION } from "../../../../constants/licenses-products";
import { PRODUCT_SUPPORT_POLICY, SUBMIT_TICKET } from "../../../../constants/urls";
import { SITE_TYPE_USER_PORTAL } from "../../../../utils/gtm/downloads";
import BuyNowButton from "../BuyNowButton";
import { isLegacy } from "../../../../utils/products";
import UpgradeButton from "../../UpgradeButton/UpgradeButton";
import { CLICKED_DOWNLOAD_BUTTON } from "../../../../constants/hotjarEvents";
import { getLastDownloadedEdition } from "../../../../selectors/basics";

const getDownloadText = (data, overViewPage) => {
  if (data.ltscVersion) {
    return `Download LTSC ${data.ltscVersion}`;
  }
  if (overViewPage) {
    return "Download";
  }
  return `Download v${data.version}`;
};

const LicenseCTAs = ({ data, productRange, overViewPage, isLoading }) => {
  const dispatch = useDispatch();
  const lastDownloadedEdition = useSelector(getLastDownloadedEdition);
  const isLastDownloadedEdition = lastDownloadedEdition === data.edition || lastDownloadedEdition === null;
  const downloadTrial = async (arch) => {
    const args = {
      architecture: arch,
      edition: data.edition,
      productName: data.description,
    };
    if (data.description === REFLECT_SERVER || data.description === DEPLOYMENT_KIT || data.description === TECHNICIAN)
      args.description = data.description;
    dispatch(downloadTrialAsync(args, { siteType: SITE_TYPE_USER_PORTAL }));
  };
  const downloadUrl = data.downloadURL64;
  const isTechProduct = data.description === TECHNICIAN || data.description === DEPLOYMENT_KIT;
  const isNotEligibleForTrial = isTechProduct && (data.trialExpired || data.trialInstalled);
  const onDownloadProduct = () => {
    if (window.hj) {
      window.hj("event", EDITION_TO_HOTJAR_EVENT[data.edition] || CLICKED_DOWNLOAD_BUTTON);
    }

    if (data.edition === 6) {
      dispatch(addOnboardingCardAsync({ edition: 6, description: REFLECT_HOME }));
    }
  };
  const isLegacyProduct = isLegacy(data.version);
  if ((isLegacyProduct && data.isTrial !== "1") || data.ltscVersion) {
    return (
      <div className="product-card__bottom__buttons">
        {!data.hideUpgradeButton && <UpgradeButton pageHeader={false} />}
        <DownloadButton
          isLoading={isLoading}
          onDownload={onDownloadProduct}
          only64Bit={!data.downloadURL32}
          data={data}
          showARMOption={!!data.downloadURLARM}
          text={getDownloadText(data, overViewPage)}
        />
      </div>
    );
  }
  return (
    <div className="product-card__bottom__buttons">
      {productRange && (
        <div className="flex flex-column">
          <div>
            {isNotEligibleForTrial && (
              <div className="flex items-start mb2 ">
                <MIcon name="info circle" size="big" color="cGrey4" className="mr1" />
                <div>
                  To download another trial, please contact{" "}
                  <a
                    style={{ display: "inline-flex" }}
                    className="inline"
                    href={SUBMIT_TICKET}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Link>Support</Link>
                  </a>
                </div>
              </div>
            )}
            {!isNotEligibleForTrial && isLastDownloadedEdition && (
              <DownloadButton
                onDownload={downloadTrial}
                isLoading={isLoading}
                className="mb2"
                data={data}
                text="Download Trial"
                showARMOption={!!data.downloadURLARM}
                only64Bit={!data.downloadURL32}
                isTrial
              />
            )}
          </div>

          {data.buyNowURL && (
            <div className="flex">
              <BuyNowButton data={data} />
            </div>
          )}
        </div>
      )}
      {!productRange && (
        <>
          {data.isTrial === "1" ? (
            <BuyNowButton data={data} />
          ) : (
            <>
              {(data.subscription === SUBSCRIPTION || Number(data.version) >= 6) &&
                !isMobile &&
                !isTablet &&
                !isIOS && (
                  <div className="flex justify-between flex-end">
                    {downloadUrl && (
                      <DownloadButton
                        isLoading={isLoading}
                        onDownload={onDownloadProduct}
                        only64Bit={!data.downloadURL32}
                        showARMOption={!!data.downloadURLARM}
                        data={data}
                        text="Download"
                      />
                    )}
                  </div>
                )}
              {data.subscription !== SUBSCRIPTION && Number(data.version) !== 10 && Number(data.version) < 6 && (
                <Button
                  as="a"
                  primary
                  basic
                  content="End of Life"
                  href={PRODUCT_SUPPORT_POLICY}
                  target="_blank"
                  rel="noopener noreferrer"
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

LicenseCTAs.propTypes = {
  data: PropTypes.object,
  productRange: PropTypes.bool,
  products: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
};
LicenseCTAs.defaultProps = {
  data: {},
  productRange: false,
  products: [],
  isLoading: false,
};

export default LicenseCTAs;
