import { DEPLOYMENT_KIT, DEPLOYMENT_KIT_SUBSCRIPTION, TECHNICIAN, TECHNICIAN_SUBSCRIPTION } from "../constants/licenses";
import { PRODUCTS_EDITIONS } from "../constants/licenses-products";

/**
 *
 * @param {string} description, name of the product
 * @param {number} sumCount - count of licenses
 * @param {number} expiredCount - count of expired licenses
 * @returns {number} user count
 */
export const getLicenseUserCount = (edition, sumCount, expiredCount) => {
  if (
    edition !== PRODUCTS_EDITIONS[TECHNICIAN] && edition !== PRODUCTS_EDITIONS[TECHNICIAN_SUBSCRIPTION] &&
    edition !== PRODUCTS_EDITIONS[DEPLOYMENT_KIT] && edition !== PRODUCTS_EDITIONS[DEPLOYMENT_KIT_SUBSCRIPTION]
  ) return 0;

  const active = sumCount - expiredCount;

  return edition === PRODUCTS_EDITIONS[TECHNICIAN] ? active : active * 5;
};
