import { Label } from "semantic-ui-react";
import PropTypes from "prop-types";
import { GREEN_LABEL, RED_LABEL, REFUNDED_TEXT, YELLOW_LABEL } from "../../../constants/licenses";
import {
  COMPLETE,
  COMPLETE_STATUS,
  PARTIALLY_REFUNDED,
  PARTIALLY_REFUNDED_STATUS,
  REFUNDED_STATUS,
} from "../../../constants/billing";

const StatusTablet = ({ status }) => {
  if (status === COMPLETE_STATUS) return <Label content={COMPLETE} className={GREEN_LABEL} />;
  if (status === REFUNDED_STATUS) return <Label content={REFUNDED_TEXT} className={RED_LABEL} />;
  if (status === PARTIALLY_REFUNDED_STATUS) return <Label content={PARTIALLY_REFUNDED} className={YELLOW_LABEL} />;
  return <div />;
};

export default StatusTablet;

StatusTablet.propTypes = {
  status: PropTypes.string,
};
StatusTablet.defaultProps = {
  status: "",
};
