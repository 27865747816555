import Cookies from "js-cookie";
import moment from "moment";
import { get } from "lodash";

import { AUTH_TOKEN_EXP_TIME } from "../constants/auth";
import CONFIG from "../app/config";

const REMEMBER_EMAIL = "REMEMBER_EMAIL";
const REMEMBER_CHECK_BOX = "REMEMBER_CHECK_BOX";
const COOKIE_NAME = "SP_AUTH_TOKEN";

/**
 * Parses the aut token to extract its information (expiration date, ...)
 * @param {string} token
 * @returns
 */
export const parseAuthToken = (token) => {
  try {
    return JSON.parse(window.atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

/**
 * Gets the initial auth token from the cookies value.
 * If it has expired or if it does not exist, return an empty string.
 * @returns {string} Return token that is in a cookie
 */
export const getInitialAuthTokenForStore = () => {
  const authTokenCookie = getAuthCookie();

  if (!authTokenCookie) return "";

  const token = parseAuthToken(authTokenCookie);

  if (!token) return "";

  const authTokenExp = token.exp;

  const momentExpirationTime = moment.unix(authTokenExp);

  // If it has expired...
  if (moment().isAfter(momentExpirationTime)) return "";

  return authTokenCookie;
};

const inTenMinutes = () => new Date(new Date().getTime() + AUTH_TOKEN_EXP_TIME);

export const getAuthCookie = () => Cookies.get(COOKIE_NAME);
export const setAuthCookie = (token) =>
  Cookies.set(COOKIE_NAME, token, {
    expires: inTenMinutes(),
    secure: CONFIG.NODE_ENV === "production",
    sameSite: "strict",
  });

export const getRememberEmail = () => Cookies.get(REMEMBER_EMAIL);
export const setRememberEmail = (email) => Cookies.set(REMEMBER_EMAIL, email);
export const removeRememberEmail = () => Cookies.remove(REMEMBER_EMAIL);

export const getRememberCheckBox = () => Cookies.get(REMEMBER_CHECK_BOX);
export const setRememberCheckBox = (checked) => Cookies.set(REMEMBER_CHECK_BOX, checked);

export const getAuthCookieExp = () => {
  const authTokenCookie = getAuthCookie();

  return get(parseAuthToken(authTokenCookie), "exp");
};

export const hasAuthTokenCookieExpired = () => !getAuthCookieExp();

export const removeAuthCookie = () => {
  /**
   * Firefox is special - t does not remove cookies that have expired (Chrome does) and there was no way to remove it programmatically.
   * The only solution I found is to set the value of the same cookie with "undefined" and then delete
   *
   */
  Cookies.set(COOKIE_NAME, undefined);

  Cookies.remove(COOKIE_NAME);
};

export const setLoginCookie = () => {
  Cookies.set("hasLoggedIn", true, { expires: 30 });
};
