import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { ERROR, SUCCESS } from "../../constants/toast";
import { fetchLicenceInfoAsync } from "./products.js";
import { postResetLicense } from "../../app/api/licences.js";

const REDUCER_NAME = "resetLicenseKey";
const initialState = {
  resettingLicense: false,
  licenseToReset: null,
};
export const resetLicenseKey = createAsyncThunk(`${REDUCER_NAME}/resetLicenseKey`, async (data, thunkAPI) => {
  // needs to be updated to new api call once created
  const response = await postResetLicense({ license: data });

  if (!response.success) {
    if (response.message) {
      toast(response.message, { type: ERROR });
    }
    return thunkAPI.rejectWithValue(response);
  }
  toast("License Key has been reset", { type: SUCCESS });
  thunkAPI.dispatch(fetchLicenceInfoAsync());
  return response;
});
export const licenseResetSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    clearState: () => initialState,
    setLicenseToReset: (state, { payload: license }) => {
      state.licenseToReset = license;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetLicenseKey.pending, (state) => {
        state.resettingLicense = true;
      })
      .addCase(resetLicenseKey.rejected, (state) => {
        state.resettingLicense = false;
      })
      .addCase(resetLicenseKey.fulfilled, (state) => {
        state.resettingLicense = false;
        state.licenseToReset = null;
      });
  },
});

export const { clearState, setLicenseToReset } = licenseResetSlice.actions;

export default licenseResetSlice.reducer;
