import { Modal } from "semantic-ui-react";
import { MIcon } from "@macrium/shared-components";
import PropTypes from "prop-types";

const ModalHeaderWithCloseButton = ({ onClose, header }) => {
  return (
    <Modal.Header className="flex items-center justify-between">
      <div>{header}</div>
      <span data-testid="close-modal-button" className="flex flex-center cursor-pointer " onClick={onClose}>
        <MIcon name="close" size="tiny" color="grey" />
      </span>
    </Modal.Header>
  );
};

export default ModalHeaderWithCloseButton;

ModalHeaderWithCloseButton.propTypes = {
  onClose: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
};
