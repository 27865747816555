import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MIcon } from "@macrium/shared-components";
import "./ReflectXBox.styles.scss";
import { SB_WEBSITE_PAGE, X_WEBSITE_PAGE } from "../../../constants/urls";
import ReflectXImage from "../ReflectXImage/ReflectXImage";
import ReflectXUpgradeButton from "../ProductCard/ReflectXUpgradeButton";
import { SBX_NOTIFICATION, X_NOTIFICATION } from "../../../constants/localStorage";
import {
  shouldShowBackupXFromNotifications,
  shouldShowUpgradeButtonFromNotifications,
} from "../../../selectors/xNotifications";

const ReflectXBox = () => {
  const [showNotification, setShowNotification] = useState(false);
  const [showInnerBox, setShowInnerBox] = useState(true);
  const [productIsX, setProductisX] = useState(true);
  const showUpgradeButton = useSelector(shouldShowUpgradeButtonFromNotifications);
  const hasBackupProducts = useSelector(shouldShowBackupXFromNotifications);

  const hasNotDismissedReflectXBox = localStorage.getItem(X_NOTIFICATION) !== "true";
  const hasNotDismissedReflectSBXBox = localStorage.getItem(SBX_NOTIFICATION) !== "true";
  const shouldShowBackupXBox = hasNotDismissedReflectSBXBox && hasBackupProducts;
  useEffect(() => {
    let fadeOutTimeout;
    let swapProductTimeout;

    if (hasNotDismissedReflectXBox || shouldShowBackupXBox) {
      setShowNotification(true);
      setShowInnerBox(true);

      if (hasNotDismissedReflectXBox) {
        if (shouldShowBackupXBox) {
          fadeOutTimeout = setTimeout(() => {
            setShowInnerBox(false);
            swapProductTimeout = setTimeout(() => {
              setProductisX(!productIsX);
              setShowInnerBox(true);
            }, 1000);
          }, 15000);
        } else {
          clearTimeout(fadeOutTimeout);
          clearTimeout(swapProductTimeout);
          setProductisX(true);
        }
        return;
      }

      setShowNotification(true);
      setProductisX(false);
    } else {
      setShowNotification(false);
    }

    return () => {
      clearTimeout(fadeOutTimeout);
      clearTimeout(swapProductTimeout);
    };
  }, [hasNotDismissedReflectXBox, productIsX, shouldShowBackupXBox]);

  if (!showNotification) return null;
  const handleClose = () => {
    localStorage.setItem(productIsX ? X_NOTIFICATION : SBX_NOTIFICATION, "true");
    setProductisX(!productIsX);
    setShowInnerBox(false);
  };
  return (
    <div className="reflect-x-container">
      <div className={showInnerBox ? "reflect-x-innerBox" : "innerBox-invisible"}>
        <div className="flex justify-between flex-center">
          <div className="bold mb1">{productIsX ? "Reflect X is here!" : "SiteBackup X is here!"}</div>
          <div data-testid="dismiss-button" onClick={handleClose} className="cursor-pointer">
            <MIcon name="close" />
          </div>
        </div>
        <div className="mb1 reflect-x-container__text">
          {productIsX
            ? "Explore the all new powerful version of Macrium Reflect."
            : "Explore the all new powerful version of SiteManager Platform by upgrading today."}
        </div>
        <ReflectXImage />
        <div className="reflectX-button-holder">
          <a href={productIsX ? X_WEBSITE_PAGE : SB_WEBSITE_PAGE} target="_blank" rel="noopener noreferrer">
            Learn More
          </a>
          {showUpgradeButton && <ReflectXUpgradeButton text="Upgrade Now" />}
        </div>
      </div>
    </div>
  );
};

export default ReflectXBox;
