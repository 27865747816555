import React from "react";

import HeaderWithContent from "./HeaderWithContent/HeaderWithContent.jsx";
import Video from "../Video.jsx";
import { LICENSES_DESCRIPTIVE_STRING } from "../../../constants/licenses.js";
import { PRODUCTS_VIDEO_URLS } from "../../../constants/licenses-products.js";
import { isLegacy } from "../../../utils/products.js";

const LicenseExtraInfo = ({ data }) => {
  let videoUrl = "";

  // Hide videos for legacy products except v8
  if (!isLegacy(data.version) || Number(data.version) === 8) videoUrl = PRODUCTS_VIDEO_URLS[data.description];

  return (
    <div className="product-card__product__extended-info">
      {videoUrl && (
        <>
          <HeaderWithContent title="Video Tutorial Series:" className="mr3">
            {LICENSES_DESCRIPTIVE_STRING[data.description]}
          </HeaderWithContent>

          <Video src={PRODUCTS_VIDEO_URLS[data.description]} />
        </>
      )}
    </div>
  );
};

export default LicenseExtraInfo;
