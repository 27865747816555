import React from "react";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";

import microsoftIcon from "./micro.svg";
import googleIcon from "./google.svg";

import "./SSOButton.scss";

const microsoft = "microsoft";
const google = "google";

const icons = {
  [microsoft]: microsoftIcon,
  [google]: googleIcon,
};

const SSOButton = ({ type, text, width, fluid, onClick, className, disabled }) => (
  <Button
    data-testid={`sso-button-${type}`}
    secondary
    disabled={disabled}
    type="button"
    className={`flex flex-center justify-center mr0 sso-button ${className}`}
    style={{ width: fluid ? "100%" : width }}
    onClick={() => onClick()}
  >
    <img src={icons[type]} alt="sso-button" className="mr1" width="21px" height="21px" />
    <span className="bold">{text}</span>
  </Button>
);

SSOButton.propTypes = {
  type: PropTypes.oneOf([microsoft, google]).isRequired,
  text: PropTypes.string.isRequired,
  width: PropTypes.string,
  fluid: PropTypes.bool,
  className: PropTypes.string,
};

SSOButton.defaultProps = {
  width: "188px",
  fluid: false,
  className: "",
};

export default SSOButton;
