import React from "react";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";

const RegisterButton = ({ loading, handleSubmit, content, ...props }) => (
  <Button
    content={content}
    primary
    fluid
    size="large"
    type="button"
    onClick={handleSubmit()}
    disabled={loading}
    {...props}
  />
);
export default RegisterButton;
RegisterButton.propTypes = {
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
};

RegisterButton.defaultProps = {
  loading: false,
};
