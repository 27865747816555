import { Button, Form, Input, Modal } from "semantic-ui-react";
import ModalHeaderWithCloseButton from "../../generic/ModalHeaderWithCloseButton";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteAccountPermanantly } from "../../../slices/account/details";
import PropTypes from "prop-types";
import { getAccountDetailsError, isLoadingDetails } from "../../../selectors/basics";

const reasonsToLeave = [
  { value: 1, content: "No Longer Using Macrium Software" },
  { value: 2, content: "Switching to a Different Backup Solution" },
  { value: 3, content: "Difficulty in Managing Licenses or Subscriptions" },
  { value: 4, content: "Technical Issues with the Software" },
  { value: 5, content: "Found Features Too Complicated or Overwhelming" },
  { value: 6, content: "Account Created Accidentally or No Longer Needed" },
  { value: 7, content: "Other (Please Specify)" },
];

const DeleteAccountModal = ({ onClose }) => {
  const [deleteText, setDeleteText] = useState("");
  const [reason, setReason] = useState(1);
  const [otherReason, setOtherReason] = useState("");
  const [deleteFreshdesk, setDeleteFreshdesk] = useState(null);
  const [required, setRequired] = useState(false);
  const dispatch = useDispatch();
  const error = useSelector(getAccountDetailsError);
  const isLoading = useSelector(isLoadingDetails);
  const deleteTextNotAdded = deleteText !== "DELETE";
  const freshDeskNotSelected = deleteFreshdesk === null;
  const deleteUserAccount = () => {
    if (freshDeskNotSelected) {
      return setRequired(true);
    }
    const chosenReason = () => {
      if (reason === 7) {
        return otherReason || "Other (Please Specify)";
      }
      return reasonsToLeave.find((r) => r.value === reason).content;
    };
    dispatch(deleteAccountPermanantly({ reason: chosenReason(), deleteFreshdesk: deleteFreshdesk }));
  };
  return (
    <Modal open={true} onClose={onClose} size="small">
      <ModalHeaderWithCloseButton onClose={onClose} header="Delete Account" />
      <Modal.Content>
        <div>
          <p>
            Are you sure you want to delete your account? Deleting your account will remove your access to manage and
            view your licenses, as well as access to download Macrium software. However, any licenses you've purchased
            will remain active and can still be used.
          </p>
          <p className="bold">Before you proceed, please provide a reason for deleting your account:</p>
          <select
            style={{
              minHeight: "38px",
            }}
            placeholder="Select a reason"
            onChange={(e) => {
              setReason(Number(e.target.value));
              setOtherReason("");
            }}
          >
            {reasonsToLeave.map((reason) => (
              <option className="flex justify-between regular flex-center" key={reason.value} value={reason.value}>
                {reason.content}
              </option>
            ))}
          </select>
          <Input
            maxLength={100}
            style={{ minWidth: "360px", marginTop: "12px" }}
            value={otherReason}
            disabled={reason !== 7}
            placeholder="Please specify..."
            onChange={(e) => setOtherReason(e.target.value)}
          />
          <div className="mt2">
            <p className="bold">Delete your associated Macrium Support Portal Account as well?</p>
            <Form>
              <Form.Group>
                <Form.Radio
                  label="Yes"
                  value={"true"}
                  checked={deleteFreshdesk}
                  onChange={() => setDeleteFreshdesk(true)}
                />
                <Form.Radio
                  label="No"
                  value={"false"}
                  checked={deleteFreshdesk === false}
                  onChange={() => setDeleteFreshdesk(false)}
                />
                {required && freshDeskNotSelected && <div className="red">Required</div>}
              </Form.Group>
            </Form>
          </div>
          <p className="mt2">
            To confirm, type the word <b>DELETE</b> (case-sensitive) in the box below.
          </p>
          <Input
            style={{ minWidth: "360px" }}
            value={deleteText}
            onChange={(e) => setDeleteText(e.target.value.trim())}
          />
          <p className="bold mt2 ">
            Once your account is deleted, you'll be logged out and won't be able to log back in.
          </p>
          {error && <p className="mt2 red ">An error occurred. Please try again.</p>}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary size="medium" content="Cancel" onClick={onClose} disabled={isLoading} />
        <Button
          color="red"
          type="button"
          onClick={() => deleteUserAccount()}
          content="Delete Account"
          loading={isLoading}
          disabled={isLoading || deleteTextNotAdded}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default DeleteAccountModal;

DeleteAccountModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};
