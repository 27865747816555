import { getAccountEmail } from "../../../selectors/basics";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getCurrentProducts, getFirstValidOrderID } from "../../../selectors/licenses";
import { Button } from "semantic-ui-react";
import "./UpgradeButton.styles.scss";
import AncillaryButton from "../AncillaryButton";
import getUpgradeLink from "../../../utils/getUpgradeLink";

const UpgradeButton = ({ pageHeader }) => {
  const userEmail = useSelector(getAccountEmail);
  const products = useSelector(getCurrentProducts);
  const orderId = useSelector(getFirstValidOrderID);
  if (!userEmail || isEmpty(products) || !orderId) return null;

  const upgradeLink = getUpgradeLink(userEmail, orderId);
  if (pageHeader) {
    return (
      <a href={upgradeLink} target="_blank" className="ml2" rel="noreferrer">
        <AncillaryButton icon="upgrade">
          <div className="center">
            <div>Upgrades &</div>
            <div>Renewals</div>
          </div>
        </AncillaryButton>
      </a>
    );
  }
  return <Button primary as="a" content="Upgrade" href={upgradeLink} target="_blank" rel="noreferrer" />;
};
UpgradeButton.propTypes = {
  pageHeader: PropTypes.bool,
};

UpgradeButton.defaultProps = {
  pageHeader: false,
};

export default UpgradeButton;
