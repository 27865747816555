import { Popup } from "semantic-ui-react";
import { MIcon } from "@macrium/shared-components";
import PropTypes from "prop-types";

const TableHeader = ({ headers, billingTable }) => {
  return (
    <div className={billingTable ? "billing_table_row billing-table-header" : "table-row table-header"}>
      {headers.map((header) => (
        <div key={header} className="table_header_cell flex flex-center">
          {header}
          {header === "Qty" && (
            <Popup
              className="fix-popup-alignment "
              trigger={
                <div data-testid="popup-trigger">
                  <MIcon size="largeSmall" name="info circle" className="mlhalf  mt1 mb0" />
                </div>
              }
              hideOnScroll
              content={"Seats/Endpoints/Deployments"}
              wide
              context={null}
              position="bottom right"
            />
          )}
        </div>
      ))}
      <div className="pl2">Status</div>
      <div />
    </div>
  );
};
export default TableHeader;
TableHeader.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  billingTable: PropTypes.bool,
};
TableHeader.defaultProps = {
  billingTable: false,
};
