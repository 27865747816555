export const REFLECT_HOME = "Reflect Home";
export const REFLECT_WORKSTATION = "Reflect Workstation";
export const REFLECT_SERVER = "Reflect Server";
export const SERVER_PLUS_VM = "Server Plus VM Bundle";
export const SERVER_PLUS = "Reflect Server Plus";
export const DEPLOYMENT_KIT = "Deployment Kit";
export const TECHNICIAN = "Technicians";
export const SITEDEPLOY = "SiteDeploy";
export const SITE_MANAGER = "Site Manager";
export const REFLECT_FREE = "Reflect Free";
export const SITE_BACKUP = "SiteBackup";
export const VM_PACK = "VM Pack";

export const WORKSTATION_LTSC_2024 = "Reflect WorkstationLTSC2024";
export const SERVER_LTSC_2024 = "Reflect ServerLTSC2024";

export const SITE_MANAGER_SUBSCRIPTION = "Site Manager Subscription";
export const SITE_BACKUP_SUBSCRIPTION = "SiteBackup Subscription";
export const REFLECT_HOME_SUBSCRIPTION = "Reflect Home Subscription";
export const REFLECT_WORKSTATION_SUBSCRIPTION = "Reflect Workstation Subscription";
export const REFLECT_SERVER_SUBSCRIPTION = "Reflect Server Subscription";
export const SERVER_PLUS_SUBSCRIPTION = "Reflect Server Plus Subscription";
export const TECHNICIAN_SUBSCRIPTION = "Technicians Subscription";
export const DEPLOYMENT_KIT_SUBSCRIPTION = "Deployment Kit Subscription";

export const ALL_PRODUCTS = [
  REFLECT_HOME_SUBSCRIPTION,
  REFLECT_WORKSTATION_SUBSCRIPTION,
  REFLECT_SERVER_SUBSCRIPTION,
  SERVER_PLUS_SUBSCRIPTION,
  SITE_MANAGER_SUBSCRIPTION,
  TECHNICIAN_SUBSCRIPTION,
  DEPLOYMENT_KIT_SUBSCRIPTION,
  SITE_BACKUP_SUBSCRIPTION,

  REFLECT_HOME,
  SITE_MANAGER,
  SITE_BACKUP,
  REFLECT_WORKSTATION,
  REFLECT_SERVER,
  SERVER_PLUS,
  SERVER_PLUS_VM,
  SITEDEPLOY,
  TECHNICIAN,
  DEPLOYMENT_KIT,

  WORKSTATION_LTSC_2024,
  SERVER_LTSC_2024,
];

export const LICENSES_DESCRIPTIVE_STRING = {
  [REFLECT_HOME]: REFLECT_HOME,
  [REFLECT_WORKSTATION]: REFLECT_WORKSTATION,
  [REFLECT_SERVER]: REFLECT_SERVER,
  [SERVER_PLUS_VM]: SERVER_PLUS_VM,
  [SERVER_PLUS]: SERVER_PLUS,
  [DEPLOYMENT_KIT]: DEPLOYMENT_KIT,
  [TECHNICIAN]: "Technician's License",
  [DEPLOYMENT_KIT_SUBSCRIPTION]: DEPLOYMENT_KIT,
  [TECHNICIAN_SUBSCRIPTION]: "Technician's License",
  [SITEDEPLOY]: SITEDEPLOY,
  [SITE_MANAGER]: SITE_MANAGER,
  [SITE_BACKUP]: SITE_BACKUP,
  [SITE_MANAGER_SUBSCRIPTION]: SITE_MANAGER,
  [SITE_BACKUP_SUBSCRIPTION]: SITE_BACKUP,
  [REFLECT_HOME_SUBSCRIPTION]: REFLECT_HOME,
  [REFLECT_WORKSTATION_SUBSCRIPTION]: REFLECT_WORKSTATION,
  [REFLECT_SERVER_SUBSCRIPTION]: REFLECT_SERVER,
  [SERVER_PLUS_SUBSCRIPTION]: SERVER_PLUS,
  [REFLECT_FREE]: REFLECT_FREE,

  [WORKSTATION_LTSC_2024]: REFLECT_WORKSTATION,
  [SERVER_LTSC_2024]: REFLECT_SERVER,
};

// some perpetual licenses have subscriptions
// some subscription licenses are listed as perpetual with a version of 10
// some subscription licences are actual perpetual

// this object is for some hacky workarounds of the server data
export const LICENSES_WITH_SUBSCRIPTIONS = {
  [REFLECT_HOME]: REFLECT_HOME_SUBSCRIPTION,
  [REFLECT_WORKSTATION]: REFLECT_WORKSTATION_SUBSCRIPTION,
  [REFLECT_SERVER]: REFLECT_SERVER_SUBSCRIPTION,
  [SERVER_PLUS]: SERVER_PLUS_SUBSCRIPTION,
  [DEPLOYMENT_KIT]: DEPLOYMENT_KIT_SUBSCRIPTION,
  [TECHNICIAN]: TECHNICIAN_SUBSCRIPTION,
  [SITE_MANAGER]: SITE_MANAGER_SUBSCRIPTION,
  [SITE_BACKUP]: SITE_BACKUP_SUBSCRIPTION,
};

export const HOME_LICENSES = {
  [REFLECT_HOME]: REFLECT_HOME,
  [REFLECT_HOME_SUBSCRIPTION]: REFLECT_HOME_SUBSCRIPTION,
};

export const VERSIONLESS_LICENSES = {
  [REFLECT_HOME_SUBSCRIPTION]: REFLECT_HOME_SUBSCRIPTION,
  [REFLECT_WORKSTATION_SUBSCRIPTION]: REFLECT_WORKSTATION_SUBSCRIPTION,
  [REFLECT_SERVER_SUBSCRIPTION]: REFLECT_SERVER_SUBSCRIPTION,
  [SERVER_PLUS_SUBSCRIPTION]: SERVER_PLUS_SUBSCRIPTION,
  [SITEDEPLOY]: SITEDEPLOY,
  [SITE_BACKUP]: SITE_BACKUP,
  [DEPLOYMENT_KIT_SUBSCRIPTION]: DEPLOYMENT_KIT_SUBSCRIPTION,
  [TECHNICIAN_SUBSCRIPTION]: TECHNICIAN_SUBSCRIPTION,
  [SITE_MANAGER_SUBSCRIPTION]: SITE_MANAGER_SUBSCRIPTION,
};

// sm exemption workstation, server || server plus as long as it is the same
// Only 4 reflect home licences (unless Nick likes you) site manager

export const SERVER = "Server";
export const WORKSTATION = "Workstation";

export const LICENSES_ICON = {
  [REFLECT_HOME]: {
    name: "home",
  },
  [REFLECT_WORKSTATION]: {
    name: "desktop",
  },
  [REFLECT_SERVER]: {
    name: "desktop",
  },
  [SERVER_PLUS_VM]: {
    name: "desktop",
  },
  [SERVER_PLUS]: {
    name: "desktop",
  },
  [DEPLOYMENT_KIT]: {
    name: "toolbox",
  },
  [TECHNICIAN]: {
    name: "toolbox",
  },
  [SITEDEPLOY]: {
    name: "deploy icon",
  },
  [SITE_MANAGER]: {
    name: "sitemap",
  },
  [SITE_MANAGER_SUBSCRIPTION]: {
    name: "sitemap",
  },
  [SITE_BACKUP]: {
    name: "sitemap",
  },
  [SITE_BACKUP_SUBSCRIPTION]: {
    name: "sitemap",
  },
  [REFLECT_HOME_SUBSCRIPTION]: {
    name: "home",
  },
  [REFLECT_WORKSTATION_SUBSCRIPTION]: {
    name: "desktop",
  },
  [REFLECT_SERVER_SUBSCRIPTION]: {
    name: "desktop",
  },
  [SERVER_PLUS_SUBSCRIPTION]: {
    name: "desktop",
  },
  [DEPLOYMENT_KIT_SUBSCRIPTION]: {
    name: "toolbox",
  },
  [TECHNICIAN_SUBSCRIPTION]: {
    name: "toolbox",
  },
  [WORKSTATION_LTSC_2024]: {
    name: "desktop",
  },
  [SERVER_LTSC_2024]: {
    name: "desktop",
  },
};

const HOME_SUPPORT = "H";
const STANDARD_SUPPORT = "S";
const PREMIUM_SUPPORT = "P";
const EXPIRED_SUPPORT = "E";

export const HOME_SUPPORT_TEXT = "Home Support";
export const BUSINESS_SUPPORT_TEXT = "Business Support";
export const EXPIRED_SUPPORT_TEXT = "Expired Support";

export const SUPPORT_TYPES = {
  [HOME_SUPPORT]: HOME_SUPPORT_TEXT,
  [STANDARD_SUPPORT]: BUSINESS_SUPPORT_TEXT,
  [PREMIUM_SUPPORT]: BUSINESS_SUPPORT_TEXT,
  [EXPIRED_SUPPORT]: EXPIRED_SUPPORT_TEXT,
};

export const REFUNDED = "X";
export const UPGRADED = "U";
// Y = fulfilled (unused)
export const REFUNDED_TEXT = "Refunded";
export const UPGRADED_TEXT = "Upgraded";

export const ORDER_STATUS_TEXT = {
  [REFUNDED]: REFUNDED_TEXT,
  [UPGRADED]: UPGRADED_TEXT,
};

export const KEY_TO_VERSION = {
  2: 6,
  3: 6,
  4: 7,
  5: 7,
  6: 8,
  7: 8,
  B: 10,
};

export const BLUE_LABEL = "cLogoNavy bgPaleBlue borderPaleBlue";
export const ORANGE_LABEL = "cDarkOrange borderLightRed bgLightRed";
export const GREY_LABEL = "borderGrey8 bgGrey8 cGrey1";
export const YELLOW_LABEL = "borderPaleAmber bgPaleAmber cAmber";
export const GREEN_LABEL = "borderPaleGreen bgPaleGreen cPositiveGreen";
export const RED_LABEL = "borderLightRed bgLightRed cRed";

export const BLUE_PILL = "cLogoNavy bgWhite borderLogoNavy";
export const GREY_PILL = "borderGrey4 bgWhite cGrey4";

export const OFFLINE_ACTIVATED_EXPIRED = "Offline Activated";
export const OFFLINE_ACTIVATED_ACTIVE = "Offline Activated Active";
export const OFFLINE_ACTIVATED_WARNING = "Offline Activated Warning";
export const FRIENDLYNAME = "friendlyName";
export const RENEWED_ON = "Renewed on:";
export const RENEWAL = "Renewal:";
export const NO_SUPPORT = "No Support";
export const OFFLINE_ACTIVATED = "Offline Activated";
export const AVAILABLE = "Available";
export const ACTIVE = "Active";
export const EXPIRED = "Expired";
export const VIRTUAL = "Virtual";
export const VIRTUAL_SERVER = "Virtual Server";
export const CORE = "Core";
export const SYSTEM_BUILDERS = "System Builders";
export const STATUS_LABEL_COLOR = {
  [ACTIVE]: BLUE_LABEL,
  Refunded: GREY_LABEL,
  V8: BLUE_LABEL,
  V7: BLUE_LABEL,
  V6: BLUE_LABEL,
  V5: BLUE_LABEL,
  "Version: Unknown": YELLOW_LABEL,
  [OFFLINE_ACTIVATED_EXPIRED]: ORANGE_LABEL,
  [OFFLINE_ACTIVATED_WARNING]: YELLOW_LABEL,
  [OFFLINE_ACTIVATED_ACTIVE]: BLUE_LABEL,
  [AVAILABLE]: GREEN_LABEL,
  Unused: GREEN_LABEL,
  "Upgrade Key": BLUE_LABEL,
  Upgraded: GREEN_LABEL,
  [EXPIRED]: RED_LABEL,
  [EXPIRED_SUPPORT_TEXT]: RED_LABEL,
  Retired: GREY_LABEL,
  [WORKSTATION]: GREY_LABEL,
  [SERVER]: GREY_LABEL,
  [VIRTUAL_SERVER]: GREY_LABEL,
  [CORE]: GREY_LABEL,
  [VM_PACK]: GREY_LABEL,
  [SYSTEM_BUILDERS]: GREY_LABEL,
};
// tag constants
export const KEY_TYPES = {
  [WORKSTATION]: WORKSTATION,
  [SERVER]: SERVER,
  [VIRTUAL]: VIRTUAL_SERVER,
  [CORE]: CORE,
  [SYSTEM_BUILDERS]: SYSTEM_BUILDERS,
};
export const QTY = "QTY";
export const UPGRADE_KEY = "Upgrade key";
export const INSTALLED = "Installed";
export const LTSC_MAINTENANCE = "LTSC Term";

export const isLTSCMaintenanceTag = (id) => id.startsWith(LTSC_MAINTENANCE);

export const EXPIRED_REFLECT_MESSAGE =
  "Reflect has automatically fallen back to restore-only mode, meaning you can still access your data for as long as you need. Please renew to remain protected. Contact support or visit our renewal wizard for more information.";
export const EXPIRED_DK_TECH_MESSAGE =
  "This license key can no longer be used to create technician's portable application.";
export const EXPIRED_SM_SD_MESSAGE =
  "This license has expired, agents using this license will be disconnected - Please renew to remain protected. Contact support or visit our renewal wizard for more information.";
