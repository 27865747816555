import { MIcon } from "@macrium/shared-components";
import { Loader } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { fetchInvoiceAsync } from "../../../slices/billing/billing";
import { useState } from "react";
import gtmPushBillingRequest from "../../../utils/gtm/billing";

const InvoiceButton = ({ transId, hideText, isRefund }) => {
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const dispatch = useDispatch();
  const getInvoicePDF = async (transId) => {
    setInvoiceLoading(true);
    const res = await dispatch(fetchInvoiceAsync(transId));
    const pdfUrl = res?.payload?.data?.invoiceUrl;
    if (!pdfUrl) return setInvoiceLoading(false);
    gtmPushBillingRequest("invoice", "billing");
    window.open(pdfUrl, "_blank");
    setInvoiceLoading(false);
  };
  return (
    <div style={{ width: "auto " }} className={`flex ${hideText ? "items-center" : "flex-start"}`}>
      {invoiceLoading ? (
        <Loader className="ui active inline loader small gray" />
      ) : (
        <div as="button" className="download_invoice_button" onClick={() => getInvoicePDF(transId)}>
          {!hideText && <span style={{ textAlign: "center" }}>Download {isRefund ? "Credit Note" : "Invoice"}</span>}
          <MIcon name="download" size="normalLarge" color="cBlue" />
        </div>
      )}
    </div>
  );
};

export default InvoiceButton;

InvoiceButton.propTypes = {
  transId: PropTypes.string.isRequired,
  hideText: PropTypes.bool,
  isRefund: PropTypes.bool,
};
InvoiceButton.defaultProps = {
  hideText: false,
  isRefund: false,
};
