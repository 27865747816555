import { createSelector } from "reselect";
import { find, every, isEmpty } from "lodash";

import { getCurrentProducts } from "./licenses";
import { isLegacy } from "../utils/products";

export const shouldShowUpgradeButtonFromNotifications = createSelector([getCurrentProducts], (currentProducts) => {
  if (isEmpty(currentProducts)) return false;

  return !!find(currentProducts, (prod) => isLegacy(prod.version) && !prod.ltscVersion && prod.isTrial !== "1");
});
export const shouldShowBackupXFromNotifications = createSelector([getCurrentProducts], (currentProducts) => {
  if (isEmpty(currentProducts)) return false;
  return !!find(currentProducts, (prod) => prod.edition === 11 || prod.edition === 1);
});
export const areAllProductsSubs = createSelector([getCurrentProducts], (currentProducts) => {
  if (isEmpty(currentProducts)) return false;

  return !!every(currentProducts, (prod) => prod.version === "10" && !prod.ltscVersion && prod.isTrial !== "1");
});
