import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { MIcon } from "@macrium/shared-components";
import "./ContextMenu.styles.scss";

const ContextMenu = ({ trigger, menuContents, includeChevron, alignRight }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuDropdown = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuDropdown.current && !menuDropdown.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="relative " ref={menuDropdown}>
      <div className="flex justify-start flex-center " onClick={() => setMenuOpen(!menuOpen)}>
        {trigger}
        {includeChevron && (
          <MIcon
            name="chevron down"
            color="grey"
            className={`triggerIcon ${menuOpen ? "triggerIconOpen" : ""}`}
            size="tiny"
          />
        )}
      </div>
      <div
        aria-expanded={menuOpen}
        data-testid="menu-dropdown"
        className={`absolute contextMenuContainer ${alignRight ? "alignRight" : "alignLeft"} ${
          menuOpen ? "contextMenuOpen" : "contextMenuClosed"
        }`}
      >
        <div className="optionContainer">
          {menuContents.map((option) => (
            <div
              key={option.id}
              aria-hidden={!menuOpen}
              className={option.className}
              value={option.id}
              onClick={() => {
                option.onClick();
                setMenuOpen(false);
              }}
            >
              {option.content}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContextMenu;

ContextMenu.props = {
  trigger: PropTypes.node,
  menuContents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      content: PropTypes.node,
      onClick: PropTypes.func,
      className: PropTypes.string,
    })
  ),
  includeChevron: PropTypes.bool,
  alignRight: PropTypes.bool,
};
ContextMenu.defaultProps = {
  includeChevron: false,
  alignRight: false,
};
