const ReflectXLogo = () => (
  <svg height="16" viewBox="0 0 367 78" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M338.059 36.7484C338.328 37.1094 338.747 37.32 339.187 37.32H310.783C310.384 37.32 309.995 37.1295 309.755 36.7985L283.179 1.44426C282.74 0.852514 283.159 0 283.898 0H310.174C310.624 0 311.043 0.210621 311.303 0.571686L338.049 36.7584L338.059 36.7484Z"
      fill="#00ADEE"
    />
    <path
      d="M311.942 36.7484C311.672 37.1094 311.253 37.32 310.813 37.32H339.217C339.616 37.32 340.005 37.1295 340.245 36.7985L366.821 1.44426C367.261 0.852514 366.841 0 366.102 0H339.826C339.376 0 338.957 0.210621 338.698 0.571686L311.952 36.7584L311.942 36.7484Z"
      fill="#003B71"
    />
    <path
      d="M338.059 41.2416C338.328 40.8805 338.747 40.6699 339.187 40.6699H310.783C310.384 40.6699 309.995 40.8605 309.755 41.1915L283.179 76.5557C282.74 77.1475 283.159 78 283.898 78H310.174C310.624 78 311.043 77.7894 311.303 77.4283L338.049 41.2416H338.059Z"
      fill="#00ADEE"
    />
    <path
      d="M311.942 41.2416C311.672 40.8805 311.253 40.6699 310.813 40.6699H339.217C339.616 40.6699 340.005 40.8605 340.245 41.1915L366.821 76.5557C367.261 77.1475 366.841 78 366.102 78H339.826C339.376 78 338.957 77.7894 338.698 77.4283L311.952 41.2416H311.942Z"
      fill="#003B71"
    />
    <path
      d="M12.3751 51.3087V77.2611H0V10.8374H20.2984C24.8403 10.8374 28.7319 11.3067 31.9632 12.2354C35.2046 13.174 37.8557 14.4821 39.9265 16.1597C41.9974 17.8472 43.518 19.8543 44.4784 22.2009C45.4488 24.5475 45.929 27.1238 45.929 29.9397C45.929 32.1765 45.5989 34.2834 44.9386 36.2805C44.2783 38.2676 43.3279 40.075 42.0874 41.7027C40.8469 43.3303 39.3063 44.7483 37.4855 45.9765C35.6547 47.2047 33.5839 48.1833 31.2529 48.9122C32.8136 49.8009 34.1641 51.0691 35.3046 52.7267L51.9615 77.2511H40.8269C39.7564 77.2511 38.8361 77.0414 38.0858 76.6121C37.3354 76.1827 36.6952 75.5736 36.175 74.7747L22.1892 53.5056C21.669 52.7067 21.0887 52.1475 20.4685 51.808C19.8382 51.4685 18.9078 51.2988 17.6873 51.2988H12.3951L12.3751 51.3087ZM12.3751 42.4416H20.1083C22.4393 42.4416 24.4701 42.152 26.2008 41.5729C27.9316 40.9937 29.3621 40.1849 30.4826 39.1564C31.6031 38.1278 32.4434 36.9096 32.9936 35.5017C33.5439 34.0937 33.824 32.5459 33.824 30.8584C33.824 27.4933 32.7135 24.907 30.4926 23.0996C28.2717 21.2923 24.8703 20.3936 20.2984 20.3936H12.3851V42.4416H12.3751Z"
      fill="#00ADEE"
    />
    <path
      d="M71.3793 29.4004C74.3506 29.4004 77.0917 29.8797 79.5927 30.8283C82.0938 31.7769 84.2447 33.1649 86.0554 34.9823C87.8662 36.7997 89.2767 39.0364 90.2872 41.6926C91.2976 44.3387 91.8078 47.3644 91.8078 50.7594C91.8078 51.6182 91.7678 52.3272 91.6877 52.8963C91.6077 53.4655 91.4777 53.9049 91.2776 54.2244C91.0775 54.544 90.8074 54.7736 90.4772 54.9134C90.1371 55.0532 89.7069 55.1231 89.1867 55.1231H60.0146C60.3548 59.9661 61.6553 63.511 63.9263 65.7777C66.1972 68.0444 69.1984 69.1728 72.95 69.1728C74.7908 69.1728 76.3814 68.9631 77.712 68.5337C79.0425 68.1043 80.213 67.635 81.2134 67.1057C82.2138 66.5865 83.0842 66.1072 83.8345 65.6778C84.5848 65.2484 85.3151 65.0387 86.0254 65.0387C86.4856 65.0387 86.8858 65.1286 87.2259 65.3183C87.566 65.5081 87.8562 65.7577 88.0963 66.0972L91.4076 70.2312C90.1471 71.6991 88.7365 72.9373 87.1759 73.9259C85.6152 74.9244 83.9745 75.7133 82.2738 76.3124C80.5731 76.9115 78.8324 77.3309 77.0717 77.5706C75.311 77.8202 73.6003 77.94 71.9396 77.94C68.6582 77.94 65.6069 77.4008 62.7858 76.3124C59.9646 75.224 57.5136 73.6163 55.4227 71.4894C53.3419 69.3625 51.6912 66.7263 50.5007 63.5908C49.3002 60.4554 48.71 56.8107 48.71 52.6866C48.71 49.4713 49.2302 46.4457 50.2706 43.6198C51.311 40.7839 52.8117 38.3175 54.7525 36.2205C56.7033 34.1235 59.0742 32.4659 61.8854 31.2377C64.6966 30.0095 67.8579 29.4004 71.3893 29.4004H71.3793ZM71.6094 37.5286C68.2981 37.5286 65.707 38.4672 63.8362 40.3345C61.9654 42.2018 60.7649 44.848 60.2447 48.283H81.5936C81.5936 46.8152 81.3935 45.4272 80.9933 44.129C80.5931 42.8309 79.9829 41.6826 79.1526 40.704C78.3222 39.7254 77.2818 38.9565 76.0213 38.3874C74.7607 37.8182 73.2901 37.5386 71.5994 37.5386L71.6094 37.5286Z"
      fill="#00ADEE"
    />
    <path
      d="M98.4807 77.261V38.677L94.389 38.0379C93.4986 37.8881 92.7883 37.5785 92.2481 37.1192C91.7079 36.6599 91.4478 36.0208 91.4478 35.192V30.5487H98.4907V27.0538C98.4907 24.3577 98.9008 21.9412 99.7112 19.7943C100.522 17.6474 101.692 15.8301 103.213 14.3322C104.733 12.8344 106.584 11.6861 108.755 10.8872C110.936 10.0884 113.387 9.68896 116.118 9.68896C118.299 9.68896 120.32 9.97855 122.19 10.5577L121.96 16.2495C121.9 17.1382 121.48 17.6874 120.72 17.9071C119.95 18.1168 119.059 18.2266 118.049 18.2266C116.698 18.2266 115.498 18.3764 114.437 18.666C113.377 18.9555 112.476 19.4648 111.746 20.1838C111.006 20.9027 110.446 21.8513 110.065 23.0296C109.685 24.2079 109.485 25.6758 109.485 27.4133V30.5387H121.77V38.627H109.855V77.261H98.4907H98.4807Z"
      fill="#00ADEE"
    />
    <path d="M139.098 9V77.2611H127.733V9H139.098Z" fill="#00ADEE" />
    <path
      d="M168.149 29.4004C171.121 29.4004 173.862 29.8797 176.363 30.8283C178.864 31.7769 181.015 33.1649 182.825 34.9823C184.636 36.7997 186.047 39.0364 187.057 41.6926C188.068 44.3387 188.578 47.3644 188.578 50.7594C188.578 51.6182 188.538 52.3272 188.458 52.8963C188.378 53.4655 188.248 53.9049 188.048 54.2244C187.847 54.544 187.577 54.7736 187.237 54.9134C186.897 55.0532 186.467 55.1231 185.947 55.1231H156.775C157.115 59.9661 158.415 63.511 160.686 65.7777C162.957 68.0444 165.958 69.1728 169.71 69.1728C171.551 69.1728 173.141 68.9631 174.472 68.5337C175.803 68.1043 176.973 67.635 177.973 67.1057C178.974 66.5865 179.844 66.1072 180.594 65.6778C181.345 65.2484 182.075 65.0387 182.775 65.0387C183.236 65.0387 183.636 65.1286 183.976 65.3183C184.316 65.4981 184.606 65.7577 184.846 66.0972L188.158 70.2312C186.897 71.6991 185.487 72.9373 183.926 73.9259C182.365 74.9244 180.725 75.7133 179.024 76.3124C177.323 76.9115 175.592 77.3309 173.822 77.5706C172.061 77.8202 170.35 77.94 168.69 77.94C165.408 77.94 162.357 77.4008 159.536 76.3124C156.715 75.224 154.254 73.6163 152.173 71.4894C150.082 69.3625 148.441 66.7263 147.251 63.5908C146.05 60.4554 145.46 56.8107 145.46 52.6866C145.46 49.4713 145.98 46.4457 147.021 43.6198C148.061 40.7839 149.562 38.3175 151.512 36.2205C153.463 34.1235 155.834 32.4659 158.645 31.2377C161.457 30.0095 164.618 29.4004 168.149 29.4004ZM168.379 37.5286C165.068 37.5286 162.477 38.4672 160.606 40.3345C158.735 42.2018 157.535 44.848 157.015 48.283H178.364C178.364 46.8152 178.163 45.4272 177.763 44.129C177.363 42.8309 176.753 41.6826 175.923 40.704C175.092 39.7254 174.052 38.9565 172.791 38.3874C171.531 37.8182 170.06 37.5386 168.369 37.5386L168.379 37.5286Z"
      fill="#00ADEE"
    />
    <path
      d="M228.704 40.0949C228.364 40.5242 228.034 40.8638 227.714 41.1034C227.394 41.353 226.924 41.4729 226.313 41.4729C225.703 41.4729 225.163 41.2931 224.613 40.9436C224.062 40.5941 223.402 40.1947 222.632 39.7454C221.862 39.306 220.951 38.9066 219.891 38.5471C218.83 38.1976 217.52 38.0179 215.959 38.0179C213.968 38.0179 212.218 38.3774 210.717 39.0963C209.216 39.8153 207.966 40.8538 206.965 42.1918C205.965 43.5399 205.225 45.1675 204.734 47.0847C204.244 49.002 203.994 51.1688 203.994 53.5853C203.994 56.0018 204.264 58.3284 204.795 60.2956C205.335 62.2528 206.105 63.9004 207.115 65.2384C208.126 66.5665 209.356 67.585 210.797 68.274C212.238 68.963 213.868 69.3125 215.679 69.3125C217.49 69.3125 218.95 69.0928 220.071 68.6435C221.191 68.2041 222.132 67.7049 222.902 67.1756C223.672 66.6364 224.333 66.1471 224.903 65.7077C225.473 65.2684 226.103 65.0387 226.814 65.0387C227.734 65.0387 228.424 65.3882 228.884 66.0972L232.156 70.2312C230.895 71.6991 229.535 72.9373 228.064 73.9258C226.594 74.9244 225.063 75.7132 223.482 76.3124C221.902 76.9115 220.271 77.3309 218.58 77.5705C216.89 77.8202 215.219 77.94 213.568 77.94C210.657 77.94 207.906 77.4008 205.335 76.3124C202.754 75.224 200.513 73.6363 198.592 71.5593C196.671 69.4823 195.161 66.926 194.04 63.9104C192.92 60.8947 192.359 57.4597 192.359 53.5953C192.359 50.1303 192.86 46.925 193.86 43.9693C194.86 41.0135 196.321 38.4572 198.252 36.3004C200.183 34.1435 202.574 32.446 205.435 31.2277C208.286 29.9995 211.567 29.3904 215.279 29.3904C218.99 29.3904 221.902 29.9596 224.553 31.0879C227.204 32.2163 229.595 33.8439 231.706 35.9609L228.714 40.0949H228.704Z"
      fill="#00ADEE"
    />
    <path
      d="M253.635 77.9899C249.553 77.9899 246.412 76.8316 244.201 74.5249C241.99 72.2183 240.89 69.0229 240.89 64.9488V38.627H236.108C235.497 38.627 234.967 38.4273 234.517 38.0278C234.067 37.6284 233.847 37.0293 233.847 36.2404V31.737L241.44 30.4987L243.831 17.6374C243.951 17.0283 244.241 16.549 244.681 16.2095C245.121 15.8699 245.682 15.7002 246.362 15.7002H252.254V30.5387H264.679V38.627H252.254V64.1699C252.254 65.6378 252.624 66.7862 253.355 67.615C254.095 68.4438 255.075 68.8532 256.296 68.8532C257.006 68.8532 257.586 68.7733 258.067 68.6035C258.547 68.4338 258.957 68.254 259.307 68.0743C259.657 67.8946 259.977 67.7148 260.248 67.5451C260.528 67.3753 260.798 67.2954 261.078 67.2954C261.418 67.2954 261.688 67.3753 261.908 67.5451C262.118 67.7148 262.348 67.9644 262.599 68.304L266 73.816C264.339 75.194 262.439 76.2325 260.298 76.9414C258.147 77.6504 255.926 77.9999 253.625 77.9999L253.635 77.9899Z"
      fill="#00ADEE"
    />
  </svg>
);
export default ReflectXLogo;
