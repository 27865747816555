const gtmPushBillingRequest = (downloadType, subOrBilling) => {
  if (window?.dataLayer) {
    return window.dataLayer.push({
      event: `downloaded ${downloadType} - ${subOrBilling}`,
      action: "billing_request",
    });
  }
  return;
};
export default gtmPushBillingRequest;
