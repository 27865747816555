import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Modal } from "semantic-ui-react";
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import ModalHeaderWithCloseButton from "../ModalHeaderWithCloseButton";
import { getSelectedFriendlyName, isSavingFriendlyName } from "../../../selectors/basics";
import {
  setSelectedFriendlyName,
  updatedLicenseFriendlyName,
  deleteLicenseFriendlyName,
} from "../../../slices/products/friendlyNames";

const FriendlyNameModal = () => {
  const dispatch = useDispatch();
  const selectedLicense = useSelector(getSelectedFriendlyName);
  const isUpdating = useSelector(isSavingFriendlyName);
  const [friendlyName, setFriendlyName] = useState("");

  useEffect(() => {
    // Set friendly name when the store changes.
    // This makes sure that the friendly name is cleared/populated appropriately when resetting friendly names in sequence
    setFriendlyName(selectedLicense.friendlyName || "");
  }, [selectedLicense]);

  const { licenseId, isSiteManagerPlatform } = selectedLicense;
  const updateFriendlyName = () => {
    dispatch(
      updatedLicenseFriendlyName({
        friendlyNameLookup: licenseId,
        friendlyNameValue: friendlyName.trim(),
      })
    );
  };
  const deleteFriendlyName = () => {
    dispatch(
      deleteLicenseFriendlyName({
        friendlyNameLookup: licenseId,
      })
    );
  };
  const onClose = () => dispatch(setSelectedFriendlyName({}));
  const hasExistingFriendlyName = !!selectedLicense.friendlyName;

  return (
    <Modal open={!isEmpty(selectedLicense)} onClose={onClose} size={hasExistingFriendlyName ? "mini" : "small"}>
      <ModalHeaderWithCloseButton
        onClose={onClose}
        header={hasExistingFriendlyName ? "Edit Friendly Name" : "License Key Friendly Name"}
      />

      <Modal.Content>
        {!hasExistingFriendlyName && (
          <>
            <p>
              Assign a friendly name to your license key to help you organize and easily identify it. You can use this
              name to label the license key by business department, device, or any other system that works for you. For
              example, you might use "Finance Workstation" or "Family Desktop."
            </p>
            <p>
              <b>
                This friendly name is for your reference only and won&apos;t affect the license or its functionality.
              </b>
            </p>
            {isSiteManagerPlatform ? (
              <p>
                You can check which license key is currently in use on a specific device by going to the{" "}
                <b>'Licenses page'</b> on your SiteManager instance.
              </p>
            ) : (
              <p>
                You can check which license key is currently in use on a specific device by going to{" "}
                <b>&apos;Help&apos; &gt; 'About Macrium Reflect'</b> directly in the Macrium Reflect software.
              </p>
            )}
          </>
        )}

        <div className="flex flex-column mb2 mt3" style={{ maxWidth: "300px", gap: "8px" }}>
          <label htmlFor="friendlyName">Friendly Name</label>
          <Input
            maxLength={30}
            placeholder="Enter name here..."
            type="text"
            autoFocus
            id="friendlyName"
            value={friendlyName}
            onChange={(e) => setFriendlyName(e.target.value)}
          />
        </div>
      </Modal.Content>
      <Modal.Actions>
        <div className={`flex items-center justify-between`}>
          {hasExistingFriendlyName ? (
            <Button
              className="red ui"
              size="medium"
              content="Delete"
              onClick={deleteFriendlyName}
              disabled={isUpdating}
            />
          ) : (
            <div />
          )}
          <div className="flex" style={{ gap: "8px" }}>
            <Button secondary size="medium" content="Cancel" onClick={onClose} disabled={isUpdating} />
            <Button
              primary
              size="medium"
              content={hasExistingFriendlyName ? "Save" : "Save Friendly Name"}
              onClick={updateFriendlyName}
              loading={isUpdating}
              disabled={!friendlyName.trim() || friendlyName.trim() === selectedLicense.friendlyName || isUpdating}
            />
          </div>
        </div>
      </Modal.Actions>
    </Modal>
  );
};

export default FriendlyNameModal;
