import React from "react";
import moment from "moment";
import { MIcon } from "@macrium/shared-components";

import HeaderWithContent from "../HeaderWithContent/HeaderWithContent.jsx";
import RegistrationCode from "../RegistrationCode.jsx";
import { SUBSCRIPTION, PERPETUAL, PRODUCTS_EDITIONS } from "../../../../constants/licenses-products.js";
import { CORE, SERVER_PLUS, SYSTEM_BUILDERS } from "../../../../constants/licenses.js";
import { formatDate } from "../../../../utils/generic.js";
import SubscriptionLabel from "../../../licence/SubscriptionLabel/SubscriptionLabel.jsx";
import { useSelector } from "react-redux";
import { isEnterpriseUser } from "../../../../selectors/basics.js";
import ReflectXUpgrade from "../ReflectXUpgrade/ReflectXUpgrade.jsx";
import {
  isProductSiteBackup,
  isProductSiteDeploy,
  isProductSiteManager,
  isProductSiteManagerOrSiteDeploy,
} from "../../../../utils/license/productIsSiteManager.js";

const LicenseHeader = ({ data, account }) => {
  const isExpired = moment().isAfter(moment(data.nextExpiry));
  const isEnterprise = useSelector(isEnterpriseUser);
  const showCorrectHeading = () => {
    if (isProductSiteManager(data.description) || isProductSiteBackup(data.description)) return "Seats:";
    if (isProductSiteDeploy(data.description)) {
      const allSysBuilders =
        data.licenses?.length > 0 &&
        data.licenses.every((license) => license.statuses.find((status) => status.id === SYSTEM_BUILDERS));
      if (allSysBuilders) return "Deployments:";
      const allCore =
        data.licenses?.length > 0 &&
        data.licenses.every((license) => license.statuses.find((status) => status.id === CORE));
      if (allCore) return "Endpoints:";
      return "Endpoints & Deployments";
    }
  };
  const showReflectUpgradeMessage =
    (isProductSiteBackup(data.description) ? false : data.subscription === PERPETUAL) &&
    !data.ltscVersion &&
    data.edition !== PRODUCTS_EDITIONS[SERVER_PLUS];
  return (
    <>
      <div className="product-card__product__extended-header">
        <div className="flex flex-wrap" style={{ gap: "1em" }}>
          {data.subscription === PERPETUAL && data.lastPurchasedOn && (
            <div>
              <HeaderWithContent title="Last Purchased On:">
                {moment(data.lastPurchasedOn).format("L")}
              </HeaderWithContent>
            </div>
          )}

          {data.isTrial === "1" && (
            <>
              <RegistrationCode account={account} />

              {(isProductSiteManager(data.description) || isProductSiteBackup(data.description)) && (
                <div className="site-manager-trial-info">
                  <div className="site-manager-trial-info__icon">
                    <MIcon name="info circle" size="large" color="cGrey4" className="mr1" />
                  </div>
                  <div className="site-manager-trial-info__text">
                    Each {data.description} trial starts with a Server trial license and a Workstation (5-seat) trial
                    license
                  </div>
                </div>
              )}
            </>
          )}

          {data.subscription === SUBSCRIPTION && (
            <>
              <div className="product-card__product__extended-header__subscription">
                {isExpired ? (
                  <HeaderWithContent title="Expired On:">
                    {data.lastExpiry ? moment(data.lastExpiry).format("L") : null}
                  </HeaderWithContent>
                ) : (
                  <>
                    <HeaderWithContent title="Upcoming Renewal Date:">
                      {data.nextExpiry ? formatDate(data.nextExpiry.unix()) : null}
                    </HeaderWithContent>
                    <HeaderWithContent
                      title="Total To Pay:"
                      className="product-card__product__extended-header__subscription-pay"
                    >
                      {data.totalToPay}
                    </HeaderWithContent>
                  </>
                )}
              </div>
            </>
          )}

          {isProductSiteManagerOrSiteDeploy(data.description) && data.seats && !isEnterprise && (
            <HeaderWithContent title={showCorrectHeading()}>{data.seats}</HeaderWithContent>
          )}
        </div>

        <div>
          <SubscriptionLabel
            isPerpetual={data.subscription !== SUBSCRIPTION}
            isExpired={isExpired}
            isTrial={data.isTrial === "1"}
            ltscVersion={data.ltscVersion}
          />
        </div>
      </div>
      {showReflectUpgradeMessage && <ReflectXUpgrade description={data.description} />}
    </>
  );
};

export default LicenseHeader;
