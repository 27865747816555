import { Popup } from "semantic-ui-react";
import { MIcon } from "@macrium/shared-components";
import PropTypes from "prop-types";
import CopyToClipboard from "../CopyToClipboard";

const RegCodeInclTooltip = ({ urc }) => {
  if (!urc) return null;
  return (
    <div className="flex flex-start">
      <CopyToClipboard textToCopy={urc} />
      <div>{urc}</div>
      <Popup
        className="fix-popup-alignment"
        trigger={
          <div data-testid="popup-trigger">
            <MIcon size="largeSmall" name="info circle" className="mlhalf mt0 mb0" />
          </div>
        }
        wide
        hideOnScroll
        content={
          <div>
            <p>
              This code is paired with your email address. Use copy and paste to enter it when prompted in the installer
              registration page.
            </p>
          </div>
        }
        position="bottom right"
        context={null}
        hoverable
      />
    </div>
  );
};

export default RegCodeInclTooltip;

RegCodeInclTooltip.propTypes = {
  urc: PropTypes.string.isRequired,
};
