import { createSelector } from "reselect";
import { map, filter, find, keyBy } from "lodash";
import moment from "moment";

import { getProductsRange } from "./basics";
import { getLicenseGroups } from "./licenses";
import findProductDetails from "../utils/findProductDetails";
import { PRODUCTS_EDITIONS } from "../constants/licenses-products";
import { REFLECT_HOME, SERVER_PLUS, SITE_MANAGER } from "../constants/licenses";

// Prepare products range array to facilitate other selectors
export const getComputedProductsRange = createSelector([getProductsRange], (productsRange) =>
  map(productsRange, (prod) => {
    const version = `${prod.majorVersion}.${prod.minorVersion}.${prod.build}`;
    const productDetails = findProductDetails(
      { edition: prod.edition, majorVersion: prod.majorVersion, description: prod.description },
      productsRange
    );

    return {
      ...prod,
      version,
      buyNowURL: productDetails?.productInfoURL || null,
      downloadURL64: productDetails?.downloadURL64 || null,
      downloadURL32: productDetails?.downloadURL32 || null,
      downloadURLARM: productDetails?.downloadURLARM || null,
      kbArticle: productDetails?.kbArticle || null,
      releaseNotes: productDetails?.releaseNotes || null,
    };
  })
);

const findInOwnedProduct = (ownedProducts, edition) =>
  find(ownedProducts, ({ edition: productEdition }) => productEdition === edition);

// Filter owned products from product range
export const getFilteredProductsRange = createSelector(
  [getComputedProductsRange, getLicenseGroups],
  (productsRange, ownedProducts) => {
    return filter(
      productsRange,
      ({ edition }) => !findInOwnedProduct(ownedProducts, edition) && edition !== PRODUCTS_EDITIONS[SERVER_PLUS]
    );
  }
);

export const productsRangeBasicLookup = createSelector([getProductsRange], (productsRange) =>
  keyBy(productsRange, "edition")
);

const findReleaseDateForX = (edition) => (productsRange) => {
  const homeProduct = find(productsRange, { edition, majorVersion: 10 });

  const effectiveReleaseDate = find(homeProduct?.versionInfo, { majorVersion: 10 })?.effectiveReleaseDate || 0;

  return moment.unix(effectiveReleaseDate);
};

export const getReflectXReleaseDate = createSelector(
  [getProductsRange],
  findReleaseDateForX(PRODUCTS_EDITIONS[REFLECT_HOME])
);
export const getPlatformXReleaseDate = createSelector(
  [getProductsRange],
  findReleaseDateForX(PRODUCTS_EDITIONS[SITE_MANAGER])
);
