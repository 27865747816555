import React from "react";
import PropTypes from "prop-types";
import HeaderWithContent from "../HeaderWithContent/HeaderWithContent.jsx";
import ROUTES from "../../../../app/routes.js";

import "./LicenseUsage.styles.scss";
import { updateFilter } from "../../../../slices/products/products.js";
import { useDispatch, useSelector } from "react-redux";
import { AVAILABLE, EXPIRED, INSTALLED, SITEDEPLOY } from "../../../../constants/licenses.js";
import TechnicianCount from "../../../licence/TechnicianCount.jsx";
import { getLicenseUserCount } from "../../../../utils/licenses.js";
import { isEnterpriseUser } from "../../../../selectors/basics.js";
import plur from "plur";
import { isTechOrDKProduct } from "../../../../utils/products.js";

const LicenseUsage = ({ data, goTo }) => {
  const dispatch = useDispatch();
  const {
    licenses = [],
    activeCount = 0,
    description,
    version,
    expiredCount = 0,
    ltscVersion,
    descriptionIdForLicensesPage,
    edition,
  } = data;
  const licensesLength = licenses.length;
  const availableLicenses = licensesLength - activeCount - expiredCount;
  const isEnterprise = useSelector(isEnterpriseUser);
  const filterLicensesByVersion = () => {
    if (version < 10 && !ltscVersion)
      dispatch(updateFilter({ key: descriptionIdForLicensesPage, filter: [`V${version}`] }));

    goTo(ROUTES.LICENCES, { productName: descriptionIdForLicensesPage });
  };
  const showSingleLicenseWarning = description === SITEDEPLOY;
  if (isEnterprise) {
    return (
      <HeaderWithContent title="License Model:">
        <p>Enterprise License</p>
      </HeaderWithContent>
    );
  }
  return isTechOrDKProduct(edition) ? (
    <TechnicianCount
      sumCount={data.sumCount}
      userCount={getLicenseUserCount(edition, data.sumCount, data.expiredCount)}
      onSumCountClick={filterLicensesByVersion}
    />
  ) : (
    <div className="relative">
      <HeaderWithContent title="License Keys:">
        <p className="license-usage-info">
          You have{" "}
          <span className="license-usage-info--bold clickable" onClick={filterLicensesByVersion}>
            {licensesLength}
          </span>{" "}
          license {plur("key", licensesLength)} with{" "}
          <span
            className={`license-usage-info--bold  ${activeCount === 0 ? "error" : "clickable"}`}
            onClick={() => {
              if (activeCount > 0) {
                if (version < 10 && !data.ltscVersion)
                  dispatch(
                    updateFilter({
                      key: descriptionIdForLicensesPage,
                      filter: [`V${version}`, INSTALLED],
                    })
                  );
                else
                  dispatch(
                    updateFilter({
                      key: descriptionIdForLicensesPage,
                      filter: [INSTALLED],
                    })
                  );
                goTo(ROUTES.LICENCES, { productName: descriptionIdForLicensesPage });
              }
            }}
          >
            {activeCount}
          </span>{" "}
          currently installed{" "}
          {(availableLicenses > 0 || expiredCount > 0) && (
            <span>
              (
              {availableLicenses > 0 && (
                <span
                  onClick={() => {
                    if (version < 10 && !data.ltscVersion)
                      dispatch(
                        updateFilter({
                          key: descriptionIdForLicensesPage,
                          filter: [`V${version}`, AVAILABLE],
                        })
                      );
                    else
                      dispatch(
                        updateFilter({
                          key: descriptionIdForLicensesPage,
                          filter: [AVAILABLE],
                        })
                      );
                    goTo(ROUTES.LICENCES, { productName: descriptionIdForLicensesPage });
                  }}
                >
                  <span className="underline clickable">{availableLicenses}</span> available
                </span>
              )}
              {expiredCount > 0 && (
                <span
                  onClick={() => {
                    if (version < 10)
                      dispatch(
                        updateFilter({
                          key: descriptionIdForLicensesPage,
                          filter: [`V${version}`, EXPIRED],
                        })
                      );
                    else
                      dispatch(
                        updateFilter({
                          key: descriptionIdForLicensesPage,
                          filter: [EXPIRED],
                        })
                      );
                    goTo(ROUTES.LICENCES, { productName: description });
                  }}
                >
                  {availableLicenses > 0 ? " & " : ""}
                  <span className="underline clickable">{expiredCount}</span> expired
                </span>
              )}
              )
            </span>
          )}
        </p>
      </HeaderWithContent>

      {showSingleLicenseWarning && (
        <p>
          Only <b>1</b> license key can be used per site installation
        </p>
      )}
    </div>
  );
};

LicenseUsage.propTypes = {
  data: PropTypes.shape({
    totalLicenses: PropTypes.number,
    licensesInUse: PropTypes.number,
    activeCount: PropTypes.number,
    description: PropTypes.string,
    version: PropTypes.string,
  }).isRequired,
  goTo: PropTypes.func.isRequired,
};

export default LicenseUsage;
