export const MAX_LENGTH_ERROR_MESSAGE = "Value is too long";

export const DEFAULT_MAX_LENGTH = { maxLength: { value: 35, message: "Value is too long" } };
export const COMPANY_DOMAIN_TOOLTIP = "Enter your company's website (e.g. macrium.com or your-company.co.uk)";
export const PHONE_MAX_LENGTH = { maxLength: { value: 20, message: MAX_LENGTH_ERROR_MESSAGE } };
export const ADDRESS_MAX_LENGTH = { maxLength: { value: 90, message: MAX_LENGTH_ERROR_MESSAGE } };
export const JOB_MAX_LENGTH = { maxLength: { value: 50, message: MAX_LENGTH_ERROR_MESSAGE } };
export const COMPANY_MAX_LENGTH = { maxLength: { value: 90, message: MAX_LENGTH_ERROR_MESSAGE } };
export const COMPANY_WEBSITE_PATTERN = {
  pattern: {
    value: /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/i,
    message: "Please enter a valid website address (e.g. your-company.com)",
  },
};
export const EMAIL_CHECKLIST = [
  "gmail",
  "googlemail",
  "hotmail",
  "outlook",
  "aol",
  "proton",
  "yahoo",
  "ymail",
  "zohocorp",
  "icloud",
  "mac",
  "me",
  "mail",
];
