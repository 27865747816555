import React from "react";
import PropTypes from "prop-types";
import { Divider, Popup } from "semantic-ui-react";

import "./Page.styles.scss";
// import AddKeysModal from "./AddKeysModal/AddKeysModal";
import AncillaryButton from "./AncillaryButton";
import UpgradeButton from "./UpgradeButton/UpgradeButton";
import { SUBMIT_TICKET } from "../../constants/urls";
import TandCPolicy from "./TandCPolicy/TandCPolicy";

const Page = ({ title, children }) => {
  // const [addKeysModalisOpen, setAddKeysModalIsOpen] = useState(false);
  return (
    <div className="page-container">
      {/* To be added back once the server accepts new cyphers - freshdesk */}
      {/* {addKeysModalisOpen && <AddKeysModal isOpen={true} close={() => setAddKeysModalIsOpen(false)} />} */}

      <div>
        <div className="page-container__header">
          <h1 className="mb0">{title}</h1>

          <div className="page-container__header-actions">
            <div /*onClick={() => setAddKeysModalIsOpen(true)}*/>
              <Popup
                className="fix-popup-alignment"
                trigger={
                  <a href={SUBMIT_TICKET} target="_blank" rel="noreferrer">
                    <AncillaryButton icon="key">
                      <div className="center">
                        <div>Add Existing</div>
                        <div>Keys</div>
                      </div>
                    </AncillaryButton>
                  </a>

                  // To be added back once the server accepts new cyphers - freshdesk
                  // <div>
                  //   <AncillaryButton icon="key">
                  //     <div className="center">
                  //       <div>Add Existing</div>
                  //       <div>Keys</div>
                  //     </div>
                  //   </AncillaryButton>
                  // </div>
                }
                wide
                hideOnScroll
                content={
                  <div data-testid="add-keys-pop-up">
                    <p className="mbhalf">
                      If you have existing or older license keys you'd like to manage through your Macrium account, you
                      can easily add them by clicking the "Add Existing Keys" button.
                    </p>
                    <p className="mb0 mt2">
                      This action will let you create a support ticket and our dedicated support team will take it from
                      there.
                    </p>

                    {/* To be added back once the server accepts new cyphers - freshdesk */}
                    {/* <p className="mb0">
                      This action will generate a support ticket, and our dedicated support team will take it from
                      there.
                    </p> */}
                  </div>
                }
                position="bottom left"
                hoverable
              />
            </div>
            <UpgradeButton pageHeader />
          </div>
        </div>
        <Divider className="mt3 mb3" />

        {children}
      </div>
      <TandCPolicy loggedIn />
    </div>
  );
};

Page.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
};

export default Page;
