import { Message, Segment } from "semantic-ui-react";
import { isEmpty } from "lodash";

const EmptyStatus = ({ isError }) => {
  if (!isEmpty(isError)) {
    return <Message negative content={isError} />;
  }
  return (
    <Segment raised>
      <h3>There are no licenses</h3>
      <div className="mb2">It looks like there are no licenses associated with this account.</div>
      <h4>Common reasons include</h4>
      <ul>
        <li>You may have purchased the software using a different email address.</li>
        <li>The license might be registered under a different account.</li>
      </ul>
      <h4>What can you do?</h4>
      <ul>
        <li>Check if you have another account with a different email address.</li>
        <li>Create an account with an existing email address.</li>
        <li>Contact our support team for help.</li>
      </ul>
    </Segment>
  );
};

export default EmptyStatus;
