import Cookies from "js-cookie";
import { MACRIUM_TRIAL } from "../constants/cookies";
import { isUndefined } from "lodash";
import { TECHNICIAN } from "../constants/licenses";

export const getTrialFromCookie = () => {
  const trialCookie = Cookies.get(MACRIUM_TRIAL);
  if (trialCookie) {
    try {
      const trialProductData = JSON.parse(trialCookie);
      const { arch, edition, description, trialSource } = trialProductData;
      const args = { architecture: arch, edition, trialSource };
      if (!isUndefined(description)) args.description = description;
      if (isUndefined(description) && edition === 10) args.description = TECHNICIAN;
      return args;
    } catch (error) {
      console.error("Error parsing trial cookie: ", error);
      return error;
    }
  }
  return null;
};
