import { split, toString } from "lodash";
import { LICENSES_DESCRIPTIVE_STRING, SERVER_PLUS, TECHNICIAN } from "../constants/licenses";
import { PRODUCTS_EDITIONS } from "../constants/licenses-products";

const findProductDetails = (data, productDownloadURLS) => {
  if (!data || !productDownloadURLS) return null;
  const currentProductVersion = data.majorVersion || Number(split(data.version, ".")[0]);
  const findEdition = () => {
    if (data.description === TECHNICIAN) return 10;
    if (data.edition) return data.edition;
    return PRODUCTS_EDITIONS[LICENSES_DESCRIPTIVE_STRING[data.description]];
  };
  const productDetails = productDownloadURLS.find((prod) => {
    return prod.edition === findEdition();
  });
  const versionDetails = () => {
    const versionInfo =
      productDetails?.versionInfo?.find((ver) => {
        if (data.ltscVersion) {
          // TODO: Should be fixes as part of the refactor. ltscVersion is a string and a number in getlicenseinfo and getallprodictsandversions (respectively)
          return (
            (ver.version === currentProductVersion || ver.majorVersion === currentProductVersion) &&
            toString(ver.ltscVersion) === data.ltscVersion
          );
        }

        return (
          (ver.version === currentProductVersion || ver.majorVersion === currentProductVersion) && !ver.ltscVersion
        );
      }) || null;
    if (!versionInfo && Number(currentProductVersion) === 10 && findEdition() === PRODUCTS_EDITIONS[SERVER_PLUS]) {
      return productDetails?.versionInfo?.find((ver) => ver.majorVersion === 8 && !ver.ltscVersion) || null;
    }
    return versionInfo;
  };
  const newVersionInfo = versionDetails();
  if (productDetails && newVersionInfo) {
    return newVersionInfo;
  }
  return null;
};
export default findProductDetails;
