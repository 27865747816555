import { toNumber } from "lodash";
import { PRODUCTS_EDITIONS } from "../constants/licenses-products";
import {
  DEPLOYMENT_KIT,
  DEPLOYMENT_KIT_SUBSCRIPTION,
  TECHNICIAN,
  TECHNICIAN_SUBSCRIPTION,
} from "../constants/licenses";

// From a product perspective, V7 and below are EOF (legacy)
export const isLegacy = (version) => toNumber(version) <= 8;

export const isBusinessProduct = (edition) => edition && edition !== 6;

export const isTechProduct = (edition) =>
  edition === PRODUCTS_EDITIONS[TECHNICIAN] || edition === PRODUCTS_EDITIONS[TECHNICIAN_SUBSCRIPTION];
export const isDKProduct = (edition) =>
  edition === PRODUCTS_EDITIONS[DEPLOYMENT_KIT] || edition === PRODUCTS_EDITIONS[DEPLOYMENT_KIT_SUBSCRIPTION];

export const isTechOrDKProduct = (edition) => isTechProduct(edition) || isDKProduct(edition);

// This can probably be removed as part of the license refactor
export const swapSubscriptionForAnnualPlan = (description) => {
  if (description.includes("Subscription")) {
    const newProductDescription = description.replace("Subscription", "Annual Plan");
    return newProductDescription;
  }

  if (description.includes("LTSC")) {
    return description.replace("LTSC", " LTSC ");
  }

  return description;
};
