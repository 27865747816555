import {
  OFF_ACTIVATION_KB,
  OFF_ACTIVATION_SD_KB,
  OFF_ACTIVATION_SM_KB,
  OFF_ACTIVATION_VIDEO_URL,
  OFF_ACT_SM_SD_VIDEO_URL,
} from "../../constants/urls";
import { Link } from "@macrium/shared-components";
import PropTypes from "prop-types";
import {
  isProductSiteBackup,
  isProductSiteDeploy,
  isProductSiteManager,
} from "../../utils/license/productIsSiteManager";

const MediaContainer = ({ hasNoAvailableLicenses, productDescription }) => {
  const isSMorSB = isProductSiteManager(productDescription) || isProductSiteBackup(productDescription);
  const isSD = isProductSiteDeploy(productDescription);

  let kbUrl = OFF_ACTIVATION_KB;

  if (isSMorSB) kbUrl = OFF_ACTIVATION_SM_KB;
  if (isSD) kbUrl = OFF_ACTIVATION_SD_KB;

  return (
    <div className="offline-videoContent p0">
      <iframe
        data-testid="video-container"
        style={{
          aspectRatio: "16 / 9",
          width: "100%",
          border: "none",
        }}
        src={isSMorSB || isSD ? OFF_ACT_SM_SD_VIDEO_URL : OFF_ACTIVATION_VIDEO_URL}
        title="YouTube video player"
        controls="1"
        allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
      <div className="offline-video-title pt1">How-to Video</div>
      <div className="pb2">How to Offline Activate</div>
      {!hasNoAvailableLicenses && (
        <a href={kbUrl} target="_blank" rel="noopener noreferrer">
          <Link>Need more help?</Link>
        </a>
      )}
    </div>
  );
};

MediaContainer.propTypes = {
  hasNoAvailableLicenses: PropTypes.bool,
  productDescription: PropTypes.string.isRequired,
};

export default MediaContainer;
