import React from "react";
import PropTypes from "prop-types";
import { useGoogleLogin } from "@react-oauth/google";

import SSOButton from "./SSOButton.jsx";

/*
 * https://developers.google.com/identity/branding-guidelines
 */
const GoogleButton = ({ onSuccess, onError, onNonOAuthError, width, register }) => {
  const login = useGoogleLogin({
    onSuccess,
    onError,
    onNonOAuthError,
  });

  return <SSOButton type="google" text={register ? "Sign up" : "Sign in"} width={width} onClick={() => login()} />;
};

GoogleButton.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  width: PropTypes.string,
  register: PropTypes.bool,
};

GoogleButton.defaultProps = {
  register: false,
};

export default GoogleButton;
