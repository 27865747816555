import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Form, Input, Popup } from "semantic-ui-react";
import { FormFieldError, MIcon } from "@macrium/shared-components";
import { forEach, isEmpty } from "lodash";

import { REGISTER_TYPES } from "../../../constants/auth";
import ProfilingAddress from "../../profiling/ProfilingAddress";
import PhoneInput from "../../generic/Inputs/PhoneInput";
import RegisterButton from "./Buttons";
import SSOButton from "../SSOButtons/SSOButton";
import FormFieldController from "../../generic/FormFieldController";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import {
  PHONE_MAX_LENGTH,
  JOB_MAX_LENGTH,
  COMPANY_MAX_LENGTH,
  COMPANY_DOMAIN_TOOLTIP,
  COMPANY_WEBSITE_PATTERN,
} from "../../../constants/forms/validation";
import { DEFAULT_BUSINESS_FIELDS } from "./fields";
import canEmailBeUsedForCompanyDomain from "../../../utils/register/canEmailBeUsedForCompanyDomain/canEmailBeUsedForCompanyDomain";

const BusinessForm = ({ type, loading, onSave, values, goBack }) => {
  const DEFAULT_BUSINESS_FIELDS_INC_DOMAIN = {
    ...DEFAULT_BUSINESS_FIELDS,
    companyDomain: canEmailBeUsedForCompanyDomain(values.email),
  };
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    watch,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      ...DEFAULT_BUSINESS_FIELDS_INC_DOMAIN,
      ...values,
    },
    mode: "onBlur",
  });

  React.useEffect(() => {
    if (isEmpty(values)) return;

    forEach(DEFAULT_BUSINESS_FIELDS_INC_DOMAIN, (defaultValue, key) => {
      // dirty/touch fields in case values are coming from a parent component
      setValue(key, values[key] || defaultValue, { shouldDirty: true, shouldTouch: true });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, values]);

  const onSubmit = (data) => onSave(data);

  return (
    <>
      <div className="register-container__back_first_step" onClick={() => goBack(getValues())}>
        <MIcon name="chevron left" color="cGrey3" />
        <span className="ml1">Back</span>
      </div>

      <Form>
        <Form.Field error={!!errors?.job}>
          <label>Job Role</label>
          <FormFieldController
            name="job"
            control={control}
            rules={{ required: "Job Role is required", ...JOB_MAX_LENGTH }}
            render={({ field }) => (
              <>
                <Input icon iconPosition="left" placeholder="Job Role" autoFocus {...field}>
                  <input />
                  <MIcon name="job role" />
                </Input>

                <FormFieldError error={errors?.job?.message} />
              </>
            )}
          />
        </Form.Field>

        <Form.Field error={!!errors?.company}>
          <label>Company Name</label>
          <FormFieldController
            name="company"
            control={control}
            rules={{ required: "Company Name is required", ...COMPANY_MAX_LENGTH }}
            render={({ field }) => (
              <>
                <Input icon iconPosition="left" placeholder="Company Name" {...field}>
                  <input />
                  <MIcon name="company" />
                </Input>

                <FormFieldError error={errors?.company?.message} />
              </>
            )}
          />
        </Form.Field>
        <Form.Field error={!!errors?.companyDomain}>
          <label className="flex flex-center">
            Company Website
            <Popup
              className="fix-popup-alignment"
              trigger={
                <div data-testid="popup-trigger">
                  <MIcon size="largeSmall" name="info circle" className="mlhalf mt1 mbhalf" color="blue" />
                </div>
              }
              position="top center"
              wide
              hideOnScroll
              content={<div>{COMPANY_DOMAIN_TOOLTIP}</div>}
              hoverable
            />
          </label>
          <FormFieldController
            name="companyDomain"
            control={control}
            rules={{
              required: "Company Website is required",
              ...COMPANY_WEBSITE_PATTERN,
              ...COMPANY_MAX_LENGTH,
            }}
            render={({ field }) => (
              <>
                <Input icon iconPosition="left" placeholder="Company Website" {...field}>
                  <input />
                  <MIcon name="network" />
                </Input>

                <FormFieldError error={errors?.companyDomain?.message} />
              </>
            )}
          />
        </Form.Field>
        <Form.Field error={!!errors?.phone}>
          <label>Phone Number</label>
          <FormFieldController
            name="phone"
            control={control}
            rules={PHONE_MAX_LENGTH}
            render={({ field }) => <PhoneInput placeholder="" {...field} showIcon />}
          />

          <FormFieldError error={errors?.phone?.message} />
        </Form.Field>

        <ProfilingAddress
          addressLine1={watch("addressLine1")}
          addressLine2={watch("addressLine2")}
          city={watch("city")}
          stateProvince={watch("stateProvince")}
          postalZipCode={watch("postalZipCode")}
          country={watch("country")}
          setValue={setValue}
          errors={errors}
          control={control}
          autoFocus={false}
        />
        <PrivacyPolicy />
        {type === REGISTER_TYPES.EMAIL && (
          <RegisterButton
            disabled={loading || !isEmpty(errors) || !isValid}
            loading={loading}
            content="Register"
            className="mt3"
            handleSubmit={() => handleSubmit(onSubmit)}
          />
        )}
        {type === REGISTER_TYPES.GOOGLE && (
          <SSOButton
            type="google"
            text="Register"
            disabled={loading || !isEmpty(errors) || !isValid}
            fluid
            className="mt3"
            onClick={handleSubmit(onSubmit)}
          />
        )}
        {type === REGISTER_TYPES.MICROSOFT && (
          <SSOButton
            type="microsoft"
            text="Register"
            disabled={loading || !isEmpty(errors) || !isValid}
            fluid
            className="mt3"
            onClick={handleSubmit(onSubmit)}
          />
        )}
      </Form>
    </>
  );
};

BusinessForm.proTypes = {
  loading: PropTypes.bool,
  values: PropTypes.object,
  type: PropTypes.oneOf([REGISTER_TYPES.EMAIL, REGISTER_TYPES.GOOGLE, REGISTER_TYPES.MICROSOFT]).isRequired,
  onSave: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
};

BusinessForm.defaultProps = {
  loading: false,
  values: {},
};

export default BusinessForm;
