import { Modal, Divider } from "semantic-ui-react";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./OfflineActivationModal.styles.scss";
import MediaContainer from "../MediaContainer";
import OfflineStepOne from "../OfflineActivationSteps/OfflineStepOne";
import OfflineStepTwo from "../OfflineActivationSteps/OfflineStepTwo";
import { get } from "lodash";
import { REJECTED } from "../../../constants/redux-action-statuses";
import OfflineStepThree from "../OfflineActivationSteps/OfflineStepThree";
import {
  clearOfflineKey,
  closeModal,
  getOfflineKeyAsync,
  openModal,
  removeCookieData,
  selectLicenseKey,
} from "../../../slices/offlineActivation/offlineActivation";
import {
  getOfflineError,
  getOfflineKey,
  getOfflineProductDetails,
  isOfflineCodeActivating,
  isOfflineModalOpen,
} from "../../../selectors/basics";
import { fetchLicenceInfoAsync } from "../../../slices/products/products";
import { getNextAvailableLicense } from "../../../selectors/offlineActivation";
import NoLicenseStep from "../OfflineActivationSteps/NoLicenseStep";
import OfflineHeader from "./OfflineHeader";
import LicenseContent from "./LicenseContent";
import setUpLicenseOptions from "../../../utils/license/setUpLicenseOptions";
import moment from "moment";
import licenseExpiresSoon from "../../../utils/license/licenseExpiresSoon";

const isLicenceAboutToExpire = (licences, selectedLicenseKey) => {
  const selectedLicenseStatuses =
    licences?.find((license) => license.licenseId === selectedLicenseKey)?.statuses || null;
  const licenseExpiryDate =
    selectedLicenseStatuses?.find((status) => status.id.startsWith("Renewal"))?.text?.split(": ")[1] || null;
  if (!licenseExpiryDate || !selectedLicenseStatuses) return false;
  return licenseExpiresSoon(moment(licenseExpiryDate, "DD/MM/YYYY"));
};

const OfflineActivationModal = () => {
  const dispatch = useDispatch();
  const isModalOpen = useSelector(isOfflineModalOpen);
  const product = useSelector(getOfflineProductDetails);
  const selectedLicenseKey = useSelector(getNextAvailableLicense);
  const offlineKey = useSelector(getOfflineKey);
  const isActivating = useSelector(isOfflineCodeActivating);
  const error = useSelector(getOfflineError);
  const [stepNumber, setStepNumber] = useState(1);
  const [activationCode, setActivationCode] = useState("");
  // set up license options for the dropdown
  const licenseOptions = useMemo(() => setUpLicenseOptions(product?.licenses), [product]);
  // if an activation code is already present (from passed params) this is set in state
  useEffect(() => {
    if (product?.activationCode) {
      setActivationCode(product.activationCode);
    }
  }, [product]);

  if (!product?.description || !isModalOpen) return null;
  const hasNoAvailableLicenses = !selectedLicenseKey || licenseOptions.length === 0;
  const activationKey = product.licenses.find(
    (license) => license.licenseId === selectedLicenseKey
  )?.offlineActivationKey;
  const alreadyActiveOfflineKey = activationKey && activationKey !== "0";

  const handleActivation = async () => {
    const res = await dispatch(
      getOfflineKeyAsync({
        activationCode: alreadyActiveOfflineKey ? null : activationCode,
        license: selectedLicenseKey,
        isOEM: false,
      })
    );
    if (get(res, "meta.requestStatus") === REJECTED) {
      return res;
    }
    dispatch(removeCookieData());
    return nextStage();
  };
  const resetModal = () => {
    setStepNumber(1);
    setActivationCode("");
  };

  const handleCloseAndFinish = async (reopen) => {
    const res = await dispatch(fetchLicenceInfoAsync());
    if (get(res, "meta.requestStatus") === REJECTED) {
      return res;
    }
    resetModal();
    if (reopen) {
      dispatch(clearOfflineKey());
      const newProduct = {
        ...product,
        licenses: product.licenses.filter((license) => license.licenseId !== selectedLicenseKey),
      };
      dispatch(closeModal());
      return dispatch(openModal(newProduct));
    }

    return dispatch(closeModal());
  };
  const nextStage = () => {
    setStepNumber(stepNumber + 1);
  };
  return (
    <Modal open={isModalOpen} size="large">
      <Modal.Header>
        <OfflineHeader
          stepNumber={stepNumber}
          closeModal={() => {
            resetModal();
            dispatch(closeModal());
          }}
        />
      </Modal.Header>

      <Modal.Content>
        <Divider />
        <LicenseContent
          product={product}
          selectedLicenseKey={selectedLicenseKey}
          hasNoAvailableLicenses={hasNoAvailableLicenses}
          licenseKeyOptions={licenseOptions}
          selectAlternateKey={(key) => dispatch(selectLicenseKey(key))}
          stepNumber={stepNumber}
          error={error}
        />

        <div className="offline-container ">
          <div className="offline-mainContent p2">
            {hasNoAvailableLicenses && (
              <NoLicenseStep
                selectedLicenseKey={selectedLicenseKey}
                handleClose={() => {
                  resetModal();
                  dispatch(closeModal());
                }}
              />
            )}
            {stepNumber === 1 && !hasNoAvailableLicenses && (
              <OfflineStepOne
                alreadyActiveOfflineKey={alreadyActiveOfflineKey}
                activationCode={activationCode}
                setActivationCode={setActivationCode}
                showExpiryWarning={isLicenceAboutToExpire(product?.licenses, selectedLicenseKey)}
                handleActivation={handleActivation}
                error={error}
                isActivating={isActivating}
                selectedLicenseKey={selectedLicenseKey}
                productDescription={product?.description}
              />
            )}
            {stepNumber === 2 && <OfflineStepTwo offlineKey={offlineKey} handleNextStep={nextStage} />}
            {stepNumber === 3 && (
              <OfflineStepThree
                handleFinish={() => handleCloseAndFinish(false)}
                handleStartAgain={() => handleCloseAndFinish(true)}
                showStartAgain={product.licenses.length > 1}
                licenceKey={selectedLicenseKey}
              />
            )}
          </div>
          <MediaContainer hasNoAvailableLicenses={hasNoAvailableLicenses} productDescription={product.description} />
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default OfflineActivationModal;
