import { getLocation, push } from "connected-react-router";
import { useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import ROUTES from "../../../../app/routes";
import CreateAnAccount from "../CreateAnAccount/CreateAnAccount";
import { addCookieData } from "../../../../slices/offlineActivation/offlineActivation";

const OfflineActivationLink = ({ storedOfflineCookies }) => {
  const { query } = useSelector(getLocation);
  const dispatch = useDispatch();
  const goTo = useCallback((value) => dispatch(push(value)), [dispatch]);
  if (!storedOfflineCookies) {
    const { key, activationCode, action } = query;
    if ((!key || !activationCode) && !action) return null;

    dispatch(addCookieData({ key, activationCode, action }));
  }
  const goToPage = () => goTo(ROUTES.OFFLINE_ACTIVATION);
  return (
    <>
      <CreateAnAccount
        header="Offline License Activation"
        subHeader="Create an account or login to activate your license offline"
        hideForMobile={false}
        showIcons={false}
      />
      <div className="mb2">
        If you are unable to create an account please click below to begin your{" "}
        <span as="a" className="underline bold offline-link" style={{ cursor: "pointer" }} onClick={goToPage}>
          Offline Activation.
        </span>
      </div>
    </>
  );
};

export default OfflineActivationLink;
OfflineActivationLink.propTypes = {
  storedOfflineCookies: PropTypes.shape({
    key: PropTypes.string,
    activationCode: PropTypes.string,
    action: PropTypes.string,
  }),
};
OfflineActivationLink.defaultProps = {
  storedOfflineCookies: null,
};
