import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get, isEmpty, reduce, isUndefined, findIndex, filter } from "lodash";

import { getLicenceInfo } from "../../app/api/licences";
import { IDLE, LOADING, FAILED } from "../actionStatuses";
import {
  LICENSES_DESCRIPTIVE_STRING,
  REFLECT_HOME,
  REFLECT_HOME_SUBSCRIPTION,
  REFLECT_WORKSTATION,
  REFLECT_SERVER,
  SERVER_PLUS_VM,
  SERVER_PLUS,
  DEPLOYMENT_KIT,
  TECHNICIAN,
  SITEDEPLOY,
  SITE_MANAGER,
  LICENSES_WITH_SUBSCRIPTIONS,
  REFLECT_WORKSTATION_SUBSCRIPTION,
  REFLECT_SERVER_SUBSCRIPTION,
  SERVER_PLUS_SUBSCRIPTION,
  DEPLOYMENT_KIT_SUBSCRIPTION,
  TECHNICIAN_SUBSCRIPTION,
  SITE_MANAGER_SUBSCRIPTION,
  WORKSTATION_LTSC_2024,
  SERVER_LTSC_2024,
  SITE_BACKUP,
  SITE_BACKUP_SUBSCRIPTION,
} from "../../constants/licenses";
import { SUBSCRIPTION } from "../../constants/licenses-products";
import { isProductSiteBackup } from "../../utils/license/productIsSiteManager";

const REDUCER_NAME = "products";

const DEFAULT_LICENSES_FILTER = {
  [REFLECT_HOME]: [],
  [REFLECT_HOME_SUBSCRIPTION]: [],
  [REFLECT_WORKSTATION]: [],
  [REFLECT_WORKSTATION_SUBSCRIPTION]: [],
  [REFLECT_SERVER]: [],
  [REFLECT_SERVER_SUBSCRIPTION]: [],
  [SERVER_PLUS_VM]: [],
  [SERVER_PLUS]: [],
  [SERVER_PLUS_SUBSCRIPTION]: [],
  [DEPLOYMENT_KIT]: [],
  [TECHNICIAN]: [],
  [DEPLOYMENT_KIT_SUBSCRIPTION]: [],
  [TECHNICIAN_SUBSCRIPTION]: [],
  [SITEDEPLOY]: [],
  [SITE_MANAGER]: [],
  [SITE_MANAGER_SUBSCRIPTION]: [],
  [SITE_BACKUP]: [],
  [SITE_BACKUP_SUBSCRIPTION]: [],
  [WORKSTATION_LTSC_2024]: [],
  [SERVER_LTSC_2024]: [],
};

const initialState = {
  status: IDLE,
  data: [],
  error: "",
  loaded: false,
  licensesFilter: DEFAULT_LICENSES_FILTER,
};

export const fetchLicenceInfoAsync = createAsyncThunk(`${REDUCER_NAME}/fetchLicenceInfoRequest`, async (thunkAPI) => {
  const response = await getLicenceInfo();

  if (!response.success) return thunkAPI.rejectWithValue(response);

  return response;
});

export const productsSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    clearState: () => initialState,
    updateFilter: (state, { payload: { key, filter: filterArray } }) => {
      if (isUndefined(key) || isUndefined(filterArray)) return state;

      return {
        ...state,
        licensesFilter: {
          ...state.licensesFilter,
          [key]: reduce(
            filterArray,
            (newFilter, filterStr) => {
              const indexToRemove = findIndex(state.licensesFilter[key], (existingStr) => existingStr === filterStr);
              if (indexToRemove > -1) return filter(newFilter, (existingStr) => existingStr !== filterStr);
              return [...newFilter, filterStr];
            },
            state.licensesFilter[key]
          ),
        },
      };
    },
    clearFilters: (state) => {
      state.licensesFilter = DEFAULT_LICENSES_FILTER;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLicenceInfoAsync.pending, (state) => {
        if (!state.loaded || !isEmpty(state.error)) {
          state.status = LOADING;
          state.error = "";
        }
      })
      .addCase(fetchLicenceInfoAsync.fulfilled, (state, { payload }) => {
        const summary = reduce(
          get(payload, "data.summary", []),
          (newProducts, product) => {
            if (get(LICENSES_DESCRIPTIVE_STRING, product.description)) {
              const needsSubscriptionCard = isProductSiteBackup(product?.description)
                ? product?.subscription === SUBSCRIPTION
                : Number(product?.version) >= 10;
              newProducts.push(
                needsSubscriptionCard && LICENSES_WITH_SUBSCRIPTIONS[product?.description]
                  ? {
                      ...product,
                      description: LICENSES_WITH_SUBSCRIPTIONS[product.description],
                    }
                  : product
              );
            }

            return newProducts;
          },
          []
        );
        state.status = IDLE;
        state.data = summary;
        state.loaded = true;
        state.error = "";
      })
      .addCase(fetchLicenceInfoAsync.rejected, (state, { payload }) => {
        state.status = FAILED;
        state.loaded = true;
        state.error = payload.message;
      });
  },
});

export const { clearState, updateFilter, clearFilters } = productsSlice.actions;

export default productsSlice.reducer;
