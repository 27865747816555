import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "semantic-ui-react";
import Cookies from "js-cookie";

import Auth from "./components/auth/auth.jsx";
import TFA from "./components/auth/2fa.jsx";
import ConfirmEmail from "./components/auth/confirmEmail.jsx";
import AfterRegister from "./components/auth/afterRegister.jsx";
import Licences from "./components/licence/LicensesPage.jsx";
import Downloads from "./components/downloads/DownloadsPage.jsx";
import Overview from "./components/overview/OverviewPage.jsx";
import Billing from "./components/billing/BillingPage.jsx";
import ForgotPassword from "./components/auth/ForgotPassword/ForgotPassword.jsx";
import ResetPassword from "./components/auth/ResetPassword/ResetPassword.jsx";
import AccountDetails from "./components/account/AccountDetails/AccountDetails.jsx";
import ROUTES from "./app/routes";
import { PreventLoggedInRoute, PrivateRoute } from "./customRoutes.jsx";
import IdleActivity from "./components/idleActivity.jsx";
import AuthContainer from "./components/generic/authContainer.jsx";
import { isLoggedIn } from "./selectors/basics";
import PortalLogo from "./components/auth/HeaderAndFooter/PortalLogo.jsx";
import ValueProposition from "./components/auth/HeaderAndFooter/ValueProposition.jsx";
import { ViewPortProvider } from "./components/context/viewPortContext.jsx";
import CustomPrompt from "./components/generic/Prompt.jsx";
import GTMPageView from "./utils/gtmPageView.js";

import "./App.styles.scss";
import { getInitialAuthTokenForStore } from "./utils/auth.js";
import { loginWithSession } from "./slices/auth/login.js";
import { MACRIUM_TRIAL } from "./constants/cookies.js";
import { CustomErrorBoundary } from "./components/generic/CustomErrorBoundary.jsx";
import Changelog from "./components/Changelog.jsx";
import ProfilingModal from "./components/profiling/ProfilingModal.jsx";
import {
  REFLECT_HOME,
  REFLECT_SERVER,
  REFLECT_WORKSTATION,
  SERVER_PLUS,
  SITEDEPLOY,
  SITE_BACKUP,
} from "./constants/licenses.js";
import OfflineActivationModal from "./components/OfflineActivation/OfflineActivationModal/OfflineActivationModal.jsx";
import OfflineActivationBasic from "./components/auth/OfflineActivationBasic/OfflineActivationBasic.jsx";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min.js";
import ReflectXModal from "./components/generic/ReflectXModal/ReflectXModal.jsx";
import ResetLicenseModal from "./components/generic/ResetLicenseModal/ResetLicenseModal.jsx";
import FriendlyNameModal from "./components/generic/FriendlyNameModal/FriendlyNameModal.jsx";

const Trials = () => {
  const goTo = ({ edition, arch, description }) => {
    Cookies.set(MACRIUM_TRIAL, JSON.stringify({ edition, arch, description }));

    window.open(window.location.origin);
  };

  return (
    <div className="flex flex-column p4" style={{ gap: "1rem" }}>
      <h4>Macrium Website</h4>

      <hr />

      <Button
        secondary
        content="Reflect Home"
        onClick={() => goTo({ edition: 6, arch: "x64", description: REFLECT_HOME })}
      />
      <Button
        secondary
        content="Reflect Workstation"
        onClick={() => goTo({ edition: 7, arch: "x64", description: REFLECT_WORKSTATION })}
      />
      <Button
        secondary
        content="Reflect Server"
        onClick={() => goTo({ edition: 8, arch: "x64", description: REFLECT_SERVER })}
      />
      <Button
        secondary
        content="Reflect Server Plus"
        onClick={() => goTo({ edition: 9, arch: "x64", description: SERVER_PLUS })}
      />
      <Button secondary content="Reflect Technicians" onClick={() => goTo({ edition: 10, arch: "x64" })} />
      <Button
        secondary
        content="SiteBackup"
        onClick={() => goTo({ edition: 1, arch: "x64", description: SITE_BACKUP })}
      />
      <Button
        secondary
        content="SiteDeploy"
        onClick={() => goTo({ edition: 11, arch: "x64", description: SITEDEPLOY })}
      />
    </div>
  );
};

const AuthLayout = ({ Component, className }) => {
  const isOem = useLocation()?.pathname === ROUTES.OEM_ACTIVATION;
  return (
    <div className="auth-layout-container">
      <PortalLogo />

      <AuthContainer className={className}>
        <CustomErrorBoundary>
          <Component />
        </CustomErrorBoundary>
      </AuthContainer>

      <ValueProposition
        text={
          isOem
            ? "Effortlessly activate licenses. Simplify the process and speed up the installation of our software on your machines."
            : "Effortlessly manage and administer all your products in one place. Simplify your workflow and stay organized with ease."
        }
      />
    </div>
  );
};

const withLayout = (Component, isAuthLayout, className) => () =>
  (
    <CustomErrorBoundary>
      {isAuthLayout ? (
        <AuthLayout className={className} Component={Component} />
      ) : (
        <div className="app-container-layout">
          <CustomPrompt />

          <Component />
        </div>
      )}
    </CustomErrorBoundary>
  );

const App = () => {
  const loggedIn = useSelector(isLoggedIn);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const sessionAuthToken = getInitialAuthTokenForStore();

    /**
     * If there is a valid jwt as a cookie, dispatch an action to login the user using that jwt
     */
    if (sessionAuthToken) {
      dispatch(loginWithSession(sessionAuthToken));
    }
  }, [dispatch]);

  return (
    <ViewPortProvider>
      <GTMPageView />
      <div className="flex fullheight scroll-y app-wrapper">
        {loggedIn && <IdleActivity />}
        <OfflineActivationModal />
        <ResetLicenseModal />
        <ProfilingModal />
        <ReflectXModal />
        <FriendlyNameModal />
        <Switch>
          {window.location.hostname !== "manage.macrium.com" && <Route path="/test-trials" render={Trials} />}

          <Route path={ROUTES.CHANGELOG} render={() => <Changelog />} />

          <PreventLoggedInRoute exact path={ROUTES.LOGIN} render={withLayout(Auth, true)} />
          <PreventLoggedInRoute exact path={ROUTES.REGISTER} render={withLayout(Auth, true)} />
          <PreventLoggedInRoute
            exact
            path={ROUTES.OFFLINE_ACTIVATION}
            render={withLayout(OfflineActivationBasic, true)}
          />
          <PreventLoggedInRoute exact path={ROUTES.OEM_ACTIVATION} render={withLayout(OfflineActivationBasic, true)} />
          <PreventLoggedInRoute exact path={ROUTES.CONFIRM_EMAIL} render={withLayout(ConfirmEmail, true)} />
          <PreventLoggedInRoute exact path={ROUTES.AFTER_REGISTER} render={withLayout(AfterRegister, true)} />
          <PreventLoggedInRoute exact path={ROUTES.TFA} render={withLayout(TFA, true, "tfa")} />
          <Route exact path={ROUTES.FORGOT_PASSWORD} render={withLayout(ForgotPassword, true, "forgot-password")} />
          <PreventLoggedInRoute exact path={ROUTES.RESET_PASSWORD} render={withLayout(ResetPassword, true)} />

          <PrivateRoute exact path={ROUTES.OVERVIEW} render={withLayout(Overview)} />
          <PrivateRoute exact path={ROUTES.LICENCES} render={withLayout(Licences)} />
          <PrivateRoute exact path={ROUTES.DOWNLOADS} render={withLayout(Downloads)} />
          <PrivateRoute exact path={ROUTES.BILLING} render={withLayout(Billing)} />
          <PrivateRoute exact path={ROUTES.ACCOUNT_SETTINGS} render={withLayout(AccountDetails)} />

          <Route render={() => <Redirect to={{ pathname: ROUTES.OVERVIEW }} />} />
        </Switch>
      </div>
    </ViewPortProvider>
  );
};

export default App;
