import { Divider, Segment } from "semantic-ui-react";
import { MIcon } from "@macrium/shared-components";
import "./MacriumAccountMessage.styles.scss";

const MacriumAccountMessage = () => {
  return (
    <div className="flex flex-column flex-center">
      <Segment className="p2 segment-styling mb0">
        <div className="flex" style={{ gap: "1em" }}>
          <div className="icon-circle-surround p0 m0 ">
            <MIcon size="medium" name="info circle" color="orange" />
          </div>
          <div className="text-small">
            <h5 className="bold mb1  message-header">Please Note</h5>
            <div>
              The Macrium Account is currently <b>separate from a Macrium Support Account</b> or <b>Forum Account</b>.
            </div>
            <ul className="pl3 mt1">
              <li>
                If you already have a Support Account <b>you will still need to create a Macrium Account</b>.
              </li>
              <li>
                If you previously purchased Macrium software, please use the email used during purchase when registering
                for a Macrium Account.
              </li>
            </ul>
          </div>
        </div>
      </Segment>
      <Divider style={{ width: "100%" }} />
    </div>
  );
};

export default MacriumAccountMessage;
