import { useState } from "react";
import { Button, Form } from "semantic-ui-react";
import DeleteAccountModal from "./DeleteAccountModal";

const DeleteAccount = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  return (
    <div className="account-details__section">
      <div className="account-details__section__left">
        <h3 className="account-details__section__left__title">Delete Account</h3>
      </div>

      <div className="account-details__section__right twofa-section">
        <p className="account-details__section__right__text">
          Delete your account permanently, including personal data and settings associated with it.
        </p>
        <Form>
          <Button
            color="red"
            type="button"
            onClick={() => {
              setShowDeleteModal(true);
            }}
            content="Delete"
          />
        </Form>
      </div>
      {showDeleteModal && <DeleteAccountModal onClose={() => setShowDeleteModal(false)} />}
    </div>
  );
};

export default DeleteAccount;
