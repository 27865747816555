import React from "react";
import PropTypes from "prop-types";
import Carousel from "react-multi-carousel";
import { MIcon, Preloader } from "@macrium/shared-components";
import { noop, map, isEmpty } from "lodash";
import { Progress } from "semantic-ui-react";
import { useSelector } from "react-redux";

import ProductCard from "../generic/ProductCard/ProductCard";

import variables from "../../scss/base/_variables.scss";
import "./ProductsRange.styles.scss";
import { isFetchingProductRange } from "../../selectors/basics";
import { getFilteredProductsRange } from "../../selectors/products";

const responsive = {
  bigDesktop: {
    breakpoint: { min: parseInt(variables.productCardsDesktopBreakpoint), max: 3000 },
    items: 3,
    partialVisibilityGutter: 30,
  },
  tabletAndDesktop: {
    breakpoint: {
      min: parseInt(variables.tabletBreakpointMin),
      max: parseInt(variables.productCardsDesktopBreakpoint),
    },
    items: 2,
    partialVisibilityGutter: 20,
  },
  mobile: {
    breakpoint: { min: 0, max: parseInt(variables.tabletBreakpointMin) },
    items: 1,
    partialVisibilityGutter: 40,
  },
};

const CustomButtonGroup = ({ next, previous, carouselState }) => {
  const { totalItems, currentSlide, slidesToShow } = carouselState;

  const hideControls = totalItems <= slidesToShow;

  const isFirstItem = currentSlide === 0;
  const isLastItem = currentSlide + slidesToShow === totalItems;

  const nPages = totalItems - slidesToShow;

  return hideControls ? null : (
    <div className="custom-carousel-container__controls">
      <div className="custom-carousel-container__controls-arrows">
        <div
          onClick={isFirstItem ? noop : previous}
          className={`custom-carousel-container__controls-previous ${isFirstItem ? "disabled" : ""}`}
        >
          <MIcon size="large" name="chevron circle left" onClick={previous} disabled={isFirstItem} />
        </div>

        <div
          onClick={isLastItem ? noop : next}
          className={`custom-carousel-container__controls-next ${isLastItem ? "disabled" : ""}`}
        >
          <MIcon size="large" name="chevron circle right" onClick={next} disabled={isLastItem} />
        </div>
      </div>

      <div className="custom-carousel-container__controls-carousel">
        <Progress percent={(currentSlide / nPages) * 100} className="carousel-slider" />
      </div>
    </div>
  );
};

// Props automatically injected by the carousel component
CustomButtonGroup.propTypes = {
  next: PropTypes.func,
  previous: PropTypes.func,
  carouselState: PropTypes.shape({
    totalItems: PropTypes.number,
    currentSlide: PropTypes.number,
    slidesToShow: PropTypes.number,
  }),
  goToSlide: PropTypes.func,
};

const ProductsRange = ({ title, gridMode }) => {
  const isLoading = useSelector(isFetchingProductRange);
  const productsRange = useSelector(getFilteredProductsRange);
  return (
    <>
      <Preloader loading={isLoading} />

      {!isEmpty(productsRange) && (
        <>
          <h2 className="mb0">{title}</h2>
          <p className="mb3">We have a great list of products to ensure complete security and protection</p>

          {gridMode ? (
            <div className="products-range__grid">
              {map(productsRange, (prod) => (
                <ProductCard key={prod.description} data={prod} productRange />
              ))}
            </div>
          ) : (
            <div className="carousel-wrapper">
              <Carousel
                responsive={responsive}
                partialVisible={true}
                containerClass="custom-carousel-container"
                itemClass="custom-carousel-container__item"
                customButtonGroup={<CustomButtonGroup />}
                renderButtonGroupOutside
                arrows={false}
              >
                {map(productsRange, (prod) => (
                  <ProductCard key={prod.description} data={prod} productRange />
                ))}
              </Carousel>
            </div>
          )}
        </>
      )}
    </>
  );
};

ProductsRange.propTypes = {
  gridMode: PropTypes.bool,
  title: PropTypes.string,
};

ProductsRange.defaultProps = {
  gridMode: false,
  title: "Our Product Range",
};

export default ProductsRange;
