const TagIcon = () => {
  return (
    <svg width="12" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Icon"
        d="M5 5.5H5.00625M1.25 3.75L1.25 6.54657C1.25 6.85231 1.25 7.00518 1.28454 7.14904C1.31516 7.27658 1.36566 7.39851 1.4342 7.51035C1.5115 7.6365 1.6196 7.7446 1.83579 7.96078L6.62868 12.7537C7.37121 13.4962 7.74248 13.8675 8.17059 14.0066C8.54717 14.1289 8.95282 14.1289 9.32941 14.0066C9.75752 13.8675 10.1288 13.4962 10.8713 12.7537L12.2537 11.3713C12.9962 10.6288 13.3675 10.2575 13.5066 9.82941C13.6289 9.45282 13.6289 9.04717 13.5066 8.67059C13.3675 8.24248 12.9962 7.87121 12.2537 7.12868L7.46078 2.33579C7.2446 2.1196 7.1365 2.0115 7.01035 1.9342C6.89851 1.86566 6.77658 1.81516 6.64904 1.78454C6.50518 1.75 6.35231 1.75 6.04657 1.75L3.25 1.75C2.54993 1.75 2.1999 1.75 1.93251 1.88624C1.69731 2.00608 1.50608 2.19731 1.38624 2.43251C1.25 2.6999 1.25 3.04993 1.25 3.75ZM5.3125 5.5C5.3125 5.67259 5.17259 5.8125 5 5.8125C4.82741 5.8125 4.6875 5.67259 4.6875 5.5C4.6875 5.32741 4.82741 5.1875 5 5.1875C5.17259 5.1875 5.3125 5.32741 5.3125 5.5Z"
        stroke="#003B71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TagIcon;
