import React from "react";
import { useSelector } from "react-redux";
import { getAccountDetails } from "../../selectors/basics";
import "./BillingPage.styles.scss";
import Page from "../generic/Page.jsx";
// import Subscriptions from "./Subscriptions/Subscriptions.jsx";
import Orders from "./Orders/Orders.jsx";
import { hasResellerOrdersOrSubsSelector } from "../../selectors/billing.js";

const Billing = () => {
  const accountDetails = useSelector(getAccountDetails);
  const hasResellerOrders = useSelector(hasResellerOrdersOrSubsSelector);
  return (
    <Page title="Billing">
      <div className="mb3">
        <p>
          {/* <span>This page shows any orders and subscriptions associated with your primary email account </span> */}
          <span>This page shows any orders associated with your primary email account </span>
          <b className="email-address">{accountDetails.email}</b>.
        </p>
        {hasResellerOrders && (
          <div className="bold">Please contact your reseller to obtain order invoices and other documentation.</div>
        )}
      </div>

      {/* <Subscriptions /> */}
      <Orders />
    </Page>
  );
};

export default Billing;
