import React from "react";
import PropTypes from "prop-types";
import FieldWrapper from "../FieldWrapper/FieldWrapper";
import RegCodeInclTooltip from "../../generic/RegCodeInclTooltip/RegCodeInclTooltip";

const RegistrationCode = ({ urc }) => {
  return (
    <FieldWrapper header="Registration Code">
      <RegCodeInclTooltip urc={urc} />
    </FieldWrapper>
  );
};

RegistrationCode.propTypes = {
  urc: PropTypes.string.isRequired,
};

export default RegistrationCode;
