export const PARTIALLY_REFUNDED = "Partially Refunded";
export const COMPLETE = "Complete";
export const REFUNDED_TEXT = "Refunded";
export const ACT = "ACT";
export const DEA = "DEA";
export const NEW = "NEW";
export const DEACTIVATED = "Action Required";
export const ON = "On";
export const OFF = "Off";
export const COMPLETE_STATUS = "Y";
export const REFUNDED_STATUS = "X";
export const PARTIALLY_REFUNDED_STATUS = "P";
export const ORANGE = "orange";
export const RED = "red";
