import React from "react";
import PropTypes from "prop-types";
import { MIcon, Link } from "@macrium/shared-components";
import { PRODUCTS_DESCRIPTION, SUBSCRIPTION } from "../../../../constants/licenses-products.js";
import { LTSC_WEBSITE_PAGE, PRODUCT_SUPPORT_POLICY } from "../../../../constants/urls.js";
import { isLegacy } from "../../../../utils/products.js";
import ReflectXSmallImage from "../../ReflectXImage/RelectXSmallImage.jsx";

const LicenseDescription = ({ data, extended }) => {
  const releaseNotesForProduct = data?.releaseNotes;
  const kbArticle = data?.kbArticle;
  return (
    <div className="product-card__description">
      <div className="product-card__description__version">
        <div className="mb0">
          {data.isTrial !== "1" && (
            <>
              {isLegacy(data.version) && (
                <a
                  href={data.ltscVersion ? LTSC_WEBSITE_PAGE : PRODUCT_SUPPORT_POLICY}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Link>
                    {data.ltscVersion
                      ? `LTSC ${data.ltscVersion}`
                      : `v${data.version} ${Number(data.version) !== 8 ? "(End of Life)" : ""}`}
                  </Link>
                </a>
              )}
              {data.subscription !== SUBSCRIPTION &&
                data.version &&
                !isLegacy(data.version) &&
                !data?.version?.startsWith("10") &&
                `v${data.version}`}
              {data?.version?.startsWith("10") && (
                <ReflectXSmallImage description={data.description} edition={data.edition} />
              )}
            </>
          )}
        </div>

        {extended && kbArticle && (
          <a href={kbArticle} target="_blank" rel="noreferrer" className="product-card__description__guide">
            <MIcon name="external" size="normalLarge" color="cBlue" />
            <span className="ml1">User Guide</span>
          </a>
        )}

        {!extended && releaseNotesForProduct && (
          <a style={{ textAlign: "right" }} href={releaseNotesForProduct} target="_blank" rel="noreferrer">
            <Link content="Release Notes" />
          </a>
        )}
      </div>

      <div className="product-card__description__text">{PRODUCTS_DESCRIPTION[data.description]}</div>
    </div>
  );
};

LicenseDescription.propTypes = {
  data: PropTypes.object,
  extended: PropTypes.bool,
};

export default LicenseDescription;
