import { Header, Message } from "semantic-ui-react";
import { map, isEmpty, isUndefined } from "lodash";
import { Preloader } from "@macrium/shared-components";
import OrderTable from "./OrderTable/OrderTable";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchBillingAsync } from "../../../slices/billing/billing";
import { getAccountEmail, getBillingErrorMessage, isFetchingBilling } from "../../../selectors/basics";
import OrderBlock from "./OrderBlock";
import { getOrdersWithDisplayableContent } from "../../../selectors/billing";
import HideShowButtons from "./HideShowButtons";

const Orders = () => {
  const [openOrders, setOpenOrders] = useState([]);
  const dispatch = useDispatch();
  const email = useSelector(getAccountEmail);
  const orders = useSelector(getOrdersWithDisplayableContent);
  const isLoading = useSelector(isFetchingBilling);
  const isError = useSelector(getBillingErrorMessage);

  useEffect(() => {
    if (email) {
      dispatch(fetchBillingAsync(email));
    }
  }, [dispatch, email]);

  const pageHasErrors = !isEmpty(isError) && !isUndefined(isError);
  return (
    <div className="relative">
      <Header as="h2" content="Order History" className="mb3 mt0" />
      {!isEmpty(orders) && !pageHasErrors && (
        <HideShowButtons setOpenOrders={setOpenOrders} orders={orders} openOrders={openOrders} />
      )}
      <div className={`relative ${isLoading ? "loading-container" : ""} `}>
        <Preloader loading={isLoading} size="massive" />
        {pageHasErrors && <Message negative content={isError} />}
        {isEmpty(orders) && !pageHasErrors && <Message content={"You currently have no orders."} />}
        {!isEmpty(orders) && !pageHasErrors && (
          <div>
            {orders.length > 15 ? (
              <OrderTable orders={orders} openOrders={openOrders} setOpenOrders={setOpenOrders} />
            ) : (
              map(orders, (order) => (
                <OrderBlock key={order.transId} order={order} openOrders={openOrders} setOpenOrders={setOpenOrders} />
              ))
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Orders;
