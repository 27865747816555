import { Message } from "semantic-ui-react";
import LicenseRows from "../licence/LicenseRows/LicenseRows";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { swapSubscriptionForAnnualPlan } from "../../utils/products";

const AssociatedLicenses = ({ associatedProductsAndLicenses, hideDivider }) => {
  return (
    <div>
      {!hideDivider && <div className="dropdown-divider" />}
      <div>
        <h3>Associated Licenses:</h3>
        <div>
          {isEmpty(associatedProductsAndLicenses) ? (
            <Message
              className="m0 mt1"
              info
              content={
                "No licenses are associated with this order ID. This may be because the licenses purchased have been refunded, upgraded, or renewed."
              }
            />
          ) : (
            associatedProductsAndLicenses.map((product) => (
              <div key={product.description} className="my2">
                <h4>{swapSubscriptionForAnnualPlan(product.description)}</h4>

                <LicenseRows
                  data={product.licenses}
                  description={product.description}
                  allRenewalSame={false}
                  allExpired={false}
                  allNoSupport={false}
                  hideContextMenu
                />
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default AssociatedLicenses;

AssociatedLicenses.propTypes = {
  associatedProductsAndLicenses: PropTypes.array.isRequired,
  hideDivider: PropTypes.bool,
};
AssociatedLicenses.defaultProps = {
  hideDivider: false,
};
