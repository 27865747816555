import ReflectImage from "./ReflectXImg.png";
import "./ReflectXImage.styles.scss";

const ReflectXImage = () => {
  return (
    <div className="image-container">
      <img src={ReflectImage} alt="Reflect X Box" />
    </div>
  );
};

export default ReflectXImage;
