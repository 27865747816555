import PropTypes from "prop-types";
import { MIcon, FormFieldError } from "@macrium/shared-components";
import { noop } from "lodash";

import CopyToClipboard from "../../generic/CopyToClipboard";
import TypeAhead from "../../generic/TypeAhead/TypeAhead";
import { INVALID_LICENSE_KEY } from "../../../constants/error-codes";
import { swapSubscriptionForAnnualPlan } from "../../../utils/products";

const LicenseContent = ({
  product,
  selectedLicenseKey,
  hasNoAvailableLicenses,
  licenseKeyOptions,
  selectAlternateKey,
  error,
  stepNumber,
}) => {
  const licenceDescription = licenseKeyOptions.find((option) => option.id === selectedLicenseKey)?.description || null;
  const hasLicenseError = error && error === INVALID_LICENSE_KEY;
  const LicenceSubheader = licenseKeyOptions.find((option) => option.id === selectedLicenseKey)?.subHeading || null;
  return (
    <div>
      <div className="h3 mb2">
        Product: <span className="bold">{swapSubscriptionForAnnualPlan(product.description)}</span>
      </div>
      {!hasNoAvailableLicenses ? (
        <div>
          <div className="pb2 bold">Selected License Key:</div>
          <div className="offline-license-display pb2">
            <div className="flex flex-center">
              {stepNumber !== 1 ? (
                <div className="mr2">{selectedLicenseKey}</div>
              ) : (
                <div style={{ width: "400px" }} className="mr2">
                  <TypeAhead
                    showChevron
                    options={licenseKeyOptions}
                    onSelect={selectAlternateKey}
                    selectedOptions={[selectedLicenseKey]}
                  />
                </div>
              )}
              <CopyToClipboard textToCopy={selectedLicenseKey} />
            </div>
            {(licenceDescription || LicenceSubheader) && (
              <div className="pt1 licence-description ">
                {LicenceSubheader && <div>{LicenceSubheader}</div>}
                {licenceDescription && <div>{licenceDescription}</div>}
              </div>
            )}
          </div>
          {hasLicenseError && <FormFieldError className="py1 red" error={error} />}
        </div>
      ) : (
        // space saver to stop size shift after selection
        <div style={{ height: "71px" }} />
      )}
      {hasNoAvailableLicenses && (
        <div className="pb2">
          <div className="pb2">License Key:</div>
          <div className="flex  flex-center" style={{ fontSize: "16px" }}>
            <MIcon name="info circle" color="red" size="medium" />
            <span className="ml2">No available license keys</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default LicenseContent;

LicenseContent.propTypes = {
  product: PropTypes.shape({
    description: PropTypes.string,
  }),
  selectedLicenseKey: PropTypes.string.isRequired,
  hasNoAvailableLicenses: PropTypes.bool.isRequired,
  licenseKeyOptions: PropTypes.array,
  selectAlternateKey: PropTypes.func,
  error: PropTypes.string,
  stepNumber: PropTypes.number.isRequired,
};

LicenseContent.defaultProps = {
  licenseKeyOptions: [],
  error: "",
  selectAlternateKey: noop,
};
