import {
  REFLECT_HOME,
  REFLECT_HOME_SUBSCRIPTION,
  REFLECT_SERVER,
  REFLECT_WORKSTATION,
  SERVER_PLUS,
  SERVER_PLUS_VM,
  DEPLOYMENT_KIT,
  TECHNICIAN,
  SITEDEPLOY,
  SITE_MANAGER,
  LICENSES_DESCRIPTIVE_STRING,
  SERVER_PLUS_SUBSCRIPTION,
  REFLECT_SERVER_SUBSCRIPTION,
  REFLECT_WORKSTATION_SUBSCRIPTION,
  DEPLOYMENT_KIT_SUBSCRIPTION,
  TECHNICIAN_SUBSCRIPTION,
  SITE_MANAGER_SUBSCRIPTION,
  SITE_BACKUP,
  SITE_BACKUP_SUBSCRIPTION,
} from "./licenses";

export const SUBSCRIPTION = "Subscription";
export const PERPETUAL = "Perpetual";
export const ANNUAL_PLAN = "Annual Plan";
// For TRIALS, the `Subscription` field is empty

/**
 * Takes the KB for Version 8 and returns the appropriate version
 * @param {string} description - name of the product
 * @param {version} version - version of the product
 * @returns {string} knowledgeBase url specific to the version
 */

export const REFLECT_HOME_DESCRIPTION =
  "The complete backup solution for personal use. Protect documents, data and operating systems using advanced disk imaging technology.";
export const SERVER_DESCRIPTION =
  "This fast and reliable solution once again sets the standard for image based server backup solutions.";
export const SERVER_PLUS_DESCRIPTION =
  "Fully featured and sophisticated tool for backing up the most critical of servers running apps such as Microsoft Exchange email, and SQL databases.";
export const DK_TECH_DESCRIPTION =
  "IT maintenance and support engineers can put the power of Macrium Reflect's backup engine in their pocket - on a USB stick.";
export const REFLECT_WORKSTATION_DESCRIPTION =
  "The complete backup solution for commercial use. Protect documents, data and operating systems using advanced disk imaging technology.";

export const PRODUCTS_DESCRIPTION = {
  [REFLECT_HOME]: REFLECT_HOME_DESCRIPTION,
  [REFLECT_HOME_SUBSCRIPTION]: REFLECT_HOME_DESCRIPTION,

  [REFLECT_SERVER]: SERVER_DESCRIPTION,
  [REFLECT_SERVER_SUBSCRIPTION]: SERVER_DESCRIPTION,

  [REFLECT_WORKSTATION]: REFLECT_WORKSTATION_DESCRIPTION,
  [REFLECT_WORKSTATION_SUBSCRIPTION]: REFLECT_WORKSTATION_DESCRIPTION,

  [SERVER_PLUS]: SERVER_PLUS_DESCRIPTION,
  [SERVER_PLUS_SUBSCRIPTION]: SERVER_PLUS_DESCRIPTION,
  [SERVER_PLUS_VM]: SERVER_PLUS_DESCRIPTION,

  [DEPLOYMENT_KIT]: DK_TECH_DESCRIPTION,
  [TECHNICIAN]: DK_TECH_DESCRIPTION,
  [DEPLOYMENT_KIT_SUBSCRIPTION]: DK_TECH_DESCRIPTION,
  [TECHNICIAN_SUBSCRIPTION]: DK_TECH_DESCRIPTION,
  [SITEDEPLOY]:
    "Controls and restores devices remotely, from PCs and thin clients to laptops and servers. Vital for organizations now that hybrid working is here to stay.",
  [SITE_MANAGER]:
    "Macrium SiteBackup allows you to manage multiple instances of Macrium Reflect in a single, easy to use interface.",
  [SITE_MANAGER_SUBSCRIPTION]:
    "Macrium SiteBackup allows you to manage multiple instances of Macrium Reflect in a single, easy to use interface.",
  [SITE_BACKUP]:
    "Macrium SiteBackup allows you to manage multiple instances of Macrium Reflect in a single, easy to use interface.",
  [SITE_BACKUP_SUBSCRIPTION]:
    "Macrium SiteBackup allows you to manage multiple instances of Macrium Reflect in a single, easy to use interface.",
};

const HOME_VIDEO = "https://www.youtube.com/embed/xOFTJs8cgd4";
const WORKSTATION_VIDEO = "https://www.youtube.com/embed/uSmFdoa4oLo";
const REFLECT_SERVER_VIDEO = "https://www.youtube.com/embed/_2cBEMICcQI";
const SERVER_PLUS_VIDEO = REFLECT_SERVER_VIDEO;

export const PRODUCTS_VIDEO_URLS = {
  [REFLECT_HOME]: HOME_VIDEO,
  [REFLECT_HOME_SUBSCRIPTION]: HOME_VIDEO,

  [REFLECT_WORKSTATION]: WORKSTATION_VIDEO,
  [REFLECT_WORKSTATION_SUBSCRIPTION]: WORKSTATION_VIDEO,
  [REFLECT_SERVER]: REFLECT_SERVER_VIDEO,
  [REFLECT_SERVER_SUBSCRIPTION]: REFLECT_SERVER_VIDEO,
  [SERVER_PLUS]: SERVER_PLUS_VIDEO,
  [SERVER_PLUS_SUBSCRIPTION]: SERVER_PLUS_VIDEO,
  [TECHNICIAN]: "https://www.youtube.com/embed/61hB6McavCs",
  [TECHNICIAN_SUBSCRIPTION]: "https://www.youtube.com/embed/61hB6McavCs",
};

export const PRODUCTS_EDITIONS = {
  [SITE_MANAGER]: 1,
  [SITE_MANAGER_SUBSCRIPTION]: 1,
  [REFLECT_HOME]: 6,
  [REFLECT_HOME_SUBSCRIPTION]: 6,
  [REFLECT_WORKSTATION]: 7,
  [REFLECT_WORKSTATION_SUBSCRIPTION]: 7,
  [REFLECT_SERVER]: 8,
  [REFLECT_SERVER_SUBSCRIPTION]: 8,
  [SERVER_PLUS]: 9,
  [SERVER_PLUS_VM]: 9,
  [SERVER_PLUS_SUBSCRIPTION]: 9,
  [TECHNICIAN]: 10,
  [TECHNICIAN_SUBSCRIPTION]: 10,
  [SITEDEPLOY]: 11,
  [SITE_BACKUP]: 1,
  [SITE_BACKUP_SUBSCRIPTION]: 1,
  [DEPLOYMENT_KIT]: 12,
  [DEPLOYMENT_KIT_SUBSCRIPTION]: 12,
};

export const EDITION_TO_PRODUCT_DESCRIPTION = {
  6: LICENSES_DESCRIPTIVE_STRING[REFLECT_HOME],
  8: LICENSES_DESCRIPTIVE_STRING[REFLECT_SERVER],
  7: LICENSES_DESCRIPTIVE_STRING[REFLECT_WORKSTATION],

  9: LICENSES_DESCRIPTIVE_STRING[SERVER_PLUS],

  10: LICENSES_DESCRIPTIVE_STRING[TECHNICIAN],
  1: LICENSES_DESCRIPTIVE_STRING[SITE_BACKUP],
  11: LICENSES_DESCRIPTIVE_STRING[SITEDEPLOY],
  12: LICENSES_DESCRIPTIVE_STRING[DEPLOYMENT_KIT],
};
export const EDITION_TO_HOTJAR_EVENT = {
  8: "clicked_download_reflect_server",
  12: "clicked_download_deployment_kit",
  10: "clicked_download_technician",
  6: "clicked_download_reflect_home",
  7: "clicked_download_reflect_workstation",
  9: "clicked_download_server_plus",
  11: "clicked_download_sitedeploy",
  1: "clicked_download_site_manager",
};

export const ARCH64 = "x64";
export const ARCH86 = "x86";
export const ARM = "ARM";
export const LEGACY_ARCH86_SM8 = "SM8_x86";

export const MACHINE_DEFAULT_NAME = {
  [SITE_MANAGER]: "SiteManager Server",
  [SITE_BACKUP]: "SiteBackup Server",
  [SITEDEPLOY]: "SiteDeploy Server",
  [SERVER_PLUS]: "Server",
  [SERVER_PLUS_VM]: "Server",
  [SERVER_PLUS_SUBSCRIPTION]: "Server",
  [REFLECT_SERVER]: "Server",
  [TECHNICIAN]: "Technician",
};
