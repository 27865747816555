import { hasDoneInitialLoad } from "../../selectors/basics";
import { getAccountDetailsAsync } from "../../slices/account/details";
import { fetchInitialData, loginWithSession, setHasDoneInitialLoad } from "../../slices/auth/login";
import { fetchLicenceInfoAsync } from "../../slices/products/products";
import { fetchBillingAsync } from "../../slices/billing/billing";
import { isEmpty } from "lodash";

import { getProductsRangeThunk } from "../../slices/products/productsRange";
import { downloadTrialAsync } from "../../slices/downloads/downloads";
import { getTrialFromCookie } from "../../utils/getTrialFromCookie";
import { SITE_TYPE_WEB } from "../../utils/gtm/downloads";
import { fetchOnboardingAsync } from "../../slices/onboarding/onboarding";
import { EDITION_TO_PRODUCT_DESCRIPTION } from "../../constants/licenses-products";

const initialLoad = (store) => (next) => (action) => {
  try {
    const initialLoadDone = hasDoneInitialLoad(store.getState());
    /**
     * If the initial load has been made, return and continue (next)
     */
    if (initialLoadDone) {
      return next(action);
    } else {
      next(action);
    }
    const runSetup = () => {
      store.dispatch(fetchLicenceInfoAsync());
      store.dispatch(fetchBillingAsync());
      // store.dispatch(fetchSubscriptionsAsync(store.getState().account.details.data.email));
      store.dispatch(getProductsRangeThunk());
      store.dispatch(fetchOnboardingAsync());

      const trialProductDetails = getTrialFromCookie();
      if (!isEmpty(trialProductDetails)) {
        const { architecture, edition, description, trialSource } = trialProductDetails;
        store.dispatch(
          downloadTrialAsync(
            {
              architecture,
              edition,
              trialSource,
              description: description || EDITION_TO_PRODUCT_DESCRIPTION[edition],
              productName: description || EDITION_TO_PRODUCT_DESCRIPTION[edition],
            },
            { siteType: SITE_TYPE_WEB }
          )
        );
      }
      store.dispatch(setHasDoneInitialLoad(true));
      return;
    };
    /**
     * Initial requests after logging in (or session sill valid).
     * This code is below the next(action) so we wait for the jwt to be in the store needed for the requests.
     *
     * Add here any requests that are needed after logging in more specifically if
     * data is needed in multiple pages
     */
    if (action.type === loginWithSession.toString() || action.type === fetchInitialData.toString()) {
      store.dispatch(getAccountDetailsAsync()).then(() => {
        runSetup();
        return;
      });
    }

    if (action.type === "details/getDetailsRequest/fulfilled") {
      return;
    }
  } catch (err) {
    console.log("Initial Load Middleware Error ", err);

    return next(action);
  }
};

export default initialLoad;
