import TableCell from "../../TableComponents/TableCell";
import PropTypes from "prop-types";
import { MIcon } from "@macrium/shared-components";
import InvoiceButton from "../InvoiceButton";
import StatusTablet from "../StatusTablet";
import AssociatedLicenses from "../../AssociatedLicenses";

const BillingRow = ({ order, setOpenOrders, openOrders }) => {
  const {
    transId,
    seller,
    purchaseDate,
    orderIsReseller,
    isRefunded,
    licenseeEmail,
    status,
    resellerEmail,
    associatedProductsAndLicenses,
  } = order;
  const expanded = openOrders.includes(transId);
  return (
    <div className="bottom-border">
      <div className="billing_table_row  relative">
        <TableCell mobileHeader="Order ID" isBillingPage children={<span className="bold">{transId}</span>} />
        <TableCell mobileHeader="Purchase Date" isBillingPage children={<span>{purchaseDate}</span>} />
        <TableCell
          mobileHeader="Licensee Contact"
          isBillingPage
          children={<span className="email_display">{licenseeEmail}</span>}
        />
        <TableCell
          mobileHeader={orderIsReseller ? "Reseller" : "Seller"}
          isBillingPage
          children={
            <div>
              {orderIsReseller ? (
                <div className="flex flex-column flex-wrap">
                  <div>{seller}</div>
                  {seller !== "-" && resellerEmail !== "-" && (
                    <a
                      href={`mailto:${resellerEmail}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="m0 email-address"
                    >
                      {resellerEmail}
                    </a>
                  )}
                </div>
              ) : (
                <div>{seller}</div>
              )}
            </div>
          }
        />
        <TableCell
          mobileHeader="Status"
          isBillingPage
          children={
            <div className="flex justify-between flex-wrap " style={{ gap: "8px" }}>
              <StatusTablet status={status} />
              <div className="flex flex-center" style={{ gap: "16px" }}>
                <div
                  as="button"
                  className="download_invoice_button"
                  onClick={() => {
                    if (expanded) {
                      const ordersWithoutCurrent = openOrders.filter((orderId) => orderId !== transId);
                      setOpenOrders(ordersWithoutCurrent);
                    } else {
                      setOpenOrders([...openOrders, transId]);
                    }
                  }}
                >
                  <MIcon name={expanded ? "eye off" : "eye"} size="normalLarge" color="cBlue" />
                </div>
                {!orderIsReseller && <InvoiceButton transId={transId} hideText isRefund={isRefunded} />}
              </div>
            </div>
          }
        />
      </div>
      {expanded && (
        <div className="p2">
          <AssociatedLicenses associatedProductsAndLicenses={associatedProductsAndLicenses} hideDivider />
        </div>
      )}
    </div>
  );
};

export default BillingRow;
BillingRow.propTypes = {
  order: PropTypes.shape({
    transId: PropTypes.string,
    licenseeEmail: PropTypes.string,
    status: PropTypes.string,
    resellerEmail: PropTypes.string,
    seller: PropTypes.string,
    isRefunded: PropTypes.bool,
    purchaseDate: PropTypes.string,
    orderIsReseller: PropTypes.bool,
    associatedProductsAndLicenses: PropTypes.array,
  }),
  setOpenOrders: PropTypes.func,
  openOrders: PropTypes.arrayOf(PropTypes.string).isRequired,
};
