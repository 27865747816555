import React from "react";
import PropTypes from "prop-types";

const ValueProposition = ({ text }) => (
  <h5 style={{ minWidth: "252px" }} className="bgGrey1 auth-layout-container__text">
    <p className="auth-layout-container__text-content">{text}</p>
  </h5>
);

ValueProposition.propTypes = {
  text: PropTypes.any,
};

ValueProposition.defaultProps = {
  text: "Effortlessly manage and administer all your products in one place. Simplify your workflow and stay organized with ease.",
};

export default ValueProposition;
