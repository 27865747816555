import { Segment } from "semantic-ui-react";
import { MIcon } from "@macrium/shared-components";
import InvoiceButton from "./InvoiceButton";
import PropTypes from "prop-types";
import BillingCell from "./BillingCell";
import StatusTablet from "./StatusTablet";
import AssociatedLicenses from "../AssociatedLicenses";

const OrderBlock = ({ order, setOpenOrders, openOrders }) => {
  const {
    transId,
    purchaseDate,
    seller,
    associatedProductsAndLicenses,
    orderIsReseller,
    isRefunded,
    licenseeEmail,
    status,
    resellerEmail,
  } = order;
  const expanded = openOrders.includes(transId);
  return (
    <Segment data-testid="Bill-block">
      <div className="flex flex-justify order_row">
        <div className="title-holder">
          <div className="order__title">
            <MIcon name="receipt" size="large" className="mr2" />
            <div className="order ">
              <h3 className="bold m0">Order ID:</h3>
              <h3 className="bold m0 pl1">{transId}</h3>
            </div>
          </div>
          <div
            className="dropdown-button "
            onClick={() => {
              if (expanded) {
                const ordersWithoutCurrent = openOrders.filter((orderId) => orderId !== transId);
                setOpenOrders(ordersWithoutCurrent);
              } else {
                setOpenOrders([...openOrders, transId]);
              }
            }}
          >
            {expanded ? "Hide " : "Show "} Licenses{" "}
            <MIcon name={expanded ? "eye off" : "eye"} size="normalLarge" className="ml1 " color="blue" />
          </div>
        </div>
        {!orderIsReseller && <InvoiceButton transId={transId} isRefund={isRefunded} />}
      </div>
      <div className="order">
        <BillingCell title="Purchase Date" children={<div className="m0">{purchaseDate}</div>} />
        <BillingCell title="Licensee Email" children={<p className="m0 email-address">{licenseeEmail}</p>} />
        <BillingCell
          title={orderIsReseller ? "Reseller" : "Seller"}
          children={
            <div className="m0">
              <p className="m0">{seller}</p>
            </div>
          }
        />
        {orderIsReseller ? (
          <BillingCell
            title="Reseller Email"
            children={
              <a
                href={`mailto:${resellerEmail}`}
                target="_blank"
                rel="noopener noreferrer"
                className="m0 email-address"
              >
                {resellerEmail}
              </a>
            }
          />
        ) : (
          <BillingCell title="Status" children={<StatusTablet status={status} />} />
        )}
      </div>
      {expanded && <AssociatedLicenses associatedProductsAndLicenses={associatedProductsAndLicenses} />}
    </Segment>
  );
};

export default OrderBlock;
OrderBlock.propTypes = {
  order: PropTypes.shape({
    transId: PropTypes.string.isRequired,
    licenseeEmail: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    resellerEmail: PropTypes.string,
    seller: PropTypes.string,
    isRefunded: PropTypes.bool,
    purchaseDate: PropTypes.string,
    orderIsReseller: PropTypes.bool,
    associatedProductsAndLicenses: PropTypes.array,
  }).isRequired,
  setOpenOrders: PropTypes.func,
  openOrders: PropTypes.arrayOf(PropTypes.string).isRequired,
};
