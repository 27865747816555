import { MIcon } from "@macrium/shared-components";
import PropTypes from "prop-types";

const IconButton = ({ disabled, onClick, icon, text }) => (<div
    className={`dropdown-button ${disabled && "disabled"}`}
    onClick={() => {
        if (!disabled) {
            onClick()
        }
    }}
>
    {text} <MIcon name={icon} size="normalLarge" className="ml1 " color={disabled ? "grey" : "blue"} />
</div>)
const HideShowButtons = ({ setOpenOrders, orders, openOrders }) => {
    const disableOpenAllOrders = openOrders.length === orders.length;
    const disableCloseAllOrders = openOrders.length === 0;
    return (
        <div className="show-hide-buttons">
            <IconButton icon="eye" text="Open All" onClick={() => setOpenOrders(orders.map((order) => order.transId))} disabled={disableOpenAllOrders} />
            <IconButton icon="eye off" text="Hide All" onClick={() => setOpenOrders([])} disabled={disableCloseAllOrders} />
        </div >
    );
};

export default HideShowButtons;

HideShowButtons.propTypes = {
    setOpenOrders: PropTypes.func.isRequired,
    orders: PropTypes.array.isRequired,
    openOrders: PropTypes.arrayOf(PropTypes.string).isRequired,
};