import moment from "moment";
import {
  ACTIVE,
  AVAILABLE,
  EXPIRED,
  EXPIRED_SUPPORT_TEXT,
  INSTALLED,
  UPGRADE_KEY,
  WORKSTATION,
  SERVER,
  VIRTUAL_SERVER,
  OFFLINE_ACTIVATED_ACTIVE,
  OFFLINE_ACTIVATED_EXPIRED,
  CORE,
  VM_PACK,
  SYSTEM_BUILDERS,
  OFFLINE_ACTIVATED_WARNING,
  NO_SUPPORT,
  LTSC_MAINTENANCE,
  FRIENDLYNAME,
} from "../../constants/licenses";

import { sortBy } from "lodash";

const indexLookUp = {
  // status
  [ACTIVE]: 0,
  [AVAILABLE]: 1,
  [INSTALLED]: 2,
  // key  types
  [WORKSTATION]: 10,
  [SERVER]: 11,
  [VIRTUAL_SERVER]: 12,
  [CORE]: 13,
  [SYSTEM_BUILDERS]: 14,
  [VM_PACK]: 15,
  // seat/endpoints
  QTY: 20,
  // version numbers
  V2: 30,
  V3: 31,
  V4: 32,
  V5: 33,
  V6: 34,
  V7: 35,
  V8: 36,
  V9: 37,
  // various
  [EXPIRED]: 40,
  [UPGRADE_KEY]: 41,
  [EXPIRED_SUPPORT_TEXT]: 50,
  [LTSC_MAINTENANCE]: 60,
  [NO_SUPPORT]: 70,
  [FRIENDLYNAME]: 80,
};
const doesTagIncludeDate = (id) => id.startsWith("Ren") || id.startsWith("Sup");
const isTagForOfflineActivation = (id) =>
  id === OFFLINE_ACTIVATED_ACTIVE || id === OFFLINE_ACTIVATED_EXPIRED || id === OFFLINE_ACTIVATED_WARNING;
const sortTags = (arrayToSort) => {
  const renewalSupportTags = arrayToSort
    .filter((tag) => doesTagIncludeDate(tag.id))
    .sort((a, b) => (moment(Date(a.id.slice(-10))).isSameOrBefore(Date(b.id.slice(-10))) ? -1 : 1));

  const newArray = arrayToSort
    .filter((tag) => !doesTagIncludeDate(tag.id) && tag.id !== "totalCount" && !isTagForOfflineActivation(tag.id))
    .map((tag) => {
      let indexValue = indexLookUp[tag.id];

      // Custom behavior
      if (tag.id.startsWith(LTSC_MAINTENANCE)) indexValue = indexLookUp[LTSC_MAINTENANCE];

      return {
        ...tag,
        indexValue,
      };
    });

  const sortedTags = sortBy(newArray, ["indexValue"]);

  const offlineActivationTag = arrayToSort.find((tag) => isTagForOfflineActivation(tag.id));
  return sortedTags.concat(renewalSupportTags).concat(offlineActivationTag ? [offlineActivationTag] : []);
};

export default sortTags;
