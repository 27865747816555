import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import HeaderWithContent from "./HeaderWithContent/HeaderWithContent";
import RegCodeInclTooltip from "../RegCodeInclTooltip/RegCodeInclTooltip";

const RegistrationCode = ({ account }) => {
  const urc = get(account, "urc", "N/A");

  return (
    <HeaderWithContent title="Registration Code:">
      <div className="urc-usage-info">
        <RegCodeInclTooltip urc={urc} />
      </div>
    </HeaderWithContent>
  );
};

RegistrationCode.propTypes = {
  account: PropTypes.shape({
    urc: PropTypes.string.isRequired,
  }),
};

export default RegistrationCode;
