import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Preloader } from "@macrium/shared-components";
import { map, isUndefined, get } from "lodash";
import { fetchLicenceInfoAsync, clearFilters } from "../../slices/products/products";
import EmptyStatus from "./EmptyStatus";
import {
  isFetchingProducts,
  getAccountEmail,
  hasDoneInitialLoad,
  getSelectedRouteState,
  getProductsError,
  isProductsLoaded,
  isEnterpriseUser,
} from "../../selectors/basics.js";
import { getLicencesCount, getLicensesForLicensesPage, filterArrayToObject } from "../../selectors/licenses.js";

import "./LicensePage.styles.scss";
import Page from "../generic/Page";
import { ALL_PRODUCTS } from "../../constants/licenses";
import ProductLicensesCard from "./ProductLicensesCard/ProductLicensesCard.jsx";
import { isTechOrDKProduct } from "../../utils/products.js";

const Licences = () => {
  // Take "productName" from router state which has been set on the Overview page license redirect
  const selectedRouteState = useSelector(getSelectedRouteState);
  const productNameToScroll = get(selectedRouteState, "productName");

  const initialLoad = useSelector(hasDoneInitialLoad);
  const productsLoaded = useSelector(isProductsLoaded);
  const totalCount = useSelector(getLicencesCount);
  const products = useSelector(getLicensesForLicensesPage);
  const filters = useSelector(filterArrayToObject);

  const email = useSelector(getAccountEmail);
  const isLoading = useSelector(isFetchingProducts);
  const isError = useSelector(getProductsError);
  const isEnterprise = useSelector(isEnterpriseUser);
  const dispatch = useDispatch();
  useEffect(() => {
    if (initialLoad) dispatch(fetchLicenceInfoAsync(email));
    return () => dispatch(clearFilters());
  }, [dispatch, initialLoad, email]);

  useEffect(() => {
    /**
     * Scroll to Product if "productName" has been set when redirecting the user
     **/
    setTimeout(() => {
      if (!isLoading) {
        const element = document.getElementById(productNameToScroll);

        if (element && productNameToScroll) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
    }, 0);
  }, [isLoading, productNameToScroll]);

  return (
    <Page title="Licenses">
      <Preloader loading={!productsLoaded || isLoading} size="massive" />

      {!isLoading && !isUndefined(isLoading) && totalCount > 0 ? (
        <div className="flex flex-center justify-between mb3">
          {isEnterprise ? (
            <h2 className="m0">Enterprise Licensing</h2>
          ) : (
            <h2 className="m0">
              Total Number of Licenses: <b>{totalCount}</b>
            </h2>
          )}
        </div>
      ) : (
        <EmptyStatus isError={isError} />
      )}

      {map(ALL_PRODUCTS, (key) => {
        if (isUndefined(products[key])) return null;
        return (
          <ProductLicensesCard
            key={key}
            description={key}
            product={products[key]}
            productNameToScroll={productNameToScroll}
            isTechniciansLicense={isTechOrDKProduct(products[key]?.edition)}
            filters={filters}
            isEnterprise={isEnterprise}
          />
        );
      })}
    </Page>
  );
};

export default Licences;
