import React from "react";
import PropTypes from "prop-types";
import { TagLabel } from "@macrium/shared-components";
import "./SubscriptionLabel.styles.scss";
import { BLUE_LABEL, GREEN_LABEL, RED_LABEL, YELLOW_LABEL } from "../../../constants/licenses.js";
import { ANNUAL_PLAN } from "../../../constants/licenses-products.js";

const SubscriptionLabel = ({ isTrial, isPerpetual, isExpired, ltscVersion }) => {
  if (isTrial) {
    return <TagLabel text="Trial Version" className={`product-type-label ${YELLOW_LABEL}`} attached={"top right"} />;
  }
  if (ltscVersion) {
    return (
      <TagLabel text={`LTSC ${ltscVersion}`} className={`product-type-label ${BLUE_LABEL}`} attached={"top right"} />
    );
  }
  if (isPerpetual) {
    return <TagLabel text="One-Time Purchase" className={`product-type-label ${BLUE_LABEL}`} attached={"top right"} />;
  }
  if (isExpired) {
    return <TagLabel text="Annual Plan Expired" className={`product-type-label ${RED_LABEL}`} attached={"top right"} />;
  }
  return <TagLabel text={ANNUAL_PLAN} className={`product-type-label ${GREEN_LABEL}`} attached={"top right"} />;
};

SubscriptionLabel.propTypes = {
  isPerpetual: PropTypes.bool,
  isExpired: PropTypes.bool,
  isTrial: PropTypes.bool,
  ltscVersion: PropTypes.string,
};

SubscriptionLabel.defaultProps = {
  isPerpetual: false,
  isExpired: false,
  isTrial: false,
  ltscVersion: "",
};

export default SubscriptionLabel;
