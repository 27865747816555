import {
  AVAILABLE,
  CORE,
  EXPIRED,
  INSTALLED,
  OFFLINE_ACTIVATED_ACTIVE,
  OFFLINE_ACTIVATED_EXPIRED,
  OFFLINE_ACTIVATED_WARNING,
  SYSTEM_BUILDERS,
} from "../../constants/licenses";

const isNotInstalledOrNeedsReactivationSD = (statuses, needsOfflineActivation) => 
  !statuses.find(({ id }) => id === INSTALLED && statuses.find(({ id }) => id === CORE || id === SYSTEM_BUILDERS) && !needsOfflineActivation);
const statusValidForOfflineActivation = (statuses, needsOfflineActivation) => {
  const hasExpired = statuses.some(({ id }) => id === EXPIRED);

  if (hasExpired) return false;

  return isNotInstalledOrNeedsReactivationSD(statuses, needsOfflineActivation) &&
  !!statuses.find(({ id }) => id === AVAILABLE || id === OFFLINE_ACTIVATED_EXPIRED || id === INSTALLED) &&
  !!statuses.every(({ id }) => id !== OFFLINE_ACTIVATED_ACTIVE && id !== OFFLINE_ACTIVATED_WARNING);
};
  
export default statusValidForOfflineActivation;
