import React from "react";
import { Accordion } from "semantic-ui-react";
import { Preloader, TagLabel } from "@macrium/shared-components";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

import Page from "../generic/Page";
import { isProductsLoaded } from "../../selectors/basics";
import ProductsRange from "../overview/ProductsRange";
import YourProducts from "../overview/YourProducts/YourProducts";
import { getProductsToDownloadsPage } from "../../selectors/downloadsPage";
import OnboardingContainer from "../onboarding/OnboardingContainer";

const OPEN = 0;
const CLOSED = -1;

const DownloadsPage = () => {
  const [activePanel, setActivePanel] = React.useState(CLOSED);

  const hasLoadedProducts = useSelector(isProductsLoaded);
  const { products, legacyProducts } = useSelector(getProductsToDownloadsPage);
  const panels = React.useMemo(() => {
    return [
      {
        key: "Legacy Products",
        title: {
          content: <TagLabel text="Legacy Products" color="grey" basic={false} />,
        },
        content: {
          content: <YourProducts title="" licenses={legacyProducts} extended={false} smallWidth />,
        },
        onTitleClick: () => setActivePanel(activePanel === CLOSED ? OPEN : CLOSED),
      },
    ];
  }, [legacyProducts, activePanel]);

  React.useEffect(() => {
    setActivePanel(isEmpty(products) ? OPEN : CLOSED);

    if (isEmpty(products)) {
      // open legacy tab by default if only legacy versions
    } else {
    }
  }, [products, hasLoadedProducts]);
  return (
    <Page title="Downloads">
      <Preloader loading={!hasLoadedProducts} size="massive" />
      <OnboardingContainer />
      {!isEmpty(products) && <YourProducts licenses={products} extended={false} smallWidth />}

      {!isEmpty(legacyProducts) && (
        <>
          {isEmpty(products) && <h2>Your Products</h2>}
          <Accordion panels={panels} activeIndex={activePanel} />
        </>
      )}

      <ProductsRange gridMode title="Start a Trial" />
    </Page>
  );
};

export default DownloadsPage;
