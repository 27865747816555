import { Button, Modal } from "semantic-ui-react";
import { EXPIRED } from "../../../constants/licenses";
import { useDispatch, useSelector } from "react-redux";
import ModalHeaderWithCloseButton from "../ModalHeaderWithCloseButton";
import { resetLicenseKey, setLicenseToReset } from "../../../slices/products/licenseReset";
import { getLicenseToReset, isResettingLicense } from "../../../selectors/basics";

const ResetLicenseModal = () => {
  const isResettingLicenseKey = useSelector(isResettingLicense);
  const licenseToReset = useSelector(getLicenseToReset);
  const dispatch = useDispatch();
  if (!licenseToReset) return null;
  const onClose = () => dispatch(setLicenseToReset(null));
  const { isOfflineActivated } = licenseToReset;
  const licenseHasExpired = licenseToReset.statuses.find(({ id }) => id === EXPIRED);
  const resetLicense = () => dispatch(resetLicenseKey(licenseToReset.licenseId));
  return (
    <Modal open={true} size="small">
      <ModalHeaderWithCloseButton onClose={onClose} header="Reset License Key" />
      <Modal.Content>
        {isOfflineActivated && !licenseHasExpired && (
          <>
            <p>
              This license key was activated offline and cannot be reset while Reflect remains installed on this device.
            </p>
            <p>
              To reset this license key, please uninstall Reflect on this device. Once uninstalled, the key will be
              available for use on a new device or hardware.
            </p>
          </>
        )}
        {!isOfflineActivated && licenseHasExpired && (
          <>
            <p>
              This license key has expired and cannot be reset. An active license key is required to enable installation
              and activation on a new device.
            </p>
            <p>Please renew your license to regain access to this feature.</p>
          </>
        )}
        {!isOfflineActivated && !licenseHasExpired && (
          <>
            <p>
              Resetting this license key will deactivate Reflect on the current device. This means you will no longer be
              able to use Reflect on this device with this key.
            </p>
            <p>
              Once reset, this key will be available to use for installation and activation of Reflect on a new device
              or hardware.
            </p>
            <b>Do you wish to proceed with resetting this license key?</b>
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button secondary size="medium" content="Close" onClick={onClose} disabled={isResettingLicenseKey} />
        <Button
          primary
          size="medium"
          content="Reset License"
          disabled={!!(isOfflineActivated || licenseHasExpired || isResettingLicenseKey)}
          onClick={resetLicense}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default ResetLicenseModal;
