import ROUTES from "../../../app/routes";
import MacriumAccountMessage from "./MacriumAccountMessage/MacriumAccountMessage";
import PropTypes from "prop-types";
import MainHeader from "./MainHeader";
import OfflineActivationLink from "./OfflineActivationLink/OfflineActivationLink";

const AuthHeader = ({ pathname, trialProductDetails, storedOfflineCookies }) => {
  return (
    <div>
      <OfflineActivationLink storedOfflineCookies={storedOfflineCookies} />
      {!storedOfflineCookies && (
        <MainHeader isTrialPage={trialProductDetails} isLoginPage={pathname === ROUTES.LOGIN} />
      )}
      <MacriumAccountMessage isLoginPage={pathname === ROUTES.LOGIN} hideTitle={trialProductDetails} />
    </div>
  );
};

export default AuthHeader;
AuthHeader.propTypes = {
  pathname: PropTypes.string.isRequired,
  trialProductDetails: PropTypes.shape({
    architecture: PropTypes.string,
    edition: PropTypes.number,
    trialSource: PropTypes.string,
  }),
  storedOfflineCookies: PropTypes.shape({
    key: PropTypes.string,
    activationCode: PropTypes.string,
    action: PropTypes.string,
  }),
};
AuthHeader.defaultProps = {
  trialProductDetails: null,
  storedOfflineCookies: null,
};
