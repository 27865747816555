import { Buttons } from "@macrium/shared-components";
import PropTypes from "prop-types";

import { REFLECT_HOME, REFLECT_HOME_SUBSCRIPTION } from "../../../constants/licenses";

const BuyNowButton = ({ data }) => {
  const isHome = data.description === REFLECT_HOME || data.description === REFLECT_HOME_SUBSCRIPTION;

  return (
    <a href={data.buyNowURL} target="_blank" rel="noopener noreferrer">
      <Buttons.MIconButton
        icon={isHome ? "cart" : undefined}
        content={isHome ? "Buy Now" : "Find Out More"}
        color="green"
        className="fluid"
      />
    </a>
  );
};

BuyNowButton.propTypes = {
  data: PropTypes.object.isRequired,
};

export default BuyNowButton;
