import { useRef } from "react";
import moment from "moment";
import { map, isUndefined, find } from "lodash";
import { Popup } from "semantic-ui-react";
import { isMobile, isIOS } from "react-device-detect";
import plur from "plur";
import TagIcon from "../generic/TagIcon";
import {
  EXPIRED,
  TECHNICIAN,
  DEPLOYMENT_KIT,
  FRIENDLYNAME,
  LICENSES_DESCRIPTIVE_STRING,
  QTY,
  UPGRADE_KEY,
  OFFLINE_ACTIVATED_WARNING,
  VM_PACK,
  DEPLOYMENT_KIT_SUBSCRIPTION,
  EXPIRED_DK_TECH_MESSAGE,
  EXPIRED_SM_SD_MESSAGE,
  EXPIRED_REFLECT_MESSAGE,
  isLTSCMaintenanceTag,
  NO_SUPPORT,
} from "../../constants/licenses.js";
import PropTypes from "prop-types";
import { MIcon } from "@macrium/shared-components";
import CopyToClipboard from "../generic/CopyToClipboard.jsx";
import { isProductSiteManagerOrSiteDeploy } from "../../utils/license/productIsSiteManager.js";

/**
 * Returns the appropriate message to show on the Expired Tag popup.
 * @param {string} description
 * @returns {string}
 */
const getExpiredMessage = (description) => {
  let messageContent = "";

  if (description === TECHNICIAN || description === DEPLOYMENT_KIT || description === DEPLOYMENT_KIT_SUBSCRIPTION) {
    messageContent = EXPIRED_DK_TECH_MESSAGE;
  } else if (isProductSiteManagerOrSiteDeploy(description)) {
    messageContent = EXPIRED_SM_SD_MESSAGE;
  } else {
    messageContent = EXPIRED_REFLECT_MESSAGE;
  }

  return messageContent;
};

const TagDisplay = ({
  description,
  pairedKey,
  isDowngraded,
  isVirtualBundle,
  text,
  id,
  statuses,
  maintenanceExpiry,
  hasMaintenanceExpired,
}) => {
  const isMobileDevice = isIOS || isMobile;
  const mobileRef = useRef();
  if (id === OFFLINE_ACTIVATED_WARNING) {
    return (
      <div className="licence-row__label">
        <span>{text}</span>
        <Popup
          className="fix-popup-alignment"
          trigger={
            <div data-testid="popup-trigger">
              <MIcon size="largeSmall" name="info circle" className="mlhalf " color="orange" />
            </div>
          }
          wide
          hideOnScroll
          content={
            <div>
              <p className="">
                This offline-activated license is approaching its reactivation date. Once it expires, reactivation will
                be required on the same device.
              </p>
            </div>
          }
          position={isMobileDevice ? "bottom center" : "bottom right"}
          context={isMobileDevice ? mobileRef : null}
        />
        {isMobileDevice && <div ref={mobileRef} className="licence-row__mobile-ref" />}
      </div>
    );
  }
  if (!isUndefined(pairedKey) && id.startsWith("V") && id.length === 2) {
    return (
      <div className="licence-row__label">
        <span>{text}</span>
        <Popup
          className="fix-popup-alignment"
          trigger={
            <div data-testid="popup-trigger">
              <MIcon size="largeSmall" name="info circle" className="mlhalf mt0 mb0" />
            </div>
          }
          wide
          hideOnScroll
          content={
            <div>
              <p className="bold mb2">
                {`This license has been ${isDowngraded ? "downgraded" : "upgraded"} from the
                    following ${plur("key", pairedKey.length)}`}
              </p>
              {pairedKey.length > 1 ? (
                map(pairedKey, (oldKey, i) => (
                  <p key={oldKey + i}>
                    <CopyToClipboard textToCopy={oldKey} /> {oldKey}
                  </p>
                ))
              ) : (
                <p className="monospace flex">
                  <CopyToClipboard textToCopy={pairedKey[0]} /> {pairedKey[0]}
                </p>
              )}
            </div>
          }
          position={isMobileDevice ? "bottom center" : "bottom right"}
          context={isMobileDevice ? mobileRef : null}
          hoverable
        />
        {isMobileDevice && <div ref={mobileRef} className="licence-row__mobile-ref" />}
      </div>
    );
  }
  if (id === UPGRADE_KEY) {
    return (
      <div className="licence-row__label">
        <span>{text}</span>
        <Popup
          className="fix-popup-alignment"
          trigger={
            <div data-testid="popup-trigger">
              <MIcon size="largeSmall" name="info circle" className="mlhalf mt0 mb0" />
            </div>
          }
          hideOnScroll
          content={
            <div>
              <p className="bold mb2">
                {`Enter this key into version ${find(statuses, ({ text }) => text.startsWith("V"))?.text.substring(
                  1
                )} installation of ${LICENSES_DESCRIPTIVE_STRING[description]} for instructions`}
              </p>
              <p>This version is no longer supported by Macrium</p>
            </div>
          }
          context={isMobileDevice ? mobileRef : null}
          wide
          position={isMobileDevice ? "bottom center" : "bottom right"}
        />
        {isMobileDevice && <div ref={mobileRef} className="licence-row__mobile-ref" />}
      </div>
    );
  }
  if (id === VM_PACK) {
    const seatQuantity = statuses
      .find(({ id }) => id === QTY)
      ?.text?.split(":")[1]
      .trim()
      .split("/")[1];
    return (
      <div className="licence-row__label">
        <span>{text}</span>
        <Popup
          className="fix-popup-alignment"
          trigger={
            <div data-testid="popup-trigger">
              <MIcon size="largeSmall" name="info circle" className="mlhalf mt0 mb0" />
            </div>
          }
          hideOnScroll
          content={`A VM Pack license key can be installed on different virtual machines up to ${seatQuantity} times.`}
          wide
          context={isMobileDevice ? mobileRef : null}
          position={isMobileDevice ? "bottom center" : "bottom right"}
        />
        {isMobileDevice && <div ref={mobileRef} className="licence-row__mobile-ref" />}
      </div>
    );
  }
  if (isVirtualBundle && id === QTY) {
    return (
      <div className="licence-row__label">
        <span>{text}</span>
        <Popup
          className="fix-popup-alignment"
          trigger={
            <div data-testid="popup-trigger">
              <MIcon size="largeSmall" name="info circle" className="mlhalf mt0 mb0" />
            </div>
          }
          hideOnScroll
          content={"This license can only be installed on virtual machines"}
          wide
          context={isMobileDevice ? mobileRef : null}
          position={isMobileDevice ? "bottom center" : "bottom right"}
        />
        {isMobileDevice && <div ref={mobileRef} className="licence-row__mobile-ref" />}
      </div>
    );
  }

  if (id === EXPIRED) {
    const messageContent = getExpiredMessage(description);

    return (
      <div className="licence-row__label">
        <span>{text}</span>
        <Popup
          className="fix-popup-alignment"
          trigger={
            <div data-testid="popup-trigger">
              <MIcon size="largeSmall" name="info circle" className="mlhalf mt0 mb0" />
            </div>
          }
          hideOnScroll
          content={messageContent}
          wide
          context={isMobileDevice ? mobileRef : null}
          position={isMobileDevice ? "bottom center" : "bottom right"}
        />
        {isMobileDevice && <div ref={mobileRef} className="licence-row__mobile-ref" />}
      </div>
    );
  }

  if (isLTSCMaintenanceTag(id)) {
    const expirationTime = moment.unix(maintenanceExpiry).format("L");
    const maintenanceExpiryText = maintenanceExpiry ? expirationTime : undefined;

    return (
      <div className="licence-row__label">
        <span>{text}</span>
        {maintenanceExpiryText && (
          <Popup
            className="fix-popup-alignment"
            trigger={
              <div data-testid="popup-trigger">
                <MIcon size="largeSmall" name="info circle" className="mlhalf mt0 mb0" />
              </div>
            }
            hideOnScroll
            content={
              <>
                <p>
                  The maintenance period {hasMaintenanceExpired ? `ended ` : `ends `}on {expirationTime}{" "}
                </p>
              </>
            }
            wide
            context={isMobileDevice ? mobileRef : null}
            position={isMobileDevice ? "bottom center" : "bottom right"}
          />
        )}
        {isMobileDevice && <div ref={mobileRef} className="licence-row__mobile-ref" />}
      </div>
    );
  }

  if (id === NO_SUPPORT) {
    return (
      <div className="licence-row__label">
        <span>{text}</span>
        <Popup
          className="fix-popup-alignment"
          trigger={
            <div data-testid="popup-trigger">
              <MIcon size="largeSmall" name="info circle" className="mlhalf mt0 mb0" />
            </div>
          }
          hideOnScroll
          content={<div>Support was not purchased for this license</div>}
          context={isMobileDevice ? mobileRef : null}
          wide
          position={isMobileDevice ? "bottom center" : "bottom right"}
        />
        {isMobileDevice && <div ref={mobileRef} className="licence-row__mobile-ref" />}
      </div>
    );
  }
  if (id === FRIENDLYNAME) {
    return (
      <div className="flex flex-center">
        <TagIcon />
        <span className="ml1">{text}</span>
      </div>
    );
  }
  return text;
};
export default TagDisplay;

TagDisplay.propTypes = {
  description: PropTypes.string.isRequired,
  pairedKey: PropTypes.array,
  isDowngraded: PropTypes.bool,
  isVirtualBundle: PropTypes.bool,
  text: PropTypes.string.isRequired,
  id: PropTypes.string,
  statuses: PropTypes.array,
  activationCode: PropTypes.string,
  maintenanceExpiry: PropTypes.string,
  hasMaintenanceExpired: PropTypes.bool,
};
TagDisplay.defaultProps = {
  text: "",
  description: "",
  activationCode: "guid-goes-here",
  maintenanceExpiry: "",
  hasMaintenanceExpired: false,
};
