const KebabMenutrigger = () => {
  return (
    <div data-testid="kebab-menu-trigger" className="flex kebab_menu flex-column flex-end pr2">
      <div className="kebab-menu-dot" />
      <div className="kebab-menu-dot" />
      <div className="kebab-menu-dot" />
    </div>
  );
};

export default KebabMenutrigger;
