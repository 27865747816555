import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";
import { isEmpty } from "lodash";
import { getAccountEmail } from "../../../selectors/basics";
import { getCurrentProducts, getFirstValidOrderID } from "../../../selectors/licenses";
import getUpgradeLink from "../../../utils/getUpgradeLink";

const ReflectXUpgradeButton = ({ asButton, text, isPrimary }) => {
  const userEmail = useSelector(getAccountEmail);
  const products = useSelector(getCurrentProducts);
  const orderId = useSelector(getFirstValidOrderID);
  if (!userEmail || isEmpty(products) || !orderId) return null;

  const upgradeLink = getUpgradeLink(userEmail, orderId);
  return asButton ? (
    <Button
      secondary={!isPrimary}
      primary={isPrimary}
      content={text}
      as="a"
      href={upgradeLink}
      target="_blank"
      rel="noopener noreferrer"
    />
  ) : (
    <a href={upgradeLink} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  );
};

ReflectXUpgradeButton.propTypes = {
  asButton: PropTypes.bool,
  text: PropTypes.string.isRequired,
  isPrimary: PropTypes.bool,
};

ReflectXUpgradeButton.defaultProps = {
  asButton: false,
  isPrimary: true,
};

export default ReflectXUpgradeButton;
