import { Button } from "semantic-ui-react";
import PropTypes from "prop-types";

const OfflineStepThree = ({ handleFinish, handleStartAgain, licenceKey, showStartAgain }) => {
  return (
    <div className="flex justify-between flex-column offline-step-container">
      <div>
        <div className="offline-step-header">Step 3</div>
        <div className="flex offline-step-content flex-column flex-start">
          <div>License key {licenceKey ? `- ${licenceKey} -` : ""} was successfully offline activated.</div>
          <div className="mt3 ml2">
            <svg width="100px" height="100px" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="36.5" cy="36.5" r="36" stroke="#32D583" />
              <path
                d="M53.7066 25.2891L32.1977 46.798L22.4209 37.0212"
                stroke="#32D583"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="flex offline-step-button-container">
        <Button primary content="Finish" onClick={handleFinish} />
        {showStartAgain && <Button secondary content="Activate Another License?" onClick={handleStartAgain} />}
      </div>
    </div>
  );
};

OfflineStepThree.propTypes = {
  handleFinish: PropTypes.func.isRequired,
  handleStartAgain: PropTypes.func.isRequired,
  showStartAgain: PropTypes.bool.isRequired,
  licenceKey: PropTypes.string.isRequired,
};
export default OfflineStepThree;
